import { useEffect, useState } from "react";

const useContextMenu = () => {
    const [clicked, setClicked] = useState<any>(false);
    const [points, setPoints] = useState({
        x: 0,
        y: 0
    });

    useEffect(() => {
        const handleClick = (e : any) => {
            let target = e?.target;
            while (target && !target?.classList?.contains("pages-container") && !target?.classList?.contains("context-menu")) {
                target = target?.parentElement;
            }

            if (target?.classList?.contains("context-menu")) return;
            setClicked(false);
        };

        window.addEventListener("click", handleClick);

        return () => {
            window.removeEventListener("click", handleClick);
        };  
    }, []);

    return [
        clicked,
        setClicked,
        points,
        setPoints
    ];
};

export default useContextMenu;