import { faFileLines, faPlus, faTrashCan, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../Components/Header/Header";
import Processing from "../../Components/Processing/Processing";
import "./DebugSessions.css";


const DebugSessions = ({ data, socket, finished } : { data: any, socket: any, finished: boolean }) => {
   
    const navigate = useNavigate();
    const [category, setCategory] = useState("projects");
    const location = useLocation();
    const [cookies, setCookie] = useCookies();
    const [sessions, setSessions] = useState([]);

    useEffect(() => {
        (async () => {
            const options = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                }
            };

            try {
                const request = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/session/get-sessions/${data.userData?.type === "student" ? data.userData.teacherId : data.userData.userId}`, options);
                const response = await request.json();
                console.log(response);
                if (response.success) {
                    setSessions(response.sessions.filter((session : any) => session.finished == finished));
                } else console.log(response);
            } catch (e) {
                console.log(e);
            }
            
        })();
    }, []);

    const removeSession = async (sessionId : string, e : any) => {
        if (data.userData.type !== "teacher") return;
        const options = {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json"
            }
        };

        try {
            const request = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/session/remove-session/${data.userData.userId}/${sessionId}`, options);
            const response = await request.json();
            console.log(response);
            if (response.success) window.location.reload();
            else console.log(response);
        } catch (e) {
            console.log(e);
        }
    };

    return sessions ? (
        <div className="content">

            {sessions?.length ?
                <div className="projects-container">


                    {sessions.map((session : { name: string, format: string, sessionId: string, number: number }, idx : number) => (
                        <div className="project-gradient" key={idx} onClick={(e : any) => e.target.tagName !== "svg" && e.target.tagName !== "path" ? window.location.replace(`/session/${session.sessionId}/${session.number}/0`) : null}>
                            <div className="project">
                                <h2>Sesiune {session.number === 1 ? (cookies?.language === "en" ? "Turning on the light bulb" : "Aprinderea unui bec") : session.number === 2 ? (cookies.language === "en" ? "One-way starting of the three-phase asynchronous motor" : "Pornirea într-un singur sens a motorului asincron trifazat") : (cookies.language === "en" ? "Current direction inverter" : "Inversorul de sens")} - {idx}</h2>

                                <div className="format">
                                    <FontAwesomeIcon icon={faFileLines} className="format-icon" />
                                    <p>{session?.format || "A4"}</p>
                                </div>

                                {data.userData.type === "teacher" ? <FontAwesomeIcon icon={faTrashCan} className="delete-icon" onClick={async (e : any) => await removeSession(session.sessionId, e)} /> : null}
                                
                            </div>
                        </div>
                    ))}
                </div>
            : 
                <div className="empty-container">
                    <h2>Nu au fost create sesiuni.</h2>
                </div>
            }

            {/* <div className="add-button" onClick={() => navigate("/create-session")}>
                <FontAwesomeIcon icon={faPlus} className="add-icon" />
            </div> */}


        </div>

    ) : <Processing />;
};

export default DebugSessions;