import { faBook, faBookOpen, faGraduationCap, faLightbulb, faLinesLeaning, faLock, faPencilRuler, faPlugCircleCheck, faTimes, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useMemo, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import Header from "../../Components/Header/Header";
import "./Categories.css";
import ZenLogoWhite from "../../imgs/ZenLogo_white.svg";



const Categories = ({ data, socket } : { data: any, socket: any }) => {

    const [cookies, setCookie] = useCookies();
    const navigate = useNavigate();

    const moduleContextRef = useRef<HTMLDivElement>(null);


    if (!data?.userData) navigate(-1);

    const accessModules = useMemo(() => data.userData?.modules || ["edit", "functional", "debug", "learn", "train", "test"], []);
    const [activeModule, setActiveModule] = useState("edit");

    const [changeModule, setChangeModule] = useState<number>(-1);
    // const [activeText, setActiveText] = useState<boolean>(false);
    const [changeModuleText, setChangeModuleText] = useState<string>("");

    let currIdx : number = 0;

    useEffect(() => {
        if (changeModule === -1) return;

        let timer : any;

        const smoothText = (texts : Array<string>, idx : number, initChangeModule : number, oldIdx : number = 0) => {
            if (idx === texts.length) return;
            const letters = texts[idx].split("");
            for (let letterIdx = 0; letterIdx < letters.length; letterIdx++) {
                let letter = letters[letterIdx];
                timer = setTimeout(() => {
                    if (oldIdx !== currIdx) return;
                    setChangeModuleText(currText => currText += letter);
                    if (letterIdx === letters.length - 1) {
                        // const paragraph : any = document.querySelector(".docs-container p");
                        // paragraph.innerHTML += "<br />";
                        return smoothText(texts, idx + 1, initChangeModule, oldIdx);
                    }
          
                 
                }, letterIdx * 10);
            }
           

        };

        fetch(`../../Documentation_Modules/${changeModule === 0 ? "edit" : changeModule === 1 ? "functional" : changeModule === 2 ? "debugging" : changeModule === 3 ? "learning" : changeModule === 4 ? "training" : changeModule === 5 ? "testing" : "edit-high-tension"}_${cookies?.language || "en"}.txt`)
        .then(req => {
            return req.text();
        }).then(res => {
            console.log(res);
            const texts = res.split(/^/gm);
            setChangeModuleText("");
            console.log(texts);

           smoothText(texts, 0, changeModule);
            
        }).catch(err => {
            console.log(err);
        });

        return () => {
            currIdx++;
        };
     

    }, [changeModule]);

    return (
        <div className="content categories-content">
            <Header data={data} socket={socket} />
            <h2 className="title">{cookies?.language === "en" ? "Modules" : "Module"}</h2>

            <div className="name-container">
                <div className="name">

                    <FontAwesomeIcon icon={faUser} className="user-icon" />
                    <p>{data.userData.username} {data.userData.type === "teacher" ? (cookies?.language === "en" ? "(Teacher)" : "(Profesor)") : (cookies?.language === "en" ? "(Student)" : "(Elev)")}</p>
                </div>
                {/* <div className="line" /> */}
            </div>
            
            {/* <div className="modules">

                <div className={`module low-tension ${!accessModules?.includes("edit") && !accessModules?.includes("functional") && !accessModules?.includes("debug") ? "locked" : ""}`} onClick={() => !accessModules?.includes("edit") && !accessModules?.includes("functional") && !accessModules?.includes("debug") ? null : navigate("/low-tension")}>
                    <h2>{cookies?.language === "en" ? "Low-tension Modules" : "Module joasa tensiune"}</h2>
                    {!accessModules?.includes("edit") && !accessModules?.includes("functional") && !accessModules?.includes("debug") ? <FontAwesomeIcon icon={faLock} className="lock-icon" /> : null}
                </div>

                <div className={`module high-tension ${!accessModules?.includes("learn") && !accessModules?.includes("train") && !accessModules?.includes("test") ? "locked" : ""}`} onClick={() => accessModules?.includes("learn") && !accessModules?.includes("train") && !accessModules?.includes("test") ? null : navigate("/high-tension")}>
                    <h2>{cookies?.language === "en" ? "High-tension Modules" : "Module inalta tensiune"}</h2>
                    {!accessModules?.includes("learn") && !accessModules?.includes("train") && !accessModules?.includes("test") ? <FontAwesomeIcon icon={faLock} className="lock-icon" /> : null}
                </div>
            </div> */}

            <div className="block-modules">
                <div className="modules-container">

                    <div className="modules">
                        <h2>{cookies?.language === "en" ? "Low-tension Modules" : "Module joasa tensiune"} </h2>
                        <div className={`module module-category ${activeModule === "edit" || !activeModule ? "active" : ""}`} onClick={() => {
                            setActiveModule("edit");
                            
                        }}>
                            <FontAwesomeIcon icon={faPencilRuler} className="module-icon"/>
                            <p>{cookies?.language === "en" ? "Edit" : "Editare"}</p>
                            <FontAwesomeIcon className="read-icon" onClick={(e : any) => {
                                let target = e.target;

                                while (!target?.classList?.contains("read-icon")) target = target.parentElement;

                                e.target.classList.add("active");
                                setChangeModule(0);
                                
                            }} icon={faBookOpen} />
                        </div>

                        <div className={`module module-category ${activeModule === "functional" ? "active" : ""}`} onClick={() => setActiveModule("functional")}>
                            <FontAwesomeIcon icon={faLightbulb} className="module-icon" />
                            <p>{cookies?.language === "en" ? "Functional" : "Functionare"}</p>
                            <FontAwesomeIcon className="read-icon" icon={faBookOpen} onClick={(e : any) => {
                                let target = e.target;

                                while (!target?.classList?.contains("read-icon")) target = target.parentElement;

                                e.target.classList.add("active");
                                setChangeModule(1);

                            }} />
                        </div>

                        <div className={`module module-category ${activeModule === "debugging" ? "active" : ""}`} onClick={() => setActiveModule("debugging")}>
                            <FontAwesomeIcon icon={faPlugCircleCheck} className="module-icon" />
                            <p>{cookies?.language === "en" ? "Debug" : "Depanare"}</p>
                            <FontAwesomeIcon className="read-icon" icon={faBookOpen} onClick={(e : any) => {
                                let target = e.target;

                                while (!target?.classList?.contains("read-icon")) target = target.parentElement;

                                e.target.classList.add("active");
                                setChangeModule(2);

                            }} />
                        </div>
                    </div>

                    <div 
                        className={`module-context ${activeModule === "edit" || activeModule === "functional" || activeModule === "debugging" ? "low-tension" : "high-tension"} ${activeModule}`} 
                        ref={moduleContextRef}
                        onClick={() => {
                            console.log(`/${activeModule === "edit" || activeModule === "functional" || activeModule === "debugging" ? "low-tension" : "high-tension"}${activeModule === "edit-high-tension" ? '-edit' : `/${activeModule}`}`);
                            navigate(`/${activeModule === "edit" || activeModule === "functional" || activeModule === "debugging" ? "low-tension" : "high-tension"}${activeModule === "edit-high-tension" ? '-edit' : `/${activeModule}`}`);
                        }}
                    >
                        <h2 className="module-title">{activeModule === "edit" || !activeModule ? (cookies?.language === "en" ? "Edit" : "Editare")  : activeModule === "functional" ? (cookies?.language === "en" ? "Functional" : "Functionare") :  activeModule === "debugging" ? (cookies?.language === "en" ? "Debugging" : "Depanare") : activeModule === "edit-high-tension" ? (cookies?.language === "en" ? "Stations" : "Stații") : activeModule === "learning" ? (cookies?.language === "en" ? "Learning" : "Invatare") : activeModule === "training" ? (cookies?.language === "en" ? "Training" : "Antrenare") : activeModule === "testing" ? (cookies?.language === "en" ? "Testing" : "Testare") : ""}</h2>
                        
                        <img src={ZenLogoWhite} alt="zen logo" /> 
                    </div>

                    <div className="modules">
                        <h2>{cookies?.language === "en" ? "High-tension Modules" : "Module inalta tensiune"}</h2>

                        {data?.userData?.admin ?  <div className={`module module-category ${activeModule === "edit-high-tension" ? "active" : ""}`} onClick={() => setActiveModule("edit-high-tension")}>
                            <FontAwesomeIcon icon={faLinesLeaning} className="module-icon"/>
                            <p>{cookies?.language === "en" ? "Stations" : "Stații"}</p>
                            <FontAwesomeIcon className="read-icon" icon={faBookOpen} onClick={(e : any) => {
                                let target = e.target;

                                while (!target?.classList?.contains("read-icon")) target = target.parentElement;

                                e.target.classList.add("active");
                                setChangeModule(6);

                            }} />
                        </div> : null}

                        <div className={`module module-category ${activeModule === "learning" ? "active" : ""}`} onClick={() => setActiveModule("learning")}>
                            <FontAwesomeIcon icon={faLinesLeaning} className="module-icon"/>
                            <p>{cookies?.language === "en" ? "Learning" : "Invatare"}</p>
                            <FontAwesomeIcon className="read-icon" icon={faBookOpen} onClick={(e : any) => {
                                let target = e.target;

                                while (!target?.classList?.contains("read-icon")) target = target.parentElement;

                                e.target.classList.add("active");
                                setChangeModule(3);

                            }} />
                        </div>

                        <div className={`module module-category ${activeModule === "training" ? "active" : ""}`} onClick={() => setActiveModule("training")}>
                            <FontAwesomeIcon icon={faBook} className="module-icon" />
                            <p>{cookies?.language === "en" ? "Training" : "Antrenare"}</p>
                            <FontAwesomeIcon className="read-icon" icon={faBookOpen} onClick={(e : any) => {
                                let target = e.target;

                                while (!target?.classList?.contains("read-icon")) target = target.parentElement;

                                e.target.classList.add("active");
                                setChangeModule(4);

                            }} />
                        </div>

                        <div className={`module module-category ${activeModule === "testing" ? "active" : ""}`} onClick={() => setActiveModule("testing")}>
                            <FontAwesomeIcon icon={faGraduationCap} className="module-icon" />
                            <p>{cookies?.language === "en" ? "Testing" : "Testare"}</p>
                            <FontAwesomeIcon className="read-icon" icon={faBookOpen} onClick={(e : any) => {
                                let target = e.target;

                                while (!target?.classList?.contains("read-icon")) target = target.parentElement;

                                e.target.classList.add("active");
                                setChangeModule(5);

                            }} />
                        </div>
                    </div>
                    
                </div>
            </div>

            <div className="start-button-container-2">
                <div className="start-button" onClick={() => navigate(`/${activeModule === "edit" || activeModule === "functional" || activeModule === "debugging" ? "low-tension" : "high-tension"}/${activeModule}`)}>
                    <h2>START</h2>
                </div>
            </div>

            <div className={`docs-container ${changeModule !== -1 ? "active" : ""}`}>
                <textarea value={changeModuleText} disabled={true} />
                <FontAwesomeIcon icon={faTimes} className="close-icon" onClick={() => {
                    setChangeModule(-1);
                    setChangeModuleText("");
                }} />
            </div>

        </div>
    );
};

export default Categories;