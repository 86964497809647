import { useCallback, useLayoutEffect, useState, useEffect, lazy, useRef } from 'react';
import { useCookies } from 'react-cookie';
import HeaderHome from '../../Components/Header_Home/Header_Home';
import './Home.css';
import ZenLogo from "../../imgs/ZenLogo.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faChartSimple, faComments, faDatabase, faExpand, faGraduationCap, faHandPointUp, faHeadset, faLinesLeaning, faPause, faPencilRuler, faPenRuler, faPlay, faPlugCircleCheck, faUndo } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons"
import Footer from '../../Components/Footer_home/Footer';
import swoosh from "../../imgs/swoosh.svg";
import ZenLogoWhite from "../../imgs/ZenLogo_white.svg";
import ipadMockup from "../../imgs/ipad-photo.webp";

import { useLocation, useNavigate } from 'react-router-dom';
import { faLightbulb } from '@fortawesome/free-regular-svg-icons';

const Home = ({ data } : { data : any }) => {

    const [cookies, setCookies] = useCookies();
    const [videoEnded, setVideoEnded] = useState(performance.navigation.type === 0 ? false : true);
    // const currentVideoSource = window.innerWidth / window.innerHeight >= 1.3 * (9 / 16) ? zenAnimation : zenAnimationMobile;
    const videoRef = useRef();
    const tutorialVideosRef = useRef([]);
    const [foundUser, setFoundUser] = useState(data?.userData != null);

    const [videosStatus, setVideosStatus] = useState([]);
    const [activeModule, setActiveModule] = useState<string | null>(null);

    const navigate = useNavigate();
    const location = useLocation();

    const isTouchDevice = useCallback(() => {
        return (('ontouchstart' in window) || (navigator.maxTouchPoints > 0));
    }, []);


    // useEffect(() => {
    //     if (!videoEnded) return;
    //     if (!tutorialVideosRef || !tutorialVideosRef?.current) return;
    //     console.log("effect");
    //     setVideosStatus(currVideos => tutorialVideosRef.current.map(video => "playing"));
    //     tutorialVideosRef.current.forEach(video => {
    //         video.playbackRate = 1.2;
    //     });
    // }, [videoEnded, tutorialVideosRef]);
   

    // const handleVideoEnded = () => {
    
    //     document.body.style.opacity = 0;
    //     setTimeout(() => {
    //         setVideoEnded(true);
    //     }, 350);
    //     setTimeout(() => {
    //         document.body.style.opacity = 1;
    //         document.body.style.background = 'var(--background)';

    //     }, 650);
    // };

    // const startVideo = () => {
    //     detectPowerSavingMode().then(result => {
    //         if (result) {
    //             document.body.style.background = 'var(--background)';
    //             return setVideoEnded(true);
    //         }
    //     });

    //     videoRef.current.playbackRate = 1.5;
    //     document.body.style.background = '#D9D8D9';
    // };

    // useEffect(() => {
    //     console.log("change");
    //     // document.body.style.overflowX = 'hidden';
    // }, []);

    // const userConnected = async () => {
    //     if (!cookies["user-id"] || !cookies["pwd"]) return;
    //     const options = {
    //         method: "POST",
    //         headers: {
    //             "Content-Type": "application/json"
    //         },
    //         body: JSON.stringify({
    //             hashedPassword: cookies["pwd"],
    //             userId: cookies["user-id"]
    //         })
    //     };

    //     try {
    //         const request = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/auth/login-remember`, options);
    //         const response = await request.json();
    //         if (!response.success) return;
    //         setFoundUser(response.admin ? "admin" : "user");
    //     } catch(e) {
    //         console.log(e);
    //     }
    // };

    // useEffect(() => {
    //     (async () => {
    //         await userConnected();
    //     })();
    // }, []);

    // useEffect(() => {
    //     if (!tutorialVideosRef?.current) return;
    //     // console.log(tutorialVideosRef.current);
    //     // tutorialVideosRef.current.forEach(video => {
    //     //     video.load();
    //     // });

    // }, [tutorialVideosRef]);

    const moduleContextRef = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
        if (!activeModule || !moduleContextRef?.current) return;
        moduleContextRef.current?.scrollIntoView({ block: "center", behavior: "smooth" });
    }, [activeModule]);

    
    return (
        <div className="home-container">
            <HeaderHome data={data} activePage="home"/>
            
            <div className="content">
                
                <div className="block-1">
                    <h2 className="title">
                        Digitalizăm învățământul <span></span> <br />  
                        cu <span className='swoosh'>ZenVolt. <img src={swoosh} alt="swoosh" /></span>
                     
                    </h2>

                    <p className="description">{cookies.language === "en" ? "ZenVolt is a software designed for medical cabients, easy to use, which simplifies handling with appointments." : "ZenVolt este un soft, de tip simulator, destinat învățământului, ce este intuitiv și ușor de folosit, simplificând învățarea elevilor, cât și a electricienilor."}</p>

                    <button type="button" onClick={() => foundUser ? navigate("/categories") : navigate("/login")}>
                        {foundUser ? <p>{cookies?.language === "en" ? "Towards app" : "Spre aplicație"}</p> : <p>{cookies?.language === "en" ? "Login" : "Login"}</p>}
                    </button>

                    {/* <p className="sm-text">{cookies?.language === "en" ? "No credit card required" : "Fără card de credit"}</p> */}
                </div>
                
                <div className="block-2">
                    {/* Videoclip cu prezentare Zentec / 3 ecrane laptop, mobil, tableta cu screenshot din aplicatie */}

                    {/* <img loading='lazy' src={mockup} className="mockup" alt="zentec on all devices" width={100} height="auto" /> */}
                </div>

                <div className="block-3">
                    {/* Rating-uri / testimoniale */}
                </div>


                <div className="block-4">
                
                    <div className="block-container">
                        <h2>
                            Pregătire interactivă <br /> <span>pe tableta</span>
                    </h2>
                        <p>Zenvolt poate fi utilizat cu ușurință de pe o tabletă, facilitând o experiență de învățare interactivă și eficientă în cadrul educației. Ne putem folosi de tehnologie pentru a atrage si a ajuta elevii, cat si electricienii sa evolueze.</p>
                    </div>

                        <div className="shadow-line"></div>
         
                    
                    {/* Avantajele noastre ca la bookr */}

                    <div className="block-container">
                         <img src={ipadMockup} className="mockup" alt="zenvolt on all devices" width={150} />
                    </div>
                </div>

                <div className="block-5 block-modules">
                    <h2 className="title sm-title">
                        Ce <span>oferim?</span><br />
                    </h2>

                    <button type="button" onClick={() => foundUser ? navigate("/categories") : navigate("/login")}>
                        {foundUser ? <p>{cookies?.language === "en" ? "Towards app" : "Spre aplicație"}</p> : <p>{cookies?.language === "en" ? "Login" : "Login"}</p>}
                    </button>


                    <div className="modules-container">
                        <div className="modules">
                            <h2>{cookies?.language === "en" ? "Low-tension Modules" : "Module joasa tensiune"} <span>⬇️</span></h2>

                            <div className={`module ${activeModule === "edit" || !activeModule ? "active" : ""}`} onClick={() => {
                                setActiveModule("edit");
                                
                            }}>
                                <FontAwesomeIcon icon={faPencilRuler} className="module-icon"/>
                                <p>{cookies?.language === "en" ? "Edit" : "Editare"}</p>
                            </div>

                            <div className={`module ${activeModule === "functional" ? "active" : ""}`} onClick={() => setActiveModule("functional")}>
                                <FontAwesomeIcon icon={faLightbulb} className="module-icon" />
                                <p>{cookies?.language === "en" ? "Functional" : "Functionare"}</p>
                            </div>

                            <div className={`module ${activeModule === "debugging" ? "active" : ""}`} onClick={() => setActiveModule("debugging")}>
                                <FontAwesomeIcon icon={faPlugCircleCheck} className="module-icon" />
                                <p>{cookies?.language === "en" ? "Debug" : "Depanare"}</p>
                            </div>
                        </div>

                        <div className="module-context" ref={moduleContextRef}>
                            <h2>{activeModule === "edit" || !activeModule ? (cookies?.language === "en" ? "Edit" : "Editare")  : activeModule === "functional" ? (cookies?.language === "en" ? "Functional" : "Functionare") :  activeModule === "debugging" ? (cookies?.language === "en" ? "Debugging" : "Depanare") : activeModule === "learning" ? (cookies?.language === "en" ? "Learning" : "Invatare") : activeModule === "training" ? (cookies?.language === "en" ? "Training" : "Antrenare") : activeModule === "testing" ? (cookies?.language === "en" ? "Testing" : "Testare") : ""}</h2>
                            {activeModule === "edit" || !activeModule ? (
                                <p>{cookies?.language === "en" ? "In this module you can create an electrical diagram from scratch, the diagram having the possibility to be printed in PDF format (A4 / A3). Provides accounts for students / teachers." : "În acest modul se poate crea o schemă electrică de la zero, schema avand posibilitatea sa fie imprimata in format PDF ( A4 / A3). Prevede conturi pentru elevi / profesori."}</p>
                            ) : activeModule === "functional" ? (
                                <p>{cookies?.language === "en" ? "In this module, it is possible to show how an electrical scheme works with predefined schemes (lighting a light bulb, simple starting of a three-phase asynchronous motor, reversing inverter, star-delta starting, etc.)" : "În acest modul se poate arată cum funcționează o schemă electrică având scheme predefinite (aprinderea unui bec, pornirea simplă a unui motor asincron trifazat, inversorul de sens, pornirea stea triunghi, etc.)"}</p>
                            ) : activeModule === "debugging" ? (
                                cookies?.language === "en" ? (
                                    <p>
                                        In this module, the teacher has access to a button for creating defects within predefined electrical schemes and to create defects within the schemes.<br />
                                        The student (operator), with the help of a virtual measuring device, will be able to make measurements to locate the electrical defect. <br />
                                        The student (operator) can simulate different electrical measurements: <br />

                                        <ul>
                                            <li>With voltage 200 VAC / 700 VAC</li>
                                            <li>No voltage (with buzzer)</li>
                                            <li>No voltage (megometer)</li>
                                        </ul>
                                    </p>
                                ) : (
                                    <p>În acest modul profesorul are acces la un buton de creere de defecte in cadrul unor scheme electrice predefinite și să creeze defecte în cadrul schemelor. <br />
                                        Elevul (operatorul), cu ajutorul unui aparat de masura virtual va putea sa faca masuratori pentru a localiza defectul electric. <br />
                                        Elevul (operatorul) poate simula diferite măsurători electrice: <br />
                                        <ul>
                                            <li>Cu tensiune 200 VCA / 700 VCA</li>
                                            <li>Fără tensiune (cu buzeru)</li>
                                            <li>Fără tensiune (cu megometru)</li>
                                        </ul>
                                    </p>
                                )
                            ) : activeModule === "learning" ? (
                                cookies?.language === "en" ? (
                                    <p>
                                       In this mode, the student (electrician) will be taught to do maneuvers with the help of the computer. The dispatcher (computer) will give predefined voice instructions to the student, which he will have to execute, after which the dispatcher will check if the student (electrician) has understood the respective command.
                                    </p>
                                ) : (
                                    <p>In acest modul elevul (electricianul) va fi invatat sa faca manevre cu ajutorul calculatorului. Dispecerul (calculatorul) ii va da instructiuni vocale predefinite elevului pe care va trebui sa le execute, urmand ca dispecerul sa verifice daca elevul (electricianul) a inteles comanda respectiva.</p>
                                )
                            ) : activeModule === "training" ? (
                                cookies?.language === "en" ? (
                                    <p>
                                        This module was designed to train students and future station electricians to learn to maneuver as close to reality as possible. <br />
                                        Training through maneuver execution exercises on a station simulator is particularly useful for reducing the degree of human error. <br />
                                    </p>
                                ) : (
                                    <p> Acest modul a fost conceput pentru a antrena elevii și viitorii electricieni de stație să învețe să facă manevre cât mai aproape de realitate. <br />
                                        Antrenarea prin exercitii de executare de manevre pe simulator de statii este deosebit de utila pentru reducerea gradului de eroare umana. <br />
                                    </p>
                                )
                            ) : activeModule === "testing" ? (
                                cookies?.language === "en" ? (
                                    <p>
                                       This module helps to test the station electricians, so they will have to do maneuvers, and at the end they will be scored according to the correctness of the maneuvers performed.<br /> <br />

                                        With the help of the simulator, two operation regimes are reproduced in the power station: operation regime under normal conditions (maneuvering according to standard sheets) and operation regime under emergency conditions (without operation sheets).
                                    </p>
                                ) : (
                                    <p>
                                       Acest modul ajută la testarea electricienilor de stație, astfel ei vor trebui să facă manevre, iar la final ei vor fi punctati în funcție de corectitudinea manevrelor efectuate.<br /> <br />
                                       Cu ajutorul simulatorului sunt redate două regimuri de manevre în stația electrică: regim de funcționare în condiții normale (efectuarea de manevră după foi standard) și regim de funcționare în condiții de avarie (fără foi de manevră).
                                    </p>
                                )
                            ) : null}
                            <img src={ZenLogoWhite} alt="zen logo" /> 
                        </div>

                        <div className="modules">
                            <h2>{cookies?.language === "en" ? "High-tension Modules" : "Module inalta tensiune"} <span>⬆️</span></h2>
                            <div className={`module ${activeModule === "learning" ? "active" : ""}`} onClick={() => setActiveModule("learning")}>
                                <FontAwesomeIcon icon={faLinesLeaning} className="module-icon"/>
                                <p>{cookies?.language === "en" ? "Learning" : "Invatare"}</p>
                            </div>

                            <div className={`module ${activeModule === "training" ? "active" : ""}`} onClick={() => setActiveModule("training")}>
                                <FontAwesomeIcon icon={faBook} className="module-icon" />
                                <p>{cookies?.language === "en" ? "Training" : "Antrenare"}</p>
                            </div>

                            <div className={`module ${activeModule === "testing" ? "active" : ""}`} onClick={() => setActiveModule("testing")}>
                                <FontAwesomeIcon icon={faGraduationCap} className="module-icon" />
                                <p>{cookies?.language === "en" ? "Testing" : "Testare"}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="block-5">
                    <h2 className="title sm-title">
                        Cu <span>ce</span><br />
                        ajuta <span>ZenVolt?</span>
                    </h2>

                    <div className="advantages">

                        <div className="advantage">
                            {/* <img src={bell3d} className="advantage-icon" /> */}
                            <h2>{cookies.language === "en" ? "Training students" : "Pregătirea elevilor"} <span>👨🏻‍🎓</span></h2>
                            <p>{cookies?.language === "en" ? "ZenVolt enables students to learn through interactive modules, ensuring they are prepared to meet the challenges and opportunities in the field of electricity. Whether students access our platform from a laptop, desktop or even tablet, the learning experience remains just as engaging and effective." : "ZenVolt permite elevilor să învețe prin intermediul modulelor interactive, asigurându-ne că sunt pregătiți să facă față provocărilor și oportunităților din aria electricității. Indiferent dacă elevii accesează platforma noastră de pe laptop, desktop sau chiar tabletă, experiență de învățare rămâne la fel de captivantă și eficientă."}</p>
                        </div>

                        <div className="advantage">
                            {/* <img src={calendar3d} className="advantage-icon" /> */}
                            <h2>{cookies.language === "en" ? "Selecting the right people for the position of electrician" : "Selectarea persoanelor potrivite pentru postul de electrician"} <span>👷🏻‍♂️</span></h2>
                            <p>{cookies.language === "en" ? "The selection process becomes more efficient and skill-oriented, ensuring a proper match between the requirements of the electrician position and the individual qualities of each applicant. With the help of the data obtained from these assessments, employers can make informed and objective decisions when choosing the right people for the position of electrician." : "Procesul de selecție devine mai eficient și mai orientat spre competențe, asigurând o potrivire corespunzătoarea între cerințele postului de electrician și calitățile individuale ale fiecărui aplicant. Cu ajutorul datelor obținute din aceste evaluări, angajatorii pot lua decizii informate și obiective atunci când aleg persoanele potrivite pentru postul de electrician."}</p>
                        
                        </div>

                        <div className="advantage">
                            {/* <img src={support3d} className="advantage-icon"/> */}
                            <h2>{cookies.language === "en" ? "Training of electricians" : "Pregătirea electricienilor"} <span>🧑🏼‍💻</span></h2>
                            <p>{cookies.language === "en" ? "ZenVolt is committed to providing an advanced educational platform that not only imparts knowledge but also develops future electricians into competent and confident electrical professionals." : "ZenVolt se angajează să ofere o platforma educațională avansată, care nu doar transmite cunoștințe, ci și dezvoltă viitori electricieni în profesioniști competenți în domeniul electric."}</p>
                        </div>

                        <div className="advantage">
                            {/* <img src={backup3d} className="advantage-icon" /> */}
                            <h2>{cookies.language === "en" ? "Testing of electricians within the company" : "Testarea electricienilor în cadrul firmei"} 💯</h2>
                            <p>{cookies.language === "en" ? "The adaptability of the zenvolt software brings added value to the testing of electricians within companies. Thus, companies can test electricians in a flexible and interactive way." : "Adaptabilitatea soft-ului zenvolt aduce o valoare adăugată testării elecricienilor în cadrul firmelor. Astfel, companiile pot testa electricienii într-un mod flexibil și interactiv."}</p>
                        </div>


                    </div>

                    {/* <p className="description">Sa iti dea tie si afacerii tale o stare ZEN 😌</p> */}

                    {/* Patru cadrane ca la invideo.io cu ce ofera zentec*/}
                </div>

                {/* <h2 className="title sm-title">
                    Aplicația <span>mobilă</span>
                </h2> */}


                {/* <div className="block-6">
                  

                    Cadranul cu mobil de la invideo.io 
                    {window.innerWidth >= 1100 ? 
                        <>
                            <div className="block-container">
                                <h2>Învață <br /> din mers</h2>
                            </div>
                            <div className="block-container">
                                <img src={ipadMockup} className="mockup-img" alt="zentec on mobile" width={300} height="auto" />
                            </div>
                        </> : 
                        <>
                            <div className="block-container">
                                <img src={ipadMockup} className="mockup-img" alt="zentec on mobile" width={300} height="auto" />
                            </div>
                            <div className="block-container">
                                <h2>Învață <br /> din mers</h2>
                            </div>
                        </>
                    }
                    
                    <div className="block-container">
                        <p className="highlight">{cookies.language === "en" ? "The easiest way to manage your business is on your phone.  " : "Cel mai usor mod de a invata cand esti plecat este de pe tableta."}</p>
                        <p>{cookies.language === "en" ? "We know how important it is for you to manage your business whenever you want, wherever you want, so we made our platform compatible for mobile access." : "Stim cat de important este sa poti invata de pe orice device, asa ca am conceput platforma noastra sa poata fi accesata de pe orice dispozitiv. De acum, poti invata de oriunde!"}</p>
                    </div>
                </div> */}

                {/* <div className="block-5">
                    
                    <h2 className="title sm-title">
                        Hai să vezi cum <br />
                        îți simplificăm <span>cabinetul</span>
                    </h2>

                    <div className="advantages videos-container">

                    <div className="advantage">
                            <img src={backup3d} className="advantage-icon" />
                            <h2>{cookies.language === "en" ? "Add doctor's schedule" : "Adaugare program doctor"}</h2>
                            <div className="video-container">
                                <video 
                                    src={`${doctorScheduleVideo}#t=1`} 
                                    onEnded={() => setVideosStatus(currStatuses => {
                                        return currStatuses.map((currStatus, idx) => {
                                            if (idx === 3) return "ended";
                                            return currStatus;
                                        });
                                    })}
                                    playsInline
                                    controls={isTouchDevice() ? true : false}
                                    muted
                                    style={{ width: "100%", height: "100%", borderRadius: '20px' }}
                                    ref={ref => tutorialVideosRef.current[3] = ref}
                                    onClick={() => {
                                        setVideosStatus(currStatuses => {
                                            return currStatuses.map((currStatus, idx) => {
                                                if (idx === 3) {
                                                    if (currStatus === "playing") return "paused";
                                                    else return "playing";
                                                }
                                                return currStatus;
                                            });
                                        });
                                    }}
                                    
                                />

                                {isTouchDevice() ? null : <div className="play-button" onClick={(e) => {
                                    if (videosStatus[3] === "paused") tutorialVideosRef.current[3].pause(); 
                                    else if(videosStatus[3] === "playing") tutorialVideosRef.current[3].play();
                                    else {
                                        tutorialVideosRef.current[3].pause();
                                        tutorialVideosRef.current[3].currentTime = 0;
                                        tutorialVideosRef.current[3].play();
                                        setVideosStatus(currStatuses => {
                                            return currStatuses.map((currStatus, idx) => {
                                                if (idx === 3) return "playing";
                                                return currStatus;
                                            });
                                        });
                                    }
                                
                                    setVideosStatus(currStatuses => {
                                        return currStatuses.map((currStatus, idx) => {
                                            if (idx === 3) {
                                                if (currStatus === "playing") return "paused";
                                                else return "playing";
                                            }
                                            return currStatus;
                                        });
                                    });
                                }}>
                                    <FontAwesomeIcon icon={videosStatus?.[3] === "paused" ? faPause : videosStatus?.[3] === "playing" ? faPlay : faUndo} className="play-icon" />
                                </div>}

                                {isTouchDevice() ? null : <div className="fullscreen-button" onClick={() => {
                                    if (tutorialVideosRef.current[3].requestFullscreen) {
                                        tutorialVideosRef.current[3].requestFullscreen();
                                      } else if (tutorialVideosRef.current[3].webkitRequestFullscreen)
                                        tutorialVideosRef.current[3].webkitRequestFullscreen();
                                      } else if (tutorialVideosRef.current[3].msRequestFullscreen) 
                                        tutorialVideosRef.current[3].msRequestFullscreen();
                                      }

                                }}>
                                    <FontAwesomeIcon icon={faExpand} className="fullscreen-icon" />
                                </div>}
                            </div>
                        </div>


                        <div className="advantage">
                            <img src={calendar3d} className="advantage-icon" /> 
                            <h2>{cookies.language === "en" ? "Add patient" : "Adaugare pacient"}</h2>
                            <div className="video-container">
                                <video 
                                    src={`${patientVideo}#t=1`} 
                                    onEnded={() => setVideosStatus(currStatuses => {
                                        return currStatuses.map((currStatus, idx) => {
                                            if (idx === 1) return "ended";
                                            return currStatus;
                                        });
                                    })}
                                    playsInline 
                                    controls={isTouchDevice() ? true : false}
                                    muted
                                    style={{ width: "100%", height: "100%", borderRadius: '20px' }}
                                    ref={ref => tutorialVideosRef.current[1] = ref}
                                    onClick={() => {
                                        setVideosStatus(currStatuses => {
                                            return currStatuses.map((currStatus, idx) => {
                                                if (idx === 1) {
                                                    if (currStatus === "playing") return "paused";
                                                    else return "playing";
                                                }
                                                return currStatus;
                                            });
                                        });
                                    }}
                                   
                                />

                                {isTouchDevice() ? null : <div className="play-button" onClick={(e) => {
                                    if (videosStatus[1] === "paused") tutorialVideosRef.current[1].pause(); 
                                    else if(videosStatus[1] === "playing") tutorialVideosRef.current[1].play();
                                    else {
                                        tutorialVideosRef.current[1].pause();
                                        tutorialVideosRef.current[1].currentTime = 0;
                                        tutorialVideosRef.current[1].play();
                                        setVideosStatus(currStatuses => {
                                            return currStatuses.map((currStatus, idx) => {
                                                if (idx === 1) return "playing";
                                                return currStatus;
                                            });
                                        });
                                    }
                                
                                    setVideosStatus(currStatuses => {
                                        return currStatuses.map((currStatus, idx) => {
                                            if (idx === 1) {
                                                if (currStatus === "playing") return "paused";
                                                else return "playing";
                                            }
                                            return currStatus;
                                        });
                                    });
                                }}>
                                    <FontAwesomeIcon icon={videosStatus?.[1] === "paused" ? faPause : videosStatus?.[1] === "playing" ? faPlay : faUndo} className="play-icon" />
                                </div>}

                                {isTouchDevice() ? null : <div className="fullscreen-button" onClick={() => {
                                    if (tutorialVideosRef.current[1].requestFullscreen) {
                                        tutorialVideosRef.current[1].requestFullscreen();
                                      } else if (tutorialVideosRef.current[1].webkitRequestFullscreen)
                                        tutorialVideosRef.current[1].webkitRequestFullscreen();
                                      } else if (tutorialVideosRef.current[1].msRequestFullscreen) 
                                        tutorialVideosRef.current[1].msRequestFullscreen();
                                      }

                                }}>
                                    <FontAwesomeIcon icon={faExpand} className="fullscreen-icon" />
                                </div>}
                            </div>
                        </div>

                        <div className="advantage">
                            <img src={bell3d} className="advantage-icon" /> 
                            <h2>{cookies.language === "en" ? "Add appointment" : "Adaugare programare"}</h2>
                            <div className="video-container">
                                <video 
                                    src={`${appointmentVideo}#t=1`} 
                                    
                                    controls={isTouchDevice() ? true : false}
                                    muted
                                    style={{ width: "100%", height: "100%", borderRadius: '20px' }}
                                    ref={ref => tutorialVideosRef.current[0] = ref}
                                    onEnded={() => setVideosStatus(currStatuses => {
                                        return currStatuses.map((currStatus, idx) => {
                                            if (idx === 0) return "ended";
                                            return currStatus;
                                        });
                                    })}
                                    onClick={() => {
                                        setVideosStatus(currStatuses => {
                                            return currStatuses.map((currStatus, idx) => {
                                                if (idx === 0) {
                                                    if (currStatus === "playing") return "paused";
                                                    else return "playing";
                                                }
                                                return currStatus;
                                            });
                                        });
                                    }}

                                    
                                />

                                {isTouchDevice() ? null : <div className="play-button" onClick={(e) => {
                                    if (videosStatus[0] === "paused") tutorialVideosRef.current[0].pause(); 
                                    else if(videosStatus[0] === "playing") tutorialVideosRef.current[0].play();
                                    else {
                                        tutorialVideosRef.current[0].pause();
                                        tutorialVideosRef.current[0].currentTime = 0;
                                        tutorialVideosRef.current[0].play();
                                        setVideosStatus(currStatuses => {
                                            return currStatuses.map((currStatus, idx) => {
                                                if (idx === 0) return "playing";
                                                return currStatus;
                                            });
                                        });
                                    }
                                
                                    setVideosStatus(currStatuses => {
                                        return currStatuses.map((currStatus, idx) => {
                                            if (idx === 0) {
                                                if (currStatus === "playing") return "paused";
                                                else return "playing";
                                            }
                                            return currStatus;
                                        });
                                    });
                                }}>
                                    <FontAwesomeIcon icon={videosStatus?.[0] === "paused" ? faPause : videosStatus?.[0] === "playing" ? faPlay : faUndo} className="play-icon" />
                                </div>}

                                {isTouchDevice() ? null : <div className="fullscreen-button" onClick={() => {
                                    if (tutorialVideosRef.current[0].requestFullscreen) {
                                        tutorialVideosRef.current[0].requestFullscreen();
                                      } else if (tutorialVideosRef.current[0].webkitRequestFullscreen)
                                        tutorialVideosRef.current[0].webkitRequestFullscreen();
                                      } else if (tutorialVideosRef.current[0].msRequestFullscreen)
                                        tutorialVideosRef.current[0].msRequestFullscreen();
                                      }
                                }}>
                                    <FontAwesomeIcon icon={faExpand} className="fullscreen-icon" />
                                </div>}
                            </div>
                        </div>

                        <div className="advantage">
                            <img src={support3d} className="advantage-icon"/>
                            <h2>{cookies.language === "en" ? "Add doctor" : "Adaugare doctor"}</h2>
                            <div className="video-container">
                                <video 
                                    src={`${doctorVideo}#t=1`} 
                                    
                                    onEnded={() => setVideosStatus(currStatuses => {
                                        return currStatuses.map((currStatus, idx) => {
                                            if (idx === 2) return "ended";
                                            return currStatus;
                                        });
                                    })}
                                    playsInline 
                                    controls={isTouchDevice() ? true : false}
                                    muted
                                    style={{ width: "100%", height: "100%", borderRadius: '20px' }}
                                    ref={ref => tutorialVideosRef.current[2] = ref}
                                    onClick={() => {
                                        setVideosStatus(currStatuses => {
                                            return currStatuses.map((currStatus, idx) => {
                                                if (idx === 2) {
                                                    if (currStatus === "playing") return "paused";
                                                    else return "playing";
                                                }
                                                return currStatus;
                                            });
                                        });
                                    }}
                                  
                                />

                                {isTouchDevice() ? null : <div className="play-button" onClick={(e) => {
                                    if (videosStatus[2] === "paused") tutorialVideosRef.current[2].pause(); 
                                    else if(videosStatus[2] === "playing") tutorialVideosRef.current[2].play();
                                    else {
                                        tutorialVideosRef.current[2].pause();
                                        tutorialVideosRef.current[2].currentTime = 0;
                                        tutorialVideosRef.current[2].play();
                                        setVideosStatus(currStatuses => {
                                            return currStatuses.map((currStatus, idx) => {
                                                if (idx === 2) return "playing";
                                                return currStatus;
                                            });
                                        });
                                    }
                                
                                    setVideosStatus(currStatuses => {
                                        return currStatuses.map((currStatus, idx) => {
                                            if (idx === 2) {
                                                if (currStatus === "playing") return "paused";
                                                else return "playing";
                                            }
                                            return currStatus;
                                        });
                                    });
                                }}>
                                    <FontAwesomeIcon icon={videosStatus?.[2] === "paused" ? faPause : videosStatus?.[2] === "playing" ? faPlay : faUndo} className="play-icon" />
                                </div>}

                                {isTouchDevice() ? null : <div className="fullscreen-button" onClick={() => {
                                    if (tutorialVideosRef.current[2].requestFullscreen) {
                                        tutorialVideosRef.current[2].requestFullscreen();
                                      } 
                                      if (tutorialVideosRef.current[2].webkitRequestFullscreen)
                                        tutorialVideosRef.current[2].webkitRequestFullscreen();
                                      } 
                                      if (tutorialVideosRef.current[2].msRequestFullscreen) {
                                        tutorialVideosRef.current[2].msRequestFullscreen();
                                      }

                                }}>
                                    <FontAwesomeIcon icon={faExpand} className="fullscreen-icon" />
                                </div>}
                            </div>
                        </div>

                    </div>

                     <p className="description">Sa iti dea tie si afacerii tale o stare ZEN 😌</p>

                        Patru cadrane ca la invideo.io cu ce ofera zentec
                </div> */}
                
                {/* <div className="fixed-icon-container">
                    <a href="https://wa.me/+40760331658" target="_blank">
                        <FontAwesomeIcon icon={faWhatsapp} className="fixed-icon" />
                    </a>
                </div> */}


                <div className="block-7 final-block">
                    <div className="logo">

                    </div>
                    <h2 className="title last"><span>Devino electrician cu ZenVolt.</span> <br /> Folosește tehnologia pentru a învață. </h2>

                    <img src={ZenLogoWhite} alt="logo-image" className="logo-icon" width={100} height="auto" />

                    <p>© {new Date().getFullYear()}. All rights reserved.</p>
                    <p>{cookies?.language === "en" ? "Created by Barbu Mihai." : "Creat de Barbu Mihai."}</p>
                </div>


            </div>

            {/* <Footer /> */}

        </div>
    );
};

export default Home;