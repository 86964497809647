import { faCheck, faCircleXmark, faCircleExclamation, faDoorClosed, faDoorOpen, faFlagCheckered, faInfo, faInfoCircle, faListOl, faPlus, faTimes, faTriangleExclamation, faPrint } from "@fortawesome/free-solid-svg-icons";
import { faCircleCheck, faStar, faUser } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Header from "../../Components/Header/Header";
import "../LeaderboardDebugSession/LeaderboardDebugSession.css";
import "./LeaderboardTestingSession.css";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

const LeaderboardTestingSession = ({ data, socket, sessionName }  : { data : any, socket: any, sessionName: string }) => {

    const params = useParams();
    const sessionId = params?.sessionId;
    console.log(sessionId);
    const [results, setResults] = useState<Array<any>>([]);
    const [cookies, setCookie] = useCookies();
    const navigate = useNavigate();
    const location = useLocation();
    
    const realSessionName = location?.state?.sessionName || sessionName;

    const [title, setTitle] = useState<string | null>(null);
    const [sessionFinished, setSessionFinished] = useState<boolean>(false);
    const [currentResultsTarget, setCurrentResultsTarget] = useState<any>(null);
    const [activeUser, setActiveUser] = useState<any | null>(null);
    const [problems, setProblems] = useState<any>();

    console.log(activeUser);
    
    const getLeaderboard = async () => {

        const options = {
            method: "GET",
            headers: {
                'Content-Type': 'application/json'
            }
        };

        try {
            console.log(data.userData.teacherId);
            const request = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/session-testing/get-results-of-session-testing/${data.userData.type === "teacher" ? data.userData.userId : data.userData.teacherId}/${sessionId}`, options);
            const response = await request.json();
            console.log(response);
            const results = response.results;
            setResults(results.filter((currUser : { userId: string }) => currUser.userId !== (data.userData.type === "teacher" ? data.userData.userId : data.userData.teacherId)));
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        (async () => {
            const options = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                },
             
            };

            try {
                const request = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/session-testing/check-id-testing/${data.userData.type === "teacher" ? data.userData.userId : data.userData.teacherId}/${sessionId}`, options);
                const response = await request.json();
                // if (!response.success) return navigate(-1);
                console.log(response);
                setTitle(response.results?.name);
                const currentResults : any = [];
                JSON.parse(response.results.problems).forEach((problemArr : any) => {
                    if (problemArr?.some((problem : { name: string }) => problem.name.toLowerCase().includes("generator"))) {
                        currentResults.unshift(problemArr);
                    } else currentResults.push(problemArr);
                });
                setProblems(currentResults);
                setSessionFinished(response.results.finished);
                await getLeaderboard();
            } catch (e) {
                console.log(e);
            }
        })(); 

    }, []);

    useEffect(() => {
        socket.on("update-results-testing", async (currResults : any) => {
            const options = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                }
            };

            try {
                const request = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/session-testing/get-results-of-session-testing/${data.userData.type === "teacher" ? data.userData.userId : data.userData.teacherId}/${sessionId}`, options);
                const response = await request.json();

                console.log(response.results);

                if (response.success) setResults(response.results);

            } catch (e) {
                console.log(e);
            }
        });
    }, []);


    const finishSessionTeacher = async () => {
        if (data.userData.type !== "teacher") return;
        
        const options = {
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                teacherId: data.userData.userId,
                sessionId
            })
        };

        try {
            const request = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/session-testing/finish-session-testing`, options);
            const response = await request.json();
            console.log(response);
            if (response.success) {
                socket.emit("session-testing-finished", sessionId, data.userData.userId);
            }               
            window.location.reload();

            
        } catch (e) {
            console.log(e);
        }
    };

    const pageDataRef = useRef<HTMLDivElement>(null);

    const printResult = async () => {
        if (!nonPrintableContentRef?.current || !subtitleRef?.current || !sessionButtonRef?.current || !pageDataRef?.current) return;
        nonPrintableContentRef.current.style.display = 'none';
        subtitleRef.current.style.display='none';
        sessionButtonRef.current.style.display = 'none';
        pageDataRef.current.style.padding = '8px 12px 2px';

        const pdf = new jsPDF({
            orientation: "landscape",
            unit: "mm",
            format: [297, 210]
        });

        const canvas = await html2canvas(pageDataRef.current);
        const imgData = canvas.toDataURL('image/png');
        
        pdf.addImage(imgData, "png", 0, 0, 297, 210); 

        nonPrintableContentRef.current.style.display = 'initial';
        subtitleRef.current.style.display = 'block';
        sessionButtonRef.current.style.display = 'initial';
        pageDataRef.current.style.padding = 'initial';


        setTimeout(() => {
            pdf.save(`${activeUser?.name}-${realSessionName?.split("(")?.[0]}.pdf`);
         
        }, 200);
    };

    const subtitleRef = useRef<HTMLHeadingElement>(null);
    const nonPrintableContentRef = useRef<HTMLDivElement>(null);
    const sessionButtonRef = useRef<HTMLDivElement>(null);

    

    return (
        <div className="content">
            <div className="non-printable-content" ref={nonPrintableContentRef}>

                <Header data={data} socket={socket} />

                {realSessionName ? <h2 className="title" style={{ textAlign: "center" }}>{realSessionName}</h2> : null}
 
                {data.userData.type === "teacher" ? <div className="join-session-container" onClick={() => {
                    window.history.replaceState({
                        sessionName: realSessionName
                    }, "");
                    setTimeout(() => window.location.replace(`/session-testing/${sessionId}/1`), 100);
                }}>
                    <FontAwesomeIcon icon={faDoorOpen} className="icon"/>
                    <h2>{cookies?.language === "en" ? "Join session" : "Intra in sesiune"}</h2>
                </div> : null}

                <div className="leaderboard">

                    <div className="row">

                        <div className="column">
                            <FontAwesomeIcon icon={faListOl} className="column-icon" />
                            <p>{cookies?.language === "en" ? "No." : "Nr."}</p>
                        </div>

                        <div>
                            <FontAwesomeIcon icon={faUser} className="column-icon" />
                            <p>{cookies?.language === "en" ? "Name" : "Nume"}</p>
                        </div>

                        <div>
                            <FontAwesomeIcon icon={faDoorOpen} className="column-icon" />
                            <p>{cookies?.language === "en" ? "Grade" : "Clasa"}</p>
                        </div>

                        <div>
                            <FontAwesomeIcon icon={faDoorOpen} className="column-icon" />
                            <p>{cookies?.language === "en" ? "Entered" : "Intrat"}</p>
                        </div>

                        <div>
                            <FontAwesomeIcon icon={faDoorOpen} className="column-icon" />
                            <p>{cookies?.language === "en" ? "Finished" : "Terminat"}</p>
                        </div>


                        <div className="column">
                            <FontAwesomeIcon icon={faCircleCheck} className="column-icon" />
                            <p>{cookies?.language === "en" ? "Correct" : "Corecte"}</p>
                        </div>
                        
                        <div className="column">
                            <FontAwesomeIcon icon={faTriangleExclamation} className="column-icon" />
                            <p>{cookies?.language === "en" ? "Warnings" : "Avertizari"}</p>
                        </div>

                        <div className="column">
                            <FontAwesomeIcon icon={faCircleXmark} className="column-icon" />
                            <p>{cookies?.language === "en" ? "Wrong" : "Gresite"}</p>
                        </div>

                        {/* <div className="column">
                            <FontAwesomeIcon icon={faStar} className="column-icon" />
                            <p>{cookies?.language === "en" ? "Points" : "Puncte"}</p>
                        </div> */}

                    </div>

                    {results && results.length ? results.map((result, idx) => (
                        <div className={`user ${JSON.stringify(result) === JSON.stringify(activeUser || "[]") ? "active" : ""}`} key={idx} onClick={() => result === activeUser ? setActiveUser(null) : setActiveUser(result)}>
                            <div className="column">
                                <p>{idx + 1}</p>
                            </div>

                            <div className="column">
                                <p>{result.name}</p>
                            </div>

                            <div className="column">
                                <p>{result?.grade || (cookies?.language === "en" ? "Undefined" : "Nedefinit")}</p>
                            </div>

                            <div className="column">
                                <p>{result?.entered ? <FontAwesomeIcon icon={faCheck} className="leaderboard-icon" /> : <FontAwesomeIcon icon={faTimes} className="leaderboard-icon" />}</p>
                            </div>

                            <div className="column">
                                <p>{result?.finished ? <FontAwesomeIcon icon={faCheck} className="leaderboard-icon" /> : <FontAwesomeIcon icon={faTimes} className="leaderboard-icon" />}</p>
                            </div>


                            <div className="column">
                                <p>{result?.correct?.length || 0}</p>
                                
                            </div>

                            <div className="column">
                                <p>{result?.warning?.length || 0}</p>
                                
                            </div>

                            <div className="column">
                                <p>{result?.wrong?.length || 0}</p>
                                
                            </div>

                            {/* <div className="column"  >
                                <p>{result.points || 0} / 100</p>
                            </div> */}
                        </div>
                    )) : null}
                </div>
            </div>

          
            {/* 
            {currentResultsTarget?.[0] ? (
                <div className="results-container">
                    <h2>{cookies?.language === "en" ? `Here is what ${currentResultsTarget[0]} did as ${currentResultsTarget[1]} answers: ` : `Aici sunt raspunsurile lui ${currentResultsTarget[0]} de tip ${currentResultsTarget[1] === "correct" ? "corect" : currentResultsTarget[1] === "warning" ? "avertizare" : "gresit"}:`}</h2>
                    <div className="logs">
                        {currentResultsTarget?.[2]?.map((elem : { message: string }, idx: number) => (
                            <li key={idx}>
                               {elem.message}
                            </li>
                        ))}
                    </div>
                </div>
            ) : null} */}

            <div ref={pageDataRef}>
                <h2 className="sub-title" ref={subtitleRef}>{activeUser?.name} 
                    {activeUser?.name ? <span onClick={() => printResult()}> <FontAwesomeIcon icon={faPrint} className="subtitle-icon" /> {cookies?.language === "en" ? "Print results" : "Printeaza rezultatele"}</span> : null}
                </h2>

                {activeUser !== null && problems ? (
                    <div className="user-results">
                        <div className="column">
                            <h2>{cookies?.language === "en" ? `${activeUser?.name}'s manuevers` : `Manevrele lui ${activeUser?.name}`}</h2>
                            {activeUser.logs.map((log : any, idx : number) => (
                                <div className="item" key={idx}>
                                    <FontAwesomeIcon icon={log.type === "wrong" ? faCircleXmark : log.type === "warning" ? faTriangleExclamation : faCircleCheck} className={`item-icon ${log.type}`} />
                                    <p>{idx + 1}) {log.message} - {log.type === "wrong" ? (cookies?.language === "en" ? "Wrong" : "Gresit") : log.type === "warning" ? (cookies?.language === "en" ? "Warning" : "Avertizare") : (cookies?.language === "en" ? "Correct" : "Corect")}</p>
                                </div>
                            ))}
                        </div>

                        <div className="column">
                            <h2>{cookies?.language === "en" ? `The correct order of manuever's execution` : `Ordinea corecta a executarii manevrelor`}</h2>

                            {problems.map((problemArr : any, idx : number) => (
                                <div className="item-container" key={idx}>
                                    {/* <FontAwesomeIcon icon={problem.type === "wrong" ? faCircleXmark : log.type === "warning" ? faTriangleExclamation : faCircleCheck} className="item-icon" /> */}
                                    <h2>{idx + 1}) {problemArr?.[0]?.name?.split("-")?.[1] || ""}</h2>
                                    {problemArr.map((problem: any, problemIdx : number) => (
                                        <div className="item" key={problemIdx}>
                                            <p>
                                                {idx + 1}.{problemIdx + 1}) {problem?.name?.split("-")?.[0].slice(0, 1)?.toUpperCase()}{problem?.name?.split("-")?.[0].slice(1)} {problem?.number !== null ? ` ${problem.number === 1 || problem.number === 2 ? (cookies?.language === "en" ? "of bar type" : "de bara") : problem.number === 3 ? (cookies?.language === "en" ? "of line type" : "de linie") : problem.number === 4 ? (cookies?.language === 'en' ? "of knife tied to the ground type" : "de C.L.P.") : ""}` : ""} {problem?.name?.split("-")?.[1]}
                                                {' '} {activeUser?.problemsSolved?.some((elemArr : any, elemArrIdx : number) => elemArr.some((elem : any) => elem.name === problem?.name && elem?.number === problem?.number && elem.solved)) ? <FontAwesomeIcon icon={faCheck} className="check-icon"/> : ""}
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            ))}

                        </div>
                    </div>
                ) : null}
            </div>

           

            {data.userData.type === "teacher" && !sessionFinished ? (
                <div className="start-session-button" ref={sessionButtonRef} onClick={async () => await finishSessionTeacher()}>
                    <p>{cookies?.language === "en" ? "Finish session" : "Incheie sesiunea"}</p>
                </div>
            ) : null}

           
        </div>
    );
};

export default LeaderboardTestingSession;