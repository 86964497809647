import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCookies } from "react-cookie";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Header from "../../Components/Header/Header";
import SRS from "../SRS/SRS";
import "./OptionsTesting.css";

const OptionsTesting = ({ data, socket } : { data: any, socket: any }) => {

    const [cookies, setCookie] = useCookies();
    const location = useLocation();
    const scenario = location?.state?.scenario;
    const schemeName = location?.state?.schemeName;
    const navigate = useNavigate();
    const { optionParam } = useParams();

    if (!optionParam) navigate(-1);
    if (!scenario) navigate(-1);

    const option = Number(optionParam);

    console.log(schemeName);

    const createSession = async (line : string, currentCell : string) => {

        const totalProblems : Array<Array<Object>> = [];
        
        const cells : Array<string> = [];

        if (currentCell === "*") {
            const lineElements = document.querySelectorAll(`div[normalline="${line}"], div[normalline2="${line}"]`) || [];
            console.log(lineElements);
            lineElements.forEach((element : any) => {
                const currentId = element.getAttribute("id");
                if (!cells.includes(currentId)) {
                    cells.push(currentId);
                }
            });

        } else cells.push(currentCell);

        console.log(cells);

        cells.forEach(cell => {
            const problems = [];

            const intreruptor = document.querySelector(`.intreruptor-${cell}`);
            const lineSeparator = document.querySelector(`.separator-${cell}[number="3"]`);
            const separators : any = document.querySelectorAll(`.separator-${cell}`) || [];
            const barSeparator = [...separators].find(separator => separator.getAttribute("normalline") === separator.getAttribute("line"));
            const clpSeparator = document.querySelector(`.separator-${cell}[number="4"]`);
            const generator = document.querySelector(`.generator-${cell}`);
            
        
            if (intreruptor) problems.push({
                name: `intreruptor-${cell}`,
                number: null
            });

            if (cell.includes("CL")) problems.push({
                number: Number(barSeparator.getAttribute("number")) === 1 ? 2 : 1,
                name: `separator-${cell}`
            });
    
            if (lineSeparator) problems.push({
                name: `separator-${cell}`,
                number: 3
            });
    
            if (barSeparator) problems.push({
                name: `separator-${cell}`,
                number: Number(barSeparator.getAttribute("number"))
            });

            if (generator) problems.push({
                name: `generator-${cell}`,
                number: null
            });
    
            if (clpSeparator && scenario === "shock" && (currentCell !== "*" || cell.includes("CM"))) problems.push({
                name: `separator-${cell}`,
                number: 4
            });
            

            if (problems.length) totalProblems.push(problems);
        });
       

        try {
            const options = {
                method: "POST",
                headers: {
                    "Content-Type" : "application/json"
                },
                body: JSON.stringify({
                    schemeName,
                    semiStation: line,
                    cell: currentCell,
                    scenario,
                    problems: totalProblems,
                    userId: data.userData.userId
                })
            };

            const request = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/session-testing/create-session-testing`, options);
            const response = await request.json();

            if (response?.success && response?.sessionId) navigate(`/session-testing/${response.sessionId}/0`);
            else console.log(response);
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <div className="content options-testing">
            <Header data={data} socket={socket} />
            {option === 1 ? (
                <>
                    <div className="navigate-button" onClick={() => navigate(-1)}>
                        <FontAwesomeIcon icon={faAngleLeft} className="navigate-icon" />
                    </div>
                    <h2 className="title">{cookies?.language === "en" ? "Choose station" : "Alege stația"}</h2>

                    <div className="projects-container high-tension">
                        <div className="project-gradient" onClick={(e : any) => navigate(`/options-testing/2`, { state: { scenario, schemeName: "SRS" } })}>
                            <div className="project learn">
                                <h2>{cookies.language === "en" ? "System Connection Station (SCS)" : "Stație Racord Sistem (SRS)"}</h2>

                                {/* <div className="format">
                                    <FontAwesomeIcon icon={faFileLines} className="format-icon" />
                                    <p>A4</p>
                                </div> */}


                            </div>

                            
                        </div>

                        <div className="project-gradient" onClick={(e : any) => navigate(`/options-testing/2`, { state: { scenario, schemeName: "SRA"  } })}>
                            <div className="project learn">
                                <h2>{cookies.language === "en" ? "Deep Connection Station (DCS)" : "Stație Racord Adânc (SRA)"}</h2>

                                {/* 
                                <div className="format">
                                    <FontAwesomeIcon icon={faFileLines} className="format-icon" />
                                    <p>A4</p>
                                </div> */}


                            </div>
                        </div>
                    </div>
                </>
            ) : schemeName === "SRS" ? (
                <>
                  <div className="navigate-button" onClick={() => navigate(-1)}>
                        <FontAwesomeIcon icon={faAngleLeft} className="navigate-icon" />
                    </div>
                    <h2 className="title">{cookies?.language === "en" ? "Choose line / cell" : "Alege linia / celula"}</h2>

                    <div className="projects-container high-tension options-testing">
                        <div className="project-gradient" onClick={async (e : any) => await createSession("IA", "*")}>
                            <div className="project learn cell-SRS">
                                <h2>{cookies?.language === "en" ? "Line IA" : "Linia IA"}</h2>

                                {/* <div className="format">
                                    <FontAwesomeIcon icon={faFileLines} className="format-icon" />
                                    <p>A4</p>
                                </div> */}


                            </div>

                            
                        </div>

                        <div className="project-gradient" onClick={async (e : any) => await createSession("IB", "*")}>
                            <div className="project learn cell-SRS">
                                <h2>{cookies?.language === "en" ? "Line IB" : "Linia IB"}</h2>

                                {/* 
                                <div className="format">
                                    <FontAwesomeIcon icon={faFileLines} className="format-icon" />
                                    <p>A4</p>
                                </div> */}


                            </div>
                        </div>

                        <div className="project-gradient" onClick={async (e : any) => await createSession("IIA", "*")}>
                            <div className="project learn cell-SRS">
                                <h2>{cookies?.language === "en" ? "Line IIA" : "Linia IIA"}</h2>

                                {/* 
                                <div className="format">
                                    <FontAwesomeIcon icon={faFileLines} className="format-icon" />
                                    <p>A4</p>
                                </div> */}


                            </div>
                        </div>

                        <div className="project-gradient" onClick={async (e : any) => await createSession("IIB", "*")}>
                            <div className="project learn cell-SRS">
                                <h2>{cookies?.language === "en" ? "Line IIB" : "Linia IIB"}</h2>

                                {/* 
                                <div className="format">
                                    <FontAwesomeIcon icon={faFileLines} className="format-icon" />
                                    <p>A4</p>
                                </div> */}


                            </div>
                        </div>

                        <div className="project-gradient" onClick={async (e : any) => await createSession("IIA", "OE1")}>
                            <div className="project learn cell-SRS">
                                <h2>OE1 CEL 4K</h2>

                                {/* 
                                <div className="format">
                                    <FontAwesomeIcon icon={faFileLines} className="format-icon" />
                                    <p>A4</p>
                                </div> */}


                            </div>
                        </div>


                        <div className="project-gradient" onClick={async (e : any) => await createSession("IIB", "OE2")}>
                            <div className="project learn cell-SRS">
                                <h2>OE2 CEL 24K</h2>

                                {/* 
                                <div className="format">
                                    <FontAwesomeIcon icon={faFileLines} className="format-icon" />
                                    <p>A4</p>
                                </div> */}


                            </div>
                        </div>


                        <div className="project-gradient" onClick={async (e : any) => await createSession("IA", "OE3")}>
                            <div className="project learn cell-SRS">
                                <h2>OE3 CEL 8K</h2>

                                {/* 
                                <div className="format">
                                    <FontAwesomeIcon icon={faFileLines} className="format-icon" />
                                    <p>A4</p>
                                </div> */}


                            </div>
                        </div>

                        <div className="project-gradient" onClick={async (e : any) => await createSession("IB", "OE4")}>
                            <div className="project learn cell-SRS">
                                <h2>OE4 CEL 28K</h2>

                                {/* 
                                <div className="format">
                                    <FontAwesomeIcon icon={faFileLines} className="format-icon" />
                                    <p>A4</p>
                                </div> */}


                            </div>
                        </div>

                        <div className="project-gradient" onClick={async (e : any) => await createSession("IA", "GeneratorI")}>
                            <div className="project learn cell-SRS">
                                <h2>Generator I CEL 12K</h2>

                                {/* 
                                <div className="format">
                                    <FontAwesomeIcon icon={faFileLines} className="format-icon" />
                                    <p>A4</p>
                                </div> */}


                            </div>
                        </div>

                        <div className="project-gradient" onClick={async (e : any) => await createSession("IA", "GeneratorII")}>
                            <div className="project learn cell-SRS">
                                <h2>Generator II CEL 12K</h2>

                                {/* 
                                <div className="format">
                                    <FontAwesomeIcon icon={faFileLines} className="format-icon" />
                                    <p>A4</p>
                                </div> */}


                            </div>
                        </div>

                        <div className="project-gradient" onClick={async (e : any) => await createSession("IA", "CMIA")}>
                            <div className="project learn cell-SRS">
                                <h2>C.M. IA CEL 2K</h2>

                                {/* 
                                <div className="format">
                                    <FontAwesomeIcon icon={faFileLines} className="format-icon" />
                                    <p>A4</p>
                                </div> */}


                            </div>
                        </div>

                        <div className="project-gradient" onClick={async (e : any) => await createSession("IB", "CMIB")}>
                            <div className="project learn cell-SRS">
                                <h2>C.M. IB CEL 30K</h2>

                                {/* 
                                <div className="format">
                                    <FontAwesomeIcon icon={faFileLines} className="format-icon" />
                                    <p>A4</p>
                                </div> */}


                            </div>
                        </div>


                        <div className="project-gradient" onClick={async (e : any) => await createSession("IIA", "CMIIA")}>
                            <div className="project learn cell-SRS">
                                <h2>C.M. IIA CEL 2K</h2>

                                {/* 
                                <div className="format">
                                    <FontAwesomeIcon icon={faFileLines} className="format-icon" />
                                    <p>A4</p>
                                </div> */}


                            </div>
                        </div>

                        {/* <div className="project-gradient" onClick={async (e : any) => await createSession("IA", "CLI")}>
                            <div className="project learn cell-SRS">
                                <h2>C.L. IB - IA CEL 14K - 18K</h2>

                                
                                <div className="format">
                                    <FontAwesomeIcon icon={faFileLines} className="format-icon" />
                                    <p>A4</p>
                                </div> 


                            </div>
                        </div> */}

                        <div className="project-gradient" onClick={async (e : any) => await createSession("IIB", "CMIIB")}>
                            <div className="project learn cell-SRS">
                                <h2>C.M. IIB CEL 30K</h2>

                                {/* 
                                <div className="format">
                                    <FontAwesomeIcon icon={faFileLines} className="format-icon" />
                                    <p>A4</p>
                                </div> */}


                            </div>
                        </div>




                        <div className="project-gradient" onClick={async (e : any) => await createSession("IB", "LPGS2")}>
                            <div className="project learn cell-SRS">
                                <h2>LPGS2 CEL 26K</h2>

                                {/* 
                                <div className="format">
                                    <FontAwesomeIcon icon={faFileLines} className="format-icon" />
                                    <p>A4</p>
                                </div> */}


                            </div>
                        </div>
                        
                        {scenario === "fire" ? (
                            <>
                                <div className="project-gradient" onClick={async (e : any) => await createSession("IA", "2T")}>
                                    <div className="project learn cell-SRS">
                                        <h2>Trafo 2T</h2>


                                    </div>
                                </div>

                                <div className="project-gradient" onClick={async (e : any) => await createSession("IB", "3T")}>
                                    <div className="project learn cell-SRS">
                                        <h2>Trafo 3T</h2>


                                    </div>
                                </div>
                            </>
                        ) : null}
                    

                        <SRS data={data} notDisplay={true}/>
                    </div>
                </>
            ) : schemeName === "SRA" ? (
                <div>

                </div>
            ) : null}


        </div>
    );
};

export default OptionsTesting;