import "./SRS.css";
import generatorImage from "../../imgs/generator2.svg";
import statie from "../../imgs/statie.svg";
import { useEffect, useRef } from "react";

const SRS = ({ data, notDisplay }) => {

    const imageRef = useRef();
    const stationContainerRef = useRef();

    useEffect(() => {

        const transformScale = () => {
            if (!stationContainerRef?.current || !imageRef?.current) return;
            const numero1 = (window.innerWidth * window.innerHeight) / (imageRef.current.getBoundingClientRect().width * imageRef.current.getBoundingClientRect().height);
            if (numero1 <= 1) return;
            console.log(numero1);
            // stationContainerRef.current.style.transform = `scale(${numero1.toString()})`;
        };

        transformScale();

        window.addEventListener("resize", transformScale);

        return () => {
            window.removeEventListener("resize", transformScale);
        };

    }, [stationContainerRef, imageRef]);

    return (
        <div ref={stationContainerRef} className="station-container" style={notDisplay ? { display: 'none' } : {}}>
            <img ref={imageRef} src={statie} alt="imagine statie" className="img-content" />
            
            <div className="consumer consumer-4T inactive" id="4T" normalline="IB">
                <div className="circle"></div>
                <div className="circle colored"></div>
            </div>
            <div className="consumer consumer-3T inactive" id="3T" normalline="IB">
                <div className="circle"></div>
                <div className="circle colored"></div>
            </div>
            <div className="consumer consumer-2T inactive" id="2T" normalline="IA">
                <div className="circle"></div>
                <div className="circle colored"></div>
            </div>
            <div className="consumer consumer-OE4 inactive" id="OE4" normalline="IB">
                <div className="circle colored"></div>
                <div className="circle"></div>
            </div>
            <div className="consumer consumer-LPGS2 inactive" id="LPGS2" normalline="IB">
                <div className="circle colored"></div>
                <div className="circle"></div>
            </div>
            <div className="consumer consumer-OE2 inactive" id="OE2" normalline="IIB">
                <div className="circle colored"></div>
                <div className="circle"></div>
            </div>
            <div className="consumer consumer-OE3 inactive" id="OE3" normalline="IA">
                <div className="circle colored"></div>
                <div className="circle"></div>
            </div>
            <div className="consumer consumer-OE1 inactive" id="OE1" normalline="IIA">
                <div className="circle colored"></div>
                <div className="circle"></div>
            </div>

            <div className="intreruptor intreruptor-4T inactive" id="4T" normalline="IB" cell="27K">
                <div className="line"></div>
            </div>

            <div className="intreruptor intreruptor-3T inactive" id="3T" normalline="IB" cell="21K">
                <div className="line"></div>
            </div>
            
            <div className="intreruptor intreruptor-2T inactive" id="2T" normalline="IA" cell="9K">
                <div className="line"></div>
            </div>

    

            <div className="intreruptor intreruptor-OE4 inactive" id="OE4" normalline="IB" cell="28K">
                <div className="line"></div>
            </div>

            <div className="intreruptor intreruptor-LPGS2 inactive" id="LPGS2" normalline="IB" cell="26K">
                <div className="line"></div>
            </div>

            <div className="intreruptor intreruptor-OE2 inactive" id="OE2" normalline="IIB" cell="24K">
                <div className="line"></div>
            </div>

            <div className="intreruptor intreruptor-OE3 inactive" id="OE3" normalline="IA" cell="8K">
                <div className="line"></div>
            </div>

            <div className="intreruptor intreruptor-OE1 inactive" id="OE1" normalline="IIA" cell="4K">
                <div className="line"></div>
            </div>

            <div className="intreruptor intreruptor-CLII inactive" id="CLII" normalline="IIA" normalline2="IIB" cell="13K, 17K"> 
                <div className="line"></div>
            </div>

            <div className="intreruptor intreruptor-CTVB inactive" id="CTVB" cell="22K">
                <div className="line"></div>
            </div>

            <div className="intreruptor intreruptor-GeneratorI inactive" id="GeneratorI" line="B" normalline="IIB" cell="20K">
                <div className="line"></div>
            </div>

            <div className="intreruptor intreruptor-CLI inactive" id="CLI" normalline="IA" normalline2="IB" cell="14K, 18K">
                <div className="line"></div>
            </div>

            <div className="intreruptor intreruptor-GeneratorII inactive" id="GeneratorII" line="A" normalline="IA" cell="12K">
                <div className="line"></div>
            </div>

            <div className="intreruptor intreruptor-CTVA inactive" id="CTVA" cell="10K">
                <div className="line"></div>
            </div>

            {/* <!-- Separatoare --> */}

            <div className="separator separator-4T seperator-1 inactive" id="4T" number="1" line="IB" normalline="IB" cell="27K">
                <div className="line"></div>
            </div>

            <div className="separator separator-4T seperator-2 inactive" id="4T" number="2" line="IIB" normalline="IB" cell="27K">
                <div className="line"></div>
            </div>

            <div className="separator separator-3T seperator-1 inactive" id="3T" number="1" line="IB" normalline="IB" cell="21K">
                <div className="line"></div>
            </div>

            <div className="separator separator-3T seperator-2 inactive" id="3T" number="2" line="IIB" normalline="IB" cell="21K">
                <div className="line"></div>
            </div>

            <div className="separator separator-CMIB inactive" id="CMIB" number="3" normalline="IB" cell="30K">
                <div className="line"></div>
            </div>
            <div className="separator separator-CMIB inactive" id="CMIB" number="4" normalline="IB" cell="30K">
                <div className="line"></div>
            </div>

            <div className="separator separator-CLII inactive" id="CLII" number="1" line="IIB" normalline="IIA" normalline2="IIB" cell="13K, 17K">
                <div className="line"></div>
            </div>
            <div className="separator separator-CLII inactive" id="CLII" number="2" line="IIA" normalline="IIA" normalline2="IIB" cell="13K, 17K">
                <div className="line"></div>
            </div>
            
            <div className="separator separator-CMIA inactive" id="CMIA" number="3" normalline="IA" cell="2K">
                <div className="line"></div>
            </div>

            <div className="separator separator-CMIA inactive" id="CMIA" number="4" normalline="IA" cell="2K">
                <div className="line"></div>
            </div>

            <div className="separator separator-2T inactive" id="2T" number="1" line="IA" normalline="IA" cell="9K">
                <div className="line"></div>
            </div>

            <div className="separator separator-2T inactive" id="2T" number="2" line="IIA" normalline="IA" cell="9K">
                <div className="line"></div>
            </div>

        

            <div className="separator separator-OE4 inactive" id="OE4" number="1" line="IB" normalline="IB" cell="28K">
                <div className="line"></div>
            </div>

            <div className="separator separator-OE4 inactive" id="OE4" number="2" line="IIB" normalline="IB" cell="28K">
                <div className="line"></div>
            </div>

            <div className="separator separator-OE4 inactive" id="OE4" number="3" normalline="IB" cell="28K">
                <div className="line"></div>
            </div>
            <div className="separator separator-OE4 inactive" id="OE4" number="4" normalline="IB" cell="28K">
                <div className="line"></div>
            </div>

            <div className="separator separator-LPGS2 inactive" id="LPGS2" number="1" line="IB" normalline="IB" cell="26K">
                <div className="line"></div>
            </div>
            <div className="separator separator-LPGS2 inactive" id="LPGS2" number="2" line="IIB" normalline="IB" cell="26K">
                <div className="line"></div>
            </div>
            <div className="separator separator-LPGS2 inactive" id="LPGS2" number="3" normalline="IB" cell="26K">
                <div className="line"></div>
            </div>
            <div className="separator separator-LPGS2 inactive" id="LPGS2" number="4" normalline="IB" cell="26K">
                <div className="line"></div>
            </div>

            <div className="separator separator-OE2 inactive" id="OE2" number="1" line="IB" normalline="IIB" cell="24K">
                <div className="line"></div>
            </div>

            <div className="separator separator-OE2 inactive" id="OE2" number="2" line="IIB" normalline="IIB" cell="24K">
                <div className="line"></div>
            </div>

            <div className="separator separator-OE2 inactive" id="OE2" number="3" normalline="IIB" cell="24K">
                <div className="line"></div>
            </div>

            <div className="separator separator-OE2 inactive" id="OE2" number="4" normalline="IIB" cell="24K">
                <div className="line"></div>
            </div>

            <div className="separator separator-CTVB inactive" id="CTVB" number="1" line="IB" cell="22K">
                <div className="line"></div>
            </div>

            <div className="separator separator-CTVB inactive" id="CTVB" number="2" line="IIB" cell="22K">
                <div className="line"></div>
            </div>

            <div className="separator separator-GeneratorI inactive" id="GeneratorI" number="1" line="IB" normalline="IIB" cell="20K">
                <div className="line"></div>
            </div>

            <div className="separator separator-GeneratorI inactive" id="GeneratorI" number="2" line="IIB" normalline="IIB" cell="20K">
                <div className="line"></div>
            </div>

            <div className="separator separator-GeneratorI inactive" id="GeneratorI" number="3" normalline="IIB" cell="20K">
                <div className="line"></div>
            </div>

            <div className="separator separator-GeneratorI inactive" id="GeneratorI" number="4" normalline="IIB" cell="20K">
                <div className="line"></div>
            </div>

            <div className="separator separator-CMIIB inactive" id="CMIIB" number="3" normalline="IIB" cell="30K">
                <div className="line"></div>
            </div>

            <div className="separator separator-CMIIB inactive" id="CMIIB" number="4" normalline="IIB" cell="30K"> 
                <div className="line"></div>
            </div>
            
            <div className="separator separator-CLI inactive" id="CLI" number="1" line="IB" normalline="IA" normalline2="IB" cell="14K, 18K">
                <div className="line"></div>
            </div>

            <div className="separator separator-CLI inactive" id="CLI" number="2" line="IA" normalline="IA" normalline2="IB" cell="14K, 18K">
                <div className="line"></div>
            </div>

            <div className="separator separator-CMIIA inactive" id="CMIIA" number="3" normalline="IIA" cell="2K">
                <div className="line"></div>
            </div>
    
            <div className="separator separator-CMIIA inactive" id="CMIIA" number="4" normalline="IIA" cell="2K">
                <div className="line"></div>
            </div>

            <div className="separator separator-GeneratorII inactive" id="GeneratorII" number="1" line="IA" normalline="IA" cell="12K">
                <div className="line"></div>
            </div>

            <div className="separator separator-GeneratorII inactive" id="GeneratorII" number="2" line="IIA" normalline="IA" cell="12K">
                <div className="line"></div>
            </div>

            <div className="separator separator-GeneratorII inactive" id="GeneratorII" number="3" normalline="IA" cell="12K">
                <div className="line"></div>
            </div>

            <div className="separator separator-GeneratorII inactive" id="GeneratorII" number="4" normalline="IA" cell="12K">
                <div className="line"></div>
            </div>

            <div className="separator separator-CTVA inactive" id="CTVA" number="1" line="IA" cell="10K">
                <div className="line"></div>
            </div>

            <div className="separator separator-CTVA inactive" id="CTVA" number="2" line="IIA" cell="10K">
                <div className="line"></div>
            </div>

            <div className="separator separator-OE3 inactive" id="OE3" number="1" line="IA" normalline="IA" cell="8K">
                <div className="line"></div>
            </div>

            <div className="separator separator-OE3 inactive" id="OE3" number="2" line="IIA" normalline="IA" cell="8K">
                <div className="line"></div>
            </div>

            <div className="separator separator-OE3 inactive" id="OE3" number="3" normalline="IA" cell="8K">
                <div className="line"></div>
            </div>

            <div className="separator separator-OE3 inactive" id="OE3" number="4" normalline="IA" cell="8K">
                <div className="line"></div>
            </div>

            <div className="separator separator-OE1 inactive" id="OE1" number="1" line="IA" normalline="IIA" cell="4K">
                <div className="line"></div>
            </div>

            <div className="separator separator-OE1 inactive" id="OE1" number="2" line="IIA" normalline="IIA" cell="4K">
                <div className="line"></div>
            </div>
            
            <div className="separator separator-OE1 inactive" id="OE1" number="4" normalline="IIA" cell="4K">
                <div className="line"></div>
            </div>

            <div className="separator separator-OE1 inactive" id="OE1" number="3" normalline="IIA" cell="4K">
                <div className="line"></div>
            </div>

            <div className="generator generator-GeneratorI inactive" id="GeneratorI" line="B" normalline="IIB" cell="20K">
                <img src={generatorImage} alt="generator" />
            </div>

            <div className="cell cell-CMIIA inactive" id="CMIIA" normalline="IIA">

                <div className="circle text-circle"></div>
                <div className="circle"></div>
                <div className="circle"></div>

            </div>

            <div className="cell cell-CMIIB inactive" id="CMIIB" normalline="IIB">
                
                <div className="circle text-circle"></div>
                <div className="circle"></div>
                <div className="circle"></div>

            </div>

            <div className="cell cell-CMIA inactive down-text" id="CMIA" normalline="IA"> 
            
                <div className="circle"></div>
                <div className="circle"></div>
                <div className="circle text-circle"></div>

            </div>

            <div className="cell cell-CMIB inactive down-text" id="CMIB" normalline="IB">
        
                <div className="circle"></div>
                <div className="circle"></div>
                <div className="circle text-circle"></div>

            </div>

            <div className="generator generator-GeneratorII inactive" id="GeneratorII" line="A" normalline="IA" cell="12K">
                <img src={generatorImage} alt="generator" />
            </div>

            <div className="message-content">
                <div className="message-container">
                    <p></p>
                    <div className="buttons">
                        <button type="button" className="positive-btn inactive">Da</button>
                        <button type="button" className="negative-btn inactive">Nu</button>

                        <button type="button" className="confirm-btn">Ok</button>
                    </div>
                
                </div>
            </div>
        </div>
    );
};

export default SRS;