import { faBolt, faBuilding, faBuildingUser, faChalkboardUser, faEnvelope, faLock, faSchool, faUser, faUserGraduate } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import "./Login.css";

const Login = () => {

  const [cookies, setCookie] = useCookies();
  const [error, setError] = useState<string>("");
  const navigate = useNavigate();

  const [inputs, setInputs] = useState({
      username: "",
      firstName: "",
      lastName: "",
      email: "",
      school: "",
      type: "teacher",
      password: "",
      department: "",
      
    });

    // useEffect(() => {
    //     const sign_in_btn : any = document.querySelector("#sign-in-btn");
    //     const sign_up_btn : any = document.querySelector("#sign-up-btn");
    //     const container : any = document.querySelector(".container");
    
    //     sign_up_btn.addEventListener("click", () => {
    //         resetStates();
    //         container.classList.add("sign-up-mode");
    //     });
    
    //     sign_in_btn.addEventListener("click", () => {
    //         resetStates();
    //         container.classList.remove("sign-up-mode");
    //     });
    // }, []);

    const resetStates = () => {
      setInputs({
        username: "",
        firstName: "",
        lastName: "",
        email: "",
        school: "",
        type: "teacher",
        password: "",
        department: ""
      });
    };

    const login = async (e : MouseEvent) => {
      e.preventDefault();
      try {
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            username: inputs.username,
            password: inputs.password
          })
        };
        console.log(process.env.REACT_APP_BACKEND_URL);

        const request = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/auth/login`, options);
        const response = await request.json();
        if (response.success) {
          setCookie("user-id", response.userData.userId);
          setCookie("pwd", response.userData.password);
          window.history.replaceState({
            page: "categories"
          }, "");
          window.location.replace("/categories");
        } else {
          console.log(response);
          setError(response.message.ro);
        }
      } catch (e) {
        console.log(e);
      }
    };

    

    const handleInputChange = (e : React.ChangeEvent<HTMLInputElement>) => {
      setInputs(currInputs => {
        return {
          ...currInputs,
          [e.target.name]: e.target.value
        };
      });
    };
    
    return (
        <div className="container">
          <div className="forms-container">
            <div className="signin-signup">
              <form action="#" className="sign-in-form">
                <h2 className="title">Intră în cont</h2>
                <div className="input-field">
                  <FontAwesomeIcon icon={faUser} className="input-icon" />
                  <input type="text" name="username" value={inputs.username} onChange={handleInputChange} placeholder="Nume de utilizator" />
                </div>
                <div className="input-field">
                  <FontAwesomeIcon icon={faLock} className="input-icon"/>
                  <input type="password" name="password"  value={inputs.password} onChange={handleInputChange} placeholder="Parola" />
                </div>
                <input type="submit" value="Login" className="btn solid" onClick={async (e : any) => await login(e)} />
                {error.length ? <p className="error">{error}</p> : null}

              </form>
           
            </div>
          </div>

      <div className="panels-container">
        <div className="panel left-panel">
          <div className="content">
            {/* <h3>Ești nou aici?</h3>
            <p>
              Descoperă ușurință de a crea scheme electrice cu ElectricSchool, unde posibilitățile sunt limitate doar de imaginația ta.
            </p>
            <button className="btn transparent" id="sign-up-btn">
              Sign up
            </button> */}
            {/* <FontAwesomeIcon icon={faBolt} className="bolt-icon" /> */}
            {/* <img src={ZenLogo} className="logo" style={{ fill: "#fff" }} /> */}
          </div>
          {/* <img src="img/log.svg" className="image" alt="" /> */}
        </div>
        <div className="panel right-panel">
          <div className="content">
            <h3>Ai cont?</h3>
            <p>
              Redescoperă ușurință de a crea scheme electrice cu ElectricSchool, unde posibilitățile sunt limitate doar de imaginația ta.
            </p>
            <button className="btn transparent" id="sign-in-btn">
              Log in
            </button>

            
          </div>
          {/* <img src="img/register.svg" className="image" alt="" /> */}
        </div>
      </div>
    </div>
    );
};

export default Login;