import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCookies } from "react-cookie";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Header from "../../Components/Header/Header";
import SRS from "../SRS/SRS";
import "../OptionsTesting/OptionsTesting.css";
import { useMemo } from "react";

const OptionsLearning = ({ data, socket } : { data: any, socket: any }) => {

    const [cookies, setCookie] = useCookies();
    const location = useLocation();
    const scenario = location?.state?.scenario;
    const schemeName : string = location?.state?.schemeName;
    const dispatcher : string | null = location?.state?.dispatcher;
    const navigate = useNavigate();
    const { optionParam } = useParams();

    if (!optionParam) navigate(-1);
    if (!scenario) navigate(-1);

    const option = Number(optionParam);

    console.log(schemeName);

    const scenarios : Array<{ line: string, cell: string, scenario: string, station: string, finished: boolean, cellNumber: string }> = useMemo(() => [], []);

    const updateScenarios = (line: string, currentCell: string, cellNumber: string) => {

        scenarios.push({
            line: line,
            cell: currentCell,
            station: schemeName,
            scenario: scenario === "closing-cell" ? "opening-cell" : "closing-cell",
            finished: false,
            cellNumber: cellNumber
        }); 
        
        // if (scenario === "disconnected" || scenario === "warm-condition" || scenario === "warm-reserve") scenarios.push({
        //     line: line,
        //     cell: currentCell,
        //     station: schemeName,
        //     scenario,
        //     finished: false
        // });

        if (scenario === "ssv") {

            scenarios.push({
                line: line,
                cell: currentCell,
                station: schemeName,
                scenario: "disconnected",
                finished: false,
                cellNumber: cellNumber
            });

        } else if (scenario === "cold-condition") {

            scenarios.push({
                line: line,
                cell: currentCell,
                station: schemeName,
                scenario: "warm-condition",
                finished: false,
                cellNumber: cellNumber
            });

        } else if (scenario === "cold-reserve") {
            scenarios.push({
                line: line,
                cell: currentCell,
                station: schemeName,
                scenario: "warm-reserve",
                finished: false,
                cellNumber: cellNumber
            });
        } else if (scenario === "slp") {

            scenarios.push({
                line: line,
                cell: currentCell,
                station: schemeName,
                scenario: "disconnected",
                finished: false,
                cellNumber: cellNumber
            });

            scenarios.push({
                line: line,
                cell: currentCell,
                station: schemeName,
                scenario: "ssv",
                finished: false,
                cellNumber: cellNumber
            });

        } else if (scenario === "closing-cell") {

            scenarios.push({
                line: line,
                cell: currentCell,
                station: schemeName,
                scenario: "disconnected",
                finished: false,
                cellNumber: cellNumber
            });

        }

        // Not allowing SLP state for CL's
        if (scenario !== "slp" || !currentCell.includes("CL")) scenarios.push({
            line: line,
            cell: currentCell,
            station: schemeName,
            scenario: scenario,
            finished: false,
            cellNumber: cellNumber
        }); 

        // Returning to initial state

        if (scenario === "ssv") {
            scenarios.push({
                line: line,
                cell: currentCell,
                station: schemeName,
                scenario: "disconnected",
                finished: false,
                cellNumber: cellNumber
            });
        } else if (scenario === "cold-condition") {
            scenarios.push({
                line: line,
                cell: currentCell,
                station: schemeName,
                scenario: "warm-condition",
                finished: false,
                cellNumber: cellNumber
            });
        } else if (scenario === "cold-reserve") {
            scenarios.push({
                line: line,
                cell: currentCell,
                station: schemeName,
                scenario: "warm-reserve",
                finished: false,
                cellNumber: cellNumber
            });
        } else if (scenario === "slp") {

            scenarios.push({
                line: line,
                cell: currentCell,
                station: schemeName,
                scenario: "ssv",
                finished: false,
                cellNumber: cellNumber
            });

            scenarios.push({
                line: line,
                cell: currentCell,
                station: schemeName,
                scenario: "disconnected",
                finished: false,
                cellNumber: cellNumber
            });

        } else if (scenario === "closing-cell") {

            scenarios.push({
                line: line,
                cell: currentCell,
                station: schemeName,
                scenario: "disconnected",
                finished: false,
                cellNumber: cellNumber
            });

        }

        scenarios.push({
            line: line,
            cell: currentCell,
            station: schemeName,
            scenario: scenario === "closing-cell" ? "opening-cell" : "closing-cell",
            finished: false,
            cellNumber: cellNumber
        }); 
        
    };

    const createSession = async (line : string, currentCell : string) => {       

        const totalProblems : Array<Array<Object>> = [];
        
        const cells : Array<string> = [];

        if (currentCell === "*") {
            const lineElements = document.querySelectorAll(`div[normalline="${line}"], div[normalline2="${line}"]`) || [];
            console.log(lineElements);
            lineElements.forEach((element : any) => {
                const currentId = element.getAttribute("id");
                if (!cells.includes(currentId)) {
                    cells.push(currentId);
                }
            });

        } else {
            cells.push(currentCell);
         
        }


        cells.forEach(cell => {
            const problems = [];

            const intreruptor = document.querySelector(`.intreruptor-${cell}`);
            const lineSeparator = document.querySelector(`.separator-${cell}[number="3"]`);
            const separators : any = document.querySelectorAll(`.separator-${cell}`) || [];
            const barSeparator = [...separators].find(separator => separator.getAttribute("normalline") === separator.getAttribute("line"));
            const clpSeparator = document.querySelector(`.separator-${cell}[number="4"]`);
            const generator = document.querySelector(`.generator-${cell}`);
            
            const cellNumber = intreruptor?.getAttribute("cell") || barSeparator?.getAttribute("cell") || lineSeparator?.getAttribute("cell");

            
            if (scenario !== "closing-cell") {
                if (intreruptor) problems.push({
                    name: `intreruptor-${cell}`,
                    number: null,
                    cell: cellNumber
                });

                if (generator && scenario !== "disconnected" && scenario !== "warm-condition" && scenario !== "warm-reserve") problems.push({
                    name: `generator-${cell}`,
                    number: null,
                    cell: cellNumber
                });

                if (cell.includes("CL") && scenario !== "disconnected" && scenario !== "warm-condition" && scenario !== "warm-reserve") problems.push({
                    number: Number(barSeparator.getAttribute("number")) === 1 ? 2 : 1,
                    name: `separator-${cell}`,
                    cell: cellNumber
                });

                if (lineSeparator && scenario !== "disconnected" && scenario !== "warm-condition" && scenario !== "warm-reserve") problems.push({
                    name: `separator-${cell}`,
                    number: 3,
                    cell: cellNumber
                });

                if (barSeparator && scenario !== "disconnected" && scenario !== "warm-condition" && scenario !== "warm-reserve") problems.push({
                    name: `separator-${cell}`,
                    number: Number(barSeparator.getAttribute("number")),
                    cell: cellNumber
                });

                if (clpSeparator && scenario === "slp" && (currentCell !== "*" || cell.includes("CM"))) problems.push({
                    name: `separator-${cell}`,
                    number: 4,
                    cell: cellNumber
                });

                if (clpSeparator && scenario === "slp" && (currentCell !== "*" || cell.includes("CM"))) problems.push({
                    name: `separator-${cell}`,
                    number: 4,
                    cell: cellNumber
                });

                if (generator && scenario !== "disconnected" && scenario !== "warm-condition" && scenario !== "warm-reserve") problems.push({
                    name: `generator-${cell}`,
                    number: null,
                    cell: cellNumber
                });

                if (barSeparator && scenario !== "disconnected" && scenario !== "warm-condition" && scenario !== "warm-reserve") problems.push({
                    name: `separator-${cell}`,
                    number: Number(barSeparator.getAttribute("number")),
                    cell: cellNumber
                });

                if (lineSeparator && scenario !== "disconnected" && scenario !== "warm-condition" && scenario !== "warm-reserve") problems.push({
                    name: `separator-${cell}`,
                    number: 3,
                    cell: cellNumber
                });
                
                if (cell.includes("CL") && scenario !== "disconnected" && scenario !== "warm-condition" && scenario !== "warm-reserve") problems.push({
                    number: Number(barSeparator.getAttribute("number")) === 1 ? 2 : 1,
                    name: `separator-${cell}`,
                    cell: cellNumber
                });
                
                if (intreruptor) problems.push({
                    name: `intreruptor-${cell}`,
                    number: null,
                    cell: cellNumber
                });

            } else {
                if (generator && scenario !== "disconnected" && scenario !== "warm-condition" && scenario !== "warm-reserve") problems.push({
                    name: `generator-${cell}`,
                    number: null,
                    cell: cellNumber

                });

                if (barSeparator && scenario !== "disconnected" && scenario !== "warm-condition" && scenario !== "warm-reserve") problems.push({
                    name: `separator-${cell}`,
                    number: Number(barSeparator.getAttribute("number")),
                    cell: cellNumber
                });

                if (lineSeparator && scenario !== "disconnected" && scenario !== "warm-condition" && scenario !== "warm-reserve") problems.push({
                    name: `separator-${cell}`,
                    number: 3,
                    cell: cellNumber
                });
                
                if (cell.includes("CL") && scenario !== "disconnected" && scenario !== "warm-condition" && scenario !== "warm-reserve") problems.push({
                    number: Number(barSeparator.getAttribute("number")) === 1 ? 2 : 1,
                    name: `separator-${cell}`,
                    cell: cellNumber
                });
                
                if (intreruptor) problems.push({
                    name: `intreruptor-${cell}`,
                    number: null,
                    cell: cellNumber

                });

                if (intreruptor) problems.push({
                    name: `intreruptor-${cell}`,
                    number: null,
                    cell: cellNumber
                });

                if (generator && scenario !== "disconnected" && scenario !== "warm-condition" && scenario !== "warm-reserve") problems.push({
                    name: `generator-${cell}`,
                    number: null,
                    cell: cellNumber
                });

                if (cell.includes("CL") && scenario !== "disconnected" && scenario !== "warm-condition" && scenario !== "warm-reserve") problems.push({
                    number: Number(barSeparator.getAttribute("number")) === 1 ? 2 : 1,
                    name: `separator-${cell}`,
                    cell: cellNumber
                });

                if (lineSeparator && scenario !== "disconnected" && scenario !== "warm-condition" && scenario !== "warm-reserve") problems.push({
                    name: `separator-${cell}`,
                    number: 3,
                    cell: cellNumber
                });

                if (barSeparator && scenario !== "disconnected" && scenario !== "warm-condition" && scenario !== "warm-reserve") problems.push({
                    name: `separator-${cell}`,
                    number: Number(barSeparator.getAttribute("number")),
                    cell: cellNumber
                });
            }
    

            if (problems.length) totalProblems.push(problems);

            updateScenarios(line, cell, cellNumber);
        });
       

        try {
            const options = {
                method: "POST",
                headers: {
                    "Content-Type" : "application/json"
                },
                body: JSON.stringify({
                    schemeName,
                    semiStation: line,
                    cell: currentCell,
                    scenario,
                    problems: totalProblems,
                    userId: data.userData.userId,
                    timeLimit: currentCell === "*" ? 45 * 60 : 15 * 60,
                    scenarios: scenarios,
                    dispatcher: dispatcher
                })
            };

            const request = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/session-learning/create-session-${data.userData.type}`, options);
            const response = await request.json();

            if (response?.success && response?.sessionId) navigate(`/session-learning/${response.sessionId}/0`);
            else console.log(response);
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <div className="content options-testing project-content">
            <Header data={data} socket={socket} />

            <div className="go-back" onClick={() => navigate(-1)}>
                <FontAwesomeIcon icon={faAngleLeft} className="back-icon" />
            </div>

            {option === 1 ? (
                <>
                    {/* <div className="navigate-button" onClick={() => navigate(-1)}>
                        <FontAwesomeIcon icon={faAngleLeft} className="navigate-icon" />
                    </div> */}
                    <h2 className="title">{cookies?.language === "en" ? "Choose station" : "Alege stația"}</h2>

                    <div className="projects-container high-tension">
                        <div className="project-gradient" onClick={(e : any) => navigate(`/options-learning/2`, { state: { scenario, schemeName: "SRS" } })}>
                            <div className="project learn">
                                <h2>{cookies.language === "en" ? "System Connection Station (SCS)" : "Stație Racord Sistem (SRS)"}</h2>

                                {/* <div className="format">
                                    <FontAwesomeIcon icon={faFileLines} className="format-icon" />
                                    <p>A4</p>
                                </div> */}


                            </div>

                            
                        </div>

                        <div className="project-gradient" onClick={(e : any) => navigate(`/options-learning/2`, { state: { scenario, schemeName: "SRA"  } })}>
                            <div className="project learn">
                                <h2>{cookies.language === "en" ? "Deep Connection Station (DCS)" : "Stație Racord Adânc (SRA)"}</h2>

                                {/* 
                                <div className="format">
                                    <FontAwesomeIcon icon={faFileLines} className="format-icon" />
                                    <p>A4</p>
                                </div> */}


                            </div>
                        </div>
                    </div>
                </>
            ) : option === 3 && schemeName === "SRS" ? (
                <>
                  {/* <div className="navigate-button" onClick={() => navigate(-1)}>
                        <FontAwesomeIcon icon={faAngleLeft} className="navigate-icon" />
                    </div> */}
                    <h2 className="title">{cookies?.language === "en" ? "Choose line / cell" : "Alege linia / celula"}</h2>

                    <div className="projects-container high-tension options-testing">
                      
                        <div className="project-gradient" onClick={async (e : any) => await createSession("IA", "*")}>
                            <div className="project learn cell-SRS">
                                <h2>{cookies?.language === "en" ? "Line IA" : "Linia IA"}</h2>

                                {/* <div className="format">
                                    <FontAwesomeIcon icon={faFileLines} className="format-icon" />
                                    <p>A4</p>
                                </div> */}


                            </div>

                            
                        </div>

                        <div className="project-gradient" onClick={async (e : any) => await createSession("IB", "*")}>
                            <div className="project learn cell-SRS">
                                <h2>{cookies?.language === "en" ? "Line IB" : "Linia IB"}</h2>

                                {/* 
                                <div className="format">
                                    <FontAwesomeIcon icon={faFileLines} className="format-icon" />
                                    <p>A4</p>
                                </div> */}


                            </div>
                        </div>

                        <div className="project-gradient" onClick={async (e : any) => await createSession("IIA", "*")}>
                            <div className="project learn cell-SRS">
                                <h2>{cookies?.language === "en" ? "Line IIA" : "Linia IIA"}</h2>

                                {/* 
                                <div className="format">
                                    <FontAwesomeIcon icon={faFileLines} className="format-icon" />
                                    <p>A4</p>
                                </div> */}


                            </div>
                        </div>

                        <div className="project-gradient" onClick={async (e : any) => await createSession("IIB", "*")}>
                            <div className="project learn cell-SRS">
                                <h2>{cookies?.language === "en" ? "Line IIB" : "Linia IIB"}</h2>

                                {/* 
                                <div className="format">
                                    <FontAwesomeIcon icon={faFileLines} className="format-icon" />
                                    <p>A4</p>
                                </div> */}


                            </div>
                        </div>

                        <div className="project-gradient" onClick={async (e : any) => await createSession("IIA", "OE1")}>
                            <div className="project learn cell-SRS">
                                <h2>OE1 CEL 4K</h2>

                                {/* 
                                <div className="format">
                                    <FontAwesomeIcon icon={faFileLines} className="format-icon" />
                                    <p>A4</p>
                                </div> */}


                            </div>
                        </div>


                        <div className="project-gradient" onClick={async (e : any) => await createSession("IIB", "OE2")}>
                            <div className="project learn cell-SRS">
                                <h2>OE2 CEL 24K</h2>

                                {/* 
                                <div className="format">
                                    <FontAwesomeIcon icon={faFileLines} className="format-icon" />
                                    <p>A4</p>
                                </div> */}


                            </div>
                        </div>


                        <div className="project-gradient" onClick={async (e : any) => await createSession("IA", "OE3")}>
                            <div className="project learn cell-SRS">
                                <h2>OE3 CEL 8K</h2>

                                {/* 
                                <div className="format">
                                    <FontAwesomeIcon icon={faFileLines} className="format-icon" />
                                    <p>A4</p>
                                </div> */}


                            </div>
                        </div>

                        <div className="project-gradient" onClick={async (e : any) => await createSession("IB", "OE4")}>
                            <div className="project learn cell-SRS">
                                <h2>OE4 CEL 28K</h2>

                                {/* 
                                <div className="format">
                                    <FontAwesomeIcon icon={faFileLines} className="format-icon" />
                                    <p>A4</p>
                                </div> */}


                            </div>
                        </div>

                        <div className="project-gradient" onClick={async (e : any) => await createSession("IA", "GeneratorI")}>
                            <div className="project learn cell-SRS">
                                <h2>Generator I CEL 12K</h2>

                                {/* 
                                <div className="format">
                                    <FontAwesomeIcon icon={faFileLines} className="format-icon" />
                                    <p>A4</p>
                                </div> */}


                            </div>
                        </div>

                        <div className="project-gradient" onClick={async (e : any) => await createSession("IA", "GeneratorII")}>
                            <div className="project learn cell-SRS">
                                <h2>Generator II CEL 12K</h2>

                                {/* 
                                <div className="format">
                                    <FontAwesomeIcon icon={faFileLines} className="format-icon" />
                                    <p>A4</p>
                                </div> */}


                            </div>
                        </div>

                        <div className="project-gradient" onClick={async (e : any) => await createSession("IA", "CMIA")}>
                            <div className="project learn cell-SRS">
                                <h2>C.M. IA CEL 2K</h2>

                                {/* 
                                <div className="format">
                                    <FontAwesomeIcon icon={faFileLines} className="format-icon" />
                                    <p>A4</p>
                                </div> */}


                            </div>
                        </div>

                        <div className="project-gradient" onClick={async (e : any) => await createSession("IB", "CMIB")}>
                            <div className="project learn cell-SRS">
                                <h2>C.M. IB CEL 30K</h2>

                                {/* 
                                <div className="format">
                                    <FontAwesomeIcon icon={faFileLines} className="format-icon" />
                                    <p>A4</p>
                                </div> */}


                            </div>
                        </div>


                        <div className="project-gradient" onClick={async (e : any) => await createSession("IIA", "CMIIA")}>
                            <div className="project learn cell-SRS">
                                <h2>C.M. IIA CEL 2K</h2>

                                {/* 
                                <div className="format">
                                    <FontAwesomeIcon icon={faFileLines} className="format-icon" />
                                    <p>A4</p>
                                </div> */}


                            </div>
                        </div>

                        {/* <div className="project-gradient" onClick={async (e : any) => await createSession("IA", "CLI")}>
                            <div className="project learn cell-SRS">
                                <h2>C.L. IB - IA CEL 14K - 18K</h2>

                                
                                <div className="format">
                                    <FontAwesomeIcon icon={faFileLines} className="format-icon" />
                                    <p>A4</p>
                                </div> 


                            </div>
                        </div> */}

                        <div className="project-gradient" onClick={async (e : any) => await createSession("IIB", "CMIIB")}>
                            <div className="project learn cell-SRS">
                                <h2>C.M. IIB CEL 30K</h2>

                                {/* 
                                <div className="format">
                                    <FontAwesomeIcon icon={faFileLines} className="format-icon" />
                                    <p>A4</p>
                                </div> */}


                            </div>
                        </div>




                        <div className="project-gradient" onClick={async (e : any) => await createSession("IB", "LPGS2")}>
                            <div className="project learn cell-SRS">
                                <h2>LPGS2 CEL 26K</h2>

                                {/* 
                                <div className="format">
                                    <FontAwesomeIcon icon={faFileLines} className="format-icon" />
                                    <p>A4</p>
                                </div> */}


                            </div>
                        </div>
                        
                        {scenario === "fire" ? (
                            <>
                                <div className="project-gradient" onClick={async (e : any) => await createSession("IA", "2T")}>
                                    <div className="project learn cell-SRS">
                                        <h2>Trafo 2T</h2>


                                    </div>
                                </div>

                                <div className="project-gradient" onClick={async (e : any) => await createSession("IB", "3T")}>
                                    <div className="project learn cell-SRS">
                                        <h2>Trafo 3T</h2>


                                    </div>
                                </div>
                            </>
                        ) : null}

                        {schemeName.toLowerCase() === "srs" ? <SRS data={data} notDisplay={true}/> : null}
                    </div>
                </>
            ) : option === 3 && schemeName === "SRA" ? (
                <div>

                </div>
            ) : option === 2 ? (
                <>
                    <h2 className="title">{cookies?.language === "en" ? "With Dispatcher?" : "Cu Dispecer?"}</h2>
                    <div className="projects-container high-tension">

                        <div className="project-gradient" onClick={async (e : any) => navigate(`/options-learning/3`, { state: { scenario, schemeName: schemeName, dispatcher: true } })}>
                            <div className="project learn dispatcher">
                                <h2>{cookies?.language === "en" ? "YES" : "DA"}</h2>


                            </div>
                        </div>

                        <div className="project-gradient" onClick={async (e : any) => navigate(`/options-learning/3`, { state: { scenario, schemeName: schemeName, dispatcher: false } })}>
                            <div className="project learn dispatcher">
                                <h2>{cookies?.language === "en" ? "NO" : "NU"}</h2>


                            </div>
                        </div>
                    </div>
                </>
            ) : null}


        </div>
    );
};

export default OptionsLearning;