import { faBuilding, faUser, faCalendar, faIdCard } from "@fortawesome/free-regular-svg-icons";
import { faFileLines, faUserPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ChangeEvent, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useLocation, useNavigate } from "react-router-dom";
import "../CreateProject/CreateProject.css";

const EditProject = ({ data } : { data: any }) => {

    const navigate = useNavigate();
    const [cookies, setCookie] = useCookies();
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const location = useLocation();
    const project = location.state?.project;
    if (!project) navigate(-1);

    const [inputs, setInputs] = useState<{ projectName: string, type: string, designer: string, beneficiary: string, designerCompany: string, verifierI: string, verifierII: string, verifierIII: string, date: string, id: number }>({
        projectName: project?.projectName || "",
        type: project?.format || "A4",
        designer: project?.designer || "",
        beneficiary: project?.beneficiary || "",
        designerCompany: project?.designerCompany || "",
        verifierI: project?.verifierI || "",
        verifierII: project?.verifierII || "",
        verifierIII: project?.verifierIII || "",
        date: project?.date || "",
        id: project?.id || (data.userData.projects.length ? ((data.userData.projects.reduce((prev : any , curr : any) => prev?.id > curr?.id ? prev?.id : curr?.id, -Infinity) + 1) || 1) : 1)
    });

    const handleInputChange = (e : React.ChangeEvent<HTMLInputElement>) => {
        setInputs(currInputs => {
            return {...currInputs,
                [e.target.name]: e.target.value
            };
        });
    };

    const handleCreateProject = async (e : MouseEvent) => {
        if (inputs.projectName.length < 3 || inputs.beneficiary === "" || inputs.designerCompany === "" || inputs.verifierI === "" || inputs.verifierII === "" || inputs.designer === "" || inputs.date === "")
          return setErrorMessage(cookies?.language === "en" ? "Fill every required field!" : "Completeaza toate campurile obligatorii!");


        e.preventDefault();
        const options = {
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({...inputs,
                format: inputs.type,
                userId: data.userData.userId,
                projectId: project.projectId
            })
        };

        try {
            const request = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/project/update-project-settings`, options);
            const response = await request.json();
            console.log(response);
            if (response.success) {
                window.location.assign("/low-tension/edit");
            }
        } catch (e) {
            console.log(e);
        }
    };

   
    return (
        <div className="container">
            <div className="forms-container">
                <div className="signin-signup">
                    <form action="#" className="sign-in-form">
                      <h2 className="title">Editează proiectul </h2>


                      <div className="input-switch">
                          <h3>Formatul proiectului</h3>
                          <div className="categories">
                            <div className="category active" onClick={(e : any) => {
                              document.querySelectorAll(".category")[0].classList.add("active");
                              document.querySelectorAll(".category")[1].classList.remove("active");
                              setInputs(currInputs => {
                                return {...currInputs, 
                                  type: "A4"
                                };
                              });
                            }}>
                              <FontAwesomeIcon className="category-icon" icon={faFileLines} />
                              <p>A4</p>
                            </div>

                            <div className="category" onClick={(e : any) => {
                              document.querySelectorAll(".category")[1].classList.add("active");
                              document.querySelectorAll(".category")[0].classList.remove("active");
                              setInputs(currInputs => {
                                return {...currInputs, 
                                  type: "A3"
                                };
                              });
                            }}>
                              <FontAwesomeIcon className="category-icon" icon={faFileLines} />
                              <p>A3</p>
                            </div>

                      </div>

                      <div className="input-field">
                          <FontAwesomeIcon icon={faUser} className="input-icon" />
                          <input type="text" name="projectName" value={inputs.projectName} onChange={handleInputChange} placeholder="Numele proiectului *" />
                      </div>
                  

                      <div className="input-field">
                          <FontAwesomeIcon icon={faBuilding} className="input-icon" />
                          <input type="text" name="designerCompany" value={inputs.designerCompany} onChange={handleInputChange} placeholder="Firma proiectanta *" />
                      </div>

                      <div className="input-field">
                        <FontAwesomeIcon icon={faUser} className="input-icon" />
                        <input type="text" name="beneficiary" value={inputs.beneficiary} onChange={handleInputChange} placeholder="Beneficiar *" />
                      </div>

                      <div className="input-field">
                        <FontAwesomeIcon icon={faUserPen} className="input-icon" />
                        <input type="text" name="designer" value={inputs.designer} onChange={handleInputChange} placeholder="Proiectant *" />
                      </div>

                      <div className="input-field">
                        <FontAwesomeIcon icon={faIdCard} className="input-icon" />
                        <input type="text" name="verifierI" value={inputs.verifierI} onChange={handleInputChange} placeholder="Verificator I *" />
                      </div>

                      <div className="input-field">
                        <FontAwesomeIcon icon={faIdCard} className="input-icon" />
                        <input type="text" name="verifierII" value={inputs.verifierII} onChange={handleInputChange} placeholder="Verificator II *" />
                      </div>

                      <div className="input-field">
                        <FontAwesomeIcon icon={faIdCard} className="input-icon" />
                        <input type="text" name="verifierIII" value={inputs.verifierIII} onChange={handleInputChange} placeholder="Verificator III" />
                      </div>

                      <div className="input-field">
                        <FontAwesomeIcon icon={faCalendar} className="input-icon" />
                        <input type="text" name="date" value={inputs.date} onChange={handleInputChange} placeholder="Data proiectarii *" />
                      </div>

                      <div className="input-field">
                        <FontAwesomeIcon icon={faIdCard} className="input-icon" />
                        <input type="number" name="id" value={inputs.id} min="1" onChange={handleInputChange} placeholder="Id Proiect *" />
                      </div>


                    </div>
                      
                    <p className="error">{errorMessage}</p>
                    <input type="submit" value={cookies?.language === "en" ? "Save changes" : "Salveaza modificarile"} className="btn solid" onClick={async (e : any) => await handleCreateProject(e)} />

                  </form>
                </div>
            </div>

        </div>
    );
};

export default EditProject;