import { useCookies } from "react-cookie";
import "./Processing.css";

const Processing = () => {

    const [cookies, setCookies] = useCookies();

    return (
        <h2 className="process-text">
            {cookies?.language === "en" ? "Loading" : "Încărcare"}
            <span>.</span>
            <span>.</span>
            <span>.</span>
        </h2>
    );
};

export default Processing;