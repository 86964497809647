import { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useCookies } from "react-cookie";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Grid from "../../Components/Grid/Grid";
import Processing from "../../Components/Processing/Processing";
import SCHEME_ELEMENTS from "../../Functions/getElements";
import zoomFunc from "../../Functions/zoomFunction";
import "./PredefinedSchemeDebug.css";

const COLUMNS = 28;

const PredefinedSchemeDebug = ({ useStore, data, problems, problemsSolved, totalProblems, notes, isTeacher } : { useStore: any, data: any, problems: any, problemsSolved: unknown, totalProblems: unknown, notes: string, isTeacher: boolean }) => {
    
    const navigate = useNavigate();
    const currParams = useParams();
    const schemeNumber = currParams.schemeNumber;
    const location = useLocation();
    if (schemeNumber === null || (data.userData.type === "student" && !problems)) navigate(-1);
    
    const [cookies, setCookie] = useCookies();

    const number = Number(schemeNumber);
    const addSign = useStore((state: any) => state.addSign);

    const reset = useStore((store: any) => store.reset);
    const changeGrid = useStore((state : any) => state.changeGrid);
    const imageLocking = useStore((state: any) => state.imageLocking);
    const signs = useStore((state: any) => state.elements);
    const changeIsSelectedSign = useStore((state: any) => state.changeIsSelectedSign);
    const removeSign = useStore((state: any) => state.removeSign);
    const activeElementX = number === 1 ? 11 : number === 2 ? 5 : 5;
    const activeElementY = number === 1 ? 2 : number === 2 ? 11 : 17;
    const [finished, setFinished] = useState<boolean>(false);
    const [projectName, setProjectName] = useState("");
    const [resetCount, setResetCount] = useState<number>(0);
    const [spin, setSpin] = useState<boolean>(false);
    const replaceSign = useStore((state: any) => state.replaceSign);
    const [skipVerification, setSkipVerification] = useState<boolean>(false);
    // type SchemeStructure = {
    //     dotNumber: number,
    //     open: boolean,
    //     defect: string,
    //     line: string,
    //     potential: number
    // };

    const schemeStructure = useStore((state: any) => state.schemeStructure);
    const changeSchemeStructure = useStore((state: any) => state.changeSchemeStructure);

    useEffect(() => {
        if (number === 1) {
            document.body.setAttribute("zoom", "2.8");
            zoomFunc(2.8, false);
        } else if (number === 2) {
            document.body.setAttribute("zoom", "1.6");
            zoomFunc(1.6, false);
        } else if (number === 3) {
            document.body.setAttribute("zoom", "2");
            zoomFunc(2, false);
        }

    }, [location.state]);

    const signPage = useMemo(() => localStorage.getItem("sign-page")?.toString(), []);


    useEffect(() => {

        reset();
        changeGrid();
        imageLocking();

        if (number === 1) {
            addSign(1, 2, 1, SCHEME_ELEMENTS["contur"].imageSource, 0, [{ name: "Faza", positionX: 2, positionY: 2.5, flip: 0, fontSize: 14, fontWeight: 700 }]);
            addSign(2, 2, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(3, 2, 1,  SCHEME_ELEMENTS["siguranta"].imageSource, 90, [{ name: "F1", positionX: 4, positionY: -1.5 }]);
            addSign(4, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(5, 2, 1,  SCHEME_ELEMENTS["regleta_1"].imageSource, 90, [{ name: "1", positionX: 4.5, positionY: 3.5, flip: 0, fontSize: 10, fontWeight: 700 }]);
            addSign(6, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(7, 2, 1,  SCHEME_ELEMENTS["contact_normal_deschis_actionare_prin_apasare"].imageSource, 90, [ { name: "I", positionX: 4.5, flip: 0, positionY: -3, fontSize: 10, fontWeight: 700 } ]);
            addSign(8, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(9, 2, 1,  SCHEME_ELEMENTS["regleta_1"].imageSource, 90, [{ name: "3", positionX: 4.5, positionY: 3.5, flip: 0, fontSize: 10, fontWeight: 700 }]);
            addSign(10, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(11, 2, 1,  SCHEME_ELEMENTS["indicator_luminos"].imageSource, 90);
            addSign(12, 2, 1,  SCHEME_ELEMENTS["colt_dreapta_sus"].imageSource, 0);
            addSign(12, 3, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource, 0);

            addSign(1, 4, 1,  SCHEME_ELEMENTS["contur"].imageSource, 0, [{ name: "Nul", positionX: 3, positionY: 2.5, flip: 0, fontSize: 14, fontWeight: 700 }]);
            addSign(2, 4, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(3, 4, 1,  SCHEME_ELEMENTS["siguranta"].imageSource, 90, [{ name: "F2", positionX: 4, positionY: -1.5 }]);
            addSign(4, 4, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(5, 4, 1,  SCHEME_ELEMENTS["regleta_1"].imageSource, 90, [{ name: "2", positionX: 4.5, positionY: 3.5, flip: 0, fontSize: 10, fontWeight: 700 }]);
            addSign(6, 4, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(7, 4, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(8, 4, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(9, 4, 1,  SCHEME_ELEMENTS["regleta_1"].imageSource, 90, [{ name: "4", positionX: 4.5, positionY: 3.5, flip: 0, fontSize: 10, fontWeight: 700 }]);
            addSign(10, 4, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(11, 4, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(12, 4, 1,  SCHEME_ELEMENTS["colt_dreapta_jos"].imageSource, 0);

            addSign(2, 5, 1, SCHEME_ELEMENTS["derivatie_din_conductor"].imageSource, 0, [], "invert(59%) sepia(63%) saturate(439%) hue-rotate(66deg) brightness(94%) contrast(81%)");
            addSign(2, 6, 1, SCHEME_ELEMENTS["priza_de_pamant_de_protectie"].imageSource, 0, [], "invert(59%) sepia(63%) saturate(439%) hue-rotate(66deg) brightness(94%) contrast(81%)");
            addSign(3, 5, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource);
            addSign(4, 5, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 0);
            addSign(5, 5, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource);
            addSign(6, 5, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 0);
            addSign(7, 5, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource);
            addSign(8, 5, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 0);
            addSign(9, 5, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource);
            addSign(10, 5, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 0);
            addSign(11, 5, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource);
            addSign(12, 5, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 0);
            addSign(13, 5, 1, SCHEME_ELEMENTS["colt_dreapta_jos"].imageSource, 0, [], "invert(59%) sepia(63%) saturate(439%) hue-rotate(66deg) brightness(94%) contrast(81%)");
            addSign(13, 4, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 90);
            addSign(13, 3, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 90);
            addSign(13, 2, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 90);
            addSign(13, 1, 1, SCHEME_ELEMENTS["colt_dreapta_sus"].imageSource, 0, [], "invert(59%) sepia(63%) saturate(439%) hue-rotate(66deg) brightness(94%) contrast(81%)");
            addSign(12, 1, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 0);
            addSign(11, 1, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource);
            addSign(10, 1, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 0);
            addSign(9, 1, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource);
            addSign(8, 1, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 0);
            addSign(7, 1, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource);
            addSign(6, 1, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 0);
            addSign(5, 1, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource);
            addSign(4, 1, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 0);
            addSign(3, 1, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource);

            changeIsSelectedSign(2, 2);
            setProjectName("Circuit simplu");
            // setFinished(true);

        } else if (number === 2) {

            addSign(1, 0, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [{ name: "L1", positionX: -5, positionY: 3, flip: 0, fontSize: 14, fontWeight: 700 }], "#000", "vertical"); 
            addSign(2, 0, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(3, 0, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(4, 0, 1, SCHEME_ELEMENTS["derivatie_din_conductor"].imageSource, 0, [], "#000", "vertical"); 
            addSign(5, 0, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(6, 0, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(7, 0, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(8, 0, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(9, 0, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(10, 0, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(11, 0, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(12, 0, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(13, 0, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(14, 0, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(15, 0, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(16, 0, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(17, 0, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(18, 0, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(19, 0, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(20, 0, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(21, 0, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(22, 0, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(23, 0, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(24, 0, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(25, 0, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(26, 0, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(27, 0, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 


            addSign(1, 1, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [{ name: "L2", positionX: -5, positionY: 3, flip: 0, fontSize: 14, fontWeight: 700 }], "#000", "vertical"); 
            addSign(2, 1, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(3, 1, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(4, 1, 1, SCHEME_ELEMENTS["cruce"].imageSource, 0, [], "#000", "vertical"); 
            addSign(5, 1, 1, SCHEME_ELEMENTS["derivatie_din_conductor"].imageSource, 0, [], "#000", "vertical"); 
            addSign(6, 1, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(7, 1, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(8, 1, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(9, 1, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(10, 1, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(11, 1, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(12, 1, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(13, 1, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(14, 1, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(15, 1, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(16, 1, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(17, 1, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(18, 1, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(19, 1, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(20, 1, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(21, 1, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(22, 1, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(23, 1, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(24, 1, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(25, 1, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(26, 1, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(27, 1, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 


            addSign(1, 2, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [{ name: "L3", positionX: -5, positionY: 3, flip: 0, fontSize: 14, fontWeight: 700 }], "#000", "vertical"); 
            addSign(2, 2, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(3, 2, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(4, 2, 1, SCHEME_ELEMENTS["cruce"].imageSource, 0, [], "#000", "vertical"); 
            addSign(5, 2, 1, SCHEME_ELEMENTS["cruce"].imageSource, 0, [], "#000", "vertical"); 
            addSign(6, 2, 1, SCHEME_ELEMENTS["derivatie_din_conductor"].imageSource, 0, [], "#000", "vertical"); 
            addSign(7, 2, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(8, 2, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(9, 3, 1, SCHEME_ELEMENTS["derivatie_din_conductor"].imageSource, 0, [], "#000", "vertical"); 
            addSign(10, 2, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(11, 2, 1, SCHEME_ELEMENTS["derivatie_din_conductor"].imageSource, 0, [], "#000", "vertical"); 
            addSign(12, 2, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(13, 2, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(14, 2, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(15, 2, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(16, 2, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(17, 2, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(18, 2, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(19, 2, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(20, 2, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(21, 2, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(22, 2, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(23, 2, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(24, 2, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(25, 2, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(26, 2, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(27, 2, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 


            addSign(1, 3, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [{ name: "N", positionX: -5, positionY: 3, flip: 0, fontSize: 14, fontWeight: 700 }], "#000", "vertical"); 
            addSign(2, 3, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(3, 3, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(4, 3, 1, SCHEME_ELEMENTS["cruce"].imageSource, 0, [], "#000", "vertical"); 
            addSign(5, 3, 1, SCHEME_ELEMENTS["cruce"].imageSource, 0, [], "#000", "vertical"); 
            addSign(6, 3, 1, SCHEME_ELEMENTS["cruce"].imageSource, 0, [], "#000", "vertical"); 
            addSign(7, 3, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(8, 3, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(9, 2, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(10, 3, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(11, 3, 1, SCHEME_ELEMENTS["cruce"].imageSource, 0, [], "#000", "vertical"); 
            addSign(12, 3, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(13, 3, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(14, 3, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(15, 3, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(16, 3, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(17, 3, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(18, 3, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(19, 3, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(20, 3, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(21, 3, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(22, 3, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(23, 3, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(24, 3, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(25, 3, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(26, 3, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 
            addSign(27, 3, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical"); 


            addSign(1, 4, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 0, [{ name: "PE", positionX: -5, positionY: 3, flip: 0, fontSize: 14, fontWeight: 700 }], "#000", "vertical"); 
            addSign(2, 4, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 0, [], "#000", "vertical"); 
            addSign(3, 4, 1, SCHEME_ELEMENTS["derivatie_din_conductor"].imageSource, 0, [], "invert(59%) sepia(63%) saturate(439%) hue-rotate(66deg) brightness(94%) contrast(81%)", "vertical"); 
            addSign(4, 4, 1, SCHEME_ELEMENTS["cruce"].imageSource, 0, [], "invert(59%) sepia(63%) saturate(439%) hue-rotate(66deg) brightness(94%) contrast(81%)", "vertical"); 
            addSign(5, 4, 1, SCHEME_ELEMENTS["cruce"].imageSource, 0, [], "invert(59%) sepia(63%) saturate(439%) hue-rotate(66deg) brightness(94%) contrast(81%)", "vertical"); 
            addSign(6, 4, 1, SCHEME_ELEMENTS["cruce"].imageSource, 0, [], "invert(59%) sepia(63%) saturate(439%) hue-rotate(66deg) brightness(94%) contrast(81%)", "vertical"); 
            addSign(7, 4, 1, SCHEME_ELEMENTS["semi_cruce"].imageSource, 90, [], "invert(59%) sepia(63%) saturate(439%) hue-rotate(66deg) brightness(94%) contrast(81%)", "vertical"); 
            addSign(8, 4, 1, SCHEME_ELEMENTS["semi_cruce"].imageSource, 90, [], "invert(59%) sepia(63%) saturate(439%) hue-rotate(66deg) brightness(94%) contrast(81%)", "vertical", "vertical"); 
            addSign(9, 4, 1, SCHEME_ELEMENTS["cruce"].imageSource, 0, [], "invert(59%) sepia(63%) saturate(439%) hue-rotate(66deg) brightness(94%) contrast(81%)", "vertical"); 
            addSign(10, 4, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 0, [], "#000", "vertical"); 
            addSign(11, 4, 1, SCHEME_ELEMENTS["cruce"].imageSource, 0, [], "invert(59%) sepia(63%) saturate(439%) hue-rotate(66deg) brightness(94%) contrast(81%)", "vertical"); 
            addSign(12, 4, 1, SCHEME_ELEMENTS["semi_cruce"].imageSource, 90, [], "invert(59%) sepia(63%) saturate(439%) hue-rotate(66deg) brightness(94%) contrast(81%)", "vertical"); 
            addSign(13, 4, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 0, [], "#000", "vertical"); 
            addSign(14, 4, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 0, [], "#000", "vertical"); 
            addSign(15, 4, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 0, [], "#000", "vertical"); 
            addSign(16, 4, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 0, [], "#000", "vertical"); 
            addSign(17, 4, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 0, [], "#000", "vertical"); 
            addSign(18, 4, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 0, [], "#000", "vertical"); 
            addSign(19, 4, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 0, [], "#000", "vertical"); 
            addSign(20, 4, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 0, [], "#000", "vertical"); 
            addSign(21, 4, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 0, [], "#000", "vertical"); 
            addSign(22, 4, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 0, [], "#000", "vertical"); 
            addSign(23, 4, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 0, [], "#000", "vertical"); 
            addSign(24, 4, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 0, [], "#000", "vertical"); 
            addSign(25, 4, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 0, [], "#000", "vertical"); 
            addSign(26, 4, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 0, [], "#000", "vertical"); 
            addSign(27, 4, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 0, [], "#000", "vertical"); 

            addSign(3, 5, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 90);
            addSign(3, 6, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 90);
            addSign(3, 7, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 90);
            addSign(3, 8, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 90);
            addSign(3, 9, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 90);
            addSign(3, 10, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 90);
            addSign(3, 11, 1, SCHEME_ELEMENTS["semi_cruce"].imageSource, 0, [], "invert(59%) sepia(63%) saturate(439%) hue-rotate(66deg) brightness(94%) contrast(81%)");
            addSign(3, 12, 1, SCHEME_ELEMENTS["colt_stanga_jos"].imageSource, 0, [], "invert(59%) sepia(63%) saturate(439%) hue-rotate(66deg) brightness(94%) contrast(81%)");
            addSign(4, 12, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 0);
            addSign(5, 12, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 0);
            addSign(6, 12, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 0);
            addSign(7, 12, 1, SCHEME_ELEMENTS["colt_dreapta_jos"].imageSource, 0, [], "invert(59%) sepia(63%) saturate(439%) hue-rotate(66deg) brightness(94%) contrast(81%)");
            addSign(7, 11, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 90);
            addSign(7, 10, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 90);
            addSign(7, 9, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 90);
            addSign(7, 8, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 90);
            addSign(7, 7, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 90);
            addSign(7, 6, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 90);
            addSign(7, 5, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 90);
            // addSign(7, 4, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 90);

            addSign(8, 5, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 90);
            addSign(8, 6, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 90);
            addSign(8, 7, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 90);
            addSign(8, 8, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 90);
            addSign(8, 9, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 90);
            addSign(8, 10, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 90);
            addSign(8, 11, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 90);
            addSign(8, 12, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 90);
            addSign(8, 13, 1, SCHEME_ELEMENTS["colt_stanga_jos"].imageSource, 0, [], "invert(59%) sepia(63%) saturate(439%) hue-rotate(66deg) brightness(94%) contrast(81%)");
            addSign(9, 13, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 0);
            addSign(10, 13, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 0);
            addSign(11, 13, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 0);
            addSign(12, 13, 1, SCHEME_ELEMENTS["colt_dreapta_jos"].imageSource, 0, [], "invert(59%) sepia(63%) saturate(439%) hue-rotate(66deg) brightness(94%) contrast(81%)");
            addSign(12, 12, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 90);
            addSign(12, 11, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 90);
            addSign(12, 10, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 90);
            addSign(12, 9, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 90);
            addSign(12, 8, 1, SCHEME_ELEMENTS["colt_stanga_sus"].imageSource, 0, [], "invert(59%) sepia(63%) saturate(439%) hue-rotate(66deg) brightness(94%) contrast(81%)");
            // addSign(13, 8, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 0);
            addSign(13, 8, 1, SCHEME_ELEMENTS["colt_dreapta_jos"].imageSource, 0, [], "invert(59%) sepia(63%) saturate(439%) hue-rotate(66deg) brightness(94%) contrast(81%)");
            addSign(13, 7, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 90, []);
            addSign(13, 6, 1, SCHEME_ELEMENTS["colt_dreapta_sus"].imageSource, 0, [], "invert(59%) sepia(63%) saturate(439%) hue-rotate(66deg) brightness(94%) contrast(81%)");


            // addSign(12, 7, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 90);
            addSign(12, 6, 1, SCHEME_ELEMENTS["colt_stanga_jos"].imageSource, 0, [], "invert(59%) sepia(63%) saturate(439%) hue-rotate(66deg) brightness(94%) contrast(81%)");
            addSign(12, 5, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 90);

            // addSign(13, 13, 1, SCHEME_ELEMENTS["colt_dreapta_jos"].imageSource, 0, [], "invert(59%) sepia(63%) saturate(439%) hue-rotate(66deg) brightness(94%) contrast(81%)");

            // addSign(3, 5, 1, SCHEME_ELEMENTS["conductor_lat_punctat"].imageSource, 0, [], "#000", "vertical"); 
            // addSign(3, 6, 1, SCHEME_ELEMENTS["conductor_lat_punctat"].imageSource, 0, [], "#000", "vertical"); 
            // addSign(3, 7, 1, SCHEME_ELEMENTS["conductor_lat_punctat"].imageSource, 0, [], "#000", "vertical"); 
            // addSign(3, 8, 1, SCHEME_ELEMENTS["conductor_lat_punctat"].imageSource, 0, [], "#000", "vertical"); 
            // addSign(3, 9, 1, SCHEME_ELEMENTS["conductor_lat_punctat"].imageSource, 0, [], "#000", "vertical"); 
            // addSign(3, 10, 1, SCHEME_ELEMENTS["conductor_lat_punctat"].imageSource, 0, [], "#000", "vertical"); 

            addSign(3, 11, 1, SCHEME_ELEMENTS["colt_stanga_jos_punctat"].imageSource, 0, [], "#000", "vertical"); 
            addSign(4, 11, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 0, [], "#000", "vertical"); 

            
            addSign(4, 5, 1, SCHEME_ELEMENTS["siguranta"].imageSource, 0, [{ name: "F1", positionX: -.5, positionY: 3.5, flip: 0, fontSize: 10, fontWeight: 700 }], "#000", "vertical");
            addSign(4, 6, 1, SCHEME_ELEMENTS["conductor_lat"].imageSource, 0, [], "#000", "vertical"); 
            addSign(4, 7, 1, SCHEME_ELEMENTS["contact_normal_deschis"].imageSource, 0, [{ name: "Q1", positionX: -1, positionY: 3.5, flip: 0, fontSize: 10, fontWeight: 700 }, { name: "1", positionX: 2.5, positionY: 0, flip: 0, fontSize: 10, fontWeight: 700 }, { name: "2", positionX: 2, positionY: 6, flip: 0, fontSize: 10, fontWeight: 700 } ], "#000", "vertical");
            addSign(4, 8, 1, SCHEME_ELEMENTS["conductor_lat"].imageSource, 0, [], "#000", "vertical"); 
            addSign(4, 9, 1, SCHEME_ELEMENTS["releu_termic"].imageSource, 0, [{ name: "R.t.", positionX: -1, positionY: 3.5, flip: 0, fontSize: 10, fontWeight: 700 }, { name: "1", positionX: 2.75, positionY: 0, flip: 0, fontSize: 10, fontWeight: 700 }, { name: "2", positionX: 2.5, positionY: 7.5, flip: 0, fontSize: 10, fontWeight: 700 } ], "#000", "vertical");
            addSign(4, 10, 1, SCHEME_ELEMENTS["conductor_oblic_135"].imageSource, 0, [], "#000", "vertical"); 
            

            addSign(5, 5, 1, SCHEME_ELEMENTS["siguranta"].imageSource, 0, [{ name: "F2", positionX: -.5, positionY: 3.5, flip: 0, fontSize: 10, fontWeight: 700 }], "#000", "vertical");
            addSign(5, 6, 1, SCHEME_ELEMENTS["conductor_lat"].imageSource, 0, [], "#000", "vertical"); 
            addSign(5, 7, 1, SCHEME_ELEMENTS["contact_normal_deschis"].imageSource, 0, [{ name: "3", positionX: 2, positionY: 0, flip: 0, fontSize: 10, fontWeight: 700 }, { name: "4", positionX: 2, positionY: 6, flip: 0, fontSize: 10, fontWeight: 700 }], "#000", "vertical");
            addSign(5, 8, 1, SCHEME_ELEMENTS["conductor_lat"].imageSource, 0, [], "#000", "vertical"); 
            addSign(5, 9, 1, SCHEME_ELEMENTS["releu_termic"].imageSource, 0, [{ name: "3", positionX: 2.75, positionY: 0, flip: 0, fontSize: 10, fontWeight: 700 }, { name: "4", positionX: 2.5, positionY: 7.5, flip: 0, fontSize: 10, fontWeight: 700 } ], "#000", "vertical");
            addSign(5, 10, 1, SCHEME_ELEMENTS["conductor_lat"].imageSource, 0, [], "#000", "vertical"); 
            addSign(5, 11, 1, SCHEME_ELEMENTS["motor_asincron_trifazat_cu_rotorul_in_scurtcircuit"].imageSource, 0, [], "#000", "engine vertical"); 


            addSign(6, 5, 1, SCHEME_ELEMENTS["siguranta"].imageSource, 0, [{ name: "F3", positionX: -.5, positionY: 3.5, flip: 0, fontSize: 10, fontWeight: 700 }], "#000", "vertical");
            addSign(6, 6, 1, SCHEME_ELEMENTS["conductor_lat"].imageSource, 0, [], "#000", "vertical");
            addSign(6, 7, 1, SCHEME_ELEMENTS["contact_normal_deschis"].imageSource, 0, [{ name: "5", positionX: 2, positionY: 0, flip: 0, fontSize: 10, fontWeight: 700 }, { name: "6", positionX: 2, positionY: 6, flip: 0, fontSize: 10, fontWeight: 700 }, { name: "Q1S3", positionX: 0, positionY: 0, flip: 0, fontSize: 0.001, fontWeight: 700  }], "#000", "vertical");
            addSign(6, 8, 1, SCHEME_ELEMENTS["conductor_lat"].imageSource, 0, [], "#000", "vertical");
            addSign(6, 9, 1, SCHEME_ELEMENTS["releu_termic"].imageSource, 0, [{ name: "5", positionX: 2.5, positionY: 0, flip: 0, fontSize: 10, fontWeight: 700 }, { name: "6", positionX: 2.5, positionY: 7.5, flip: 0, fontSize: 10, fontWeight: 700 } ], "#000", "vertical");
            addSign(6, 10, 1, SCHEME_ELEMENTS["conductor_oblic_45"].imageSource, 0, [], "#000", "vertical");

            addSign(9, 5, 1, SCHEME_ELEMENTS["siguranta"].imageSource, 0, [{ name: "F4", positionX: -1, positionY: 3.5, flip: 0, fontSize: 10, fontWeight: 700 }], "#000", "vertical");
            addSign(9, 6, 1, SCHEME_ELEMENTS["conductor_lat"].imageSource, 0, [], "#000", "vertical");
            addSign(9, 7, 1, SCHEME_ELEMENTS["contact_normal_inchis_prin_protectia_termica_la_supracurent"].imageSource, 0, [{ name: "R.T.", positionX: -4, positionY: 3.5, flip: 0, fontSize: 10, fontWeight: 700 }], "#000", "vertical");
            addSign(9, 8, 1, SCHEME_ELEMENTS["conductor_lat"].imageSource, 0, [], "#000", "vertical");
            addSign(9, 9, 1, SCHEME_ELEMENTS["conductor_lat"].imageSource, 0, [], "#000", "vertical");
            addSign(9, 10, 1, SCHEME_ELEMENTS["conductor_lat"].imageSource, 0, [], "#000", "vertical");
            addSign(9, 11, 1, SCHEME_ELEMENTS["conductor_lat"].imageSource, 0, [], "#000", "vertical");

            addSign(9, 12, 1, SCHEME_ELEMENTS["colt_stanga_jos"].imageSource, 0, [], "#000", "vertical");
            addSign(10, 12, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");


            addSign(11, 5, 1, SCHEME_ELEMENTS["siguranta"].imageSource, 0, [{ name: "F5", positionX: -1, positionY: 3.5, flip: 0, fontSize: 10, fontWeight: 700 }], "#000", "vertical");
            addSign(11, 6, 1, SCHEME_ELEMENTS["conductor_lat"].imageSource, 0, [], "#000", "vertical"); 
            addSign(11, 7, 1, SCHEME_ELEMENTS["contact_normal_deschis_actionare_prin_apasare_automentinere"].imageSource, 0, [{ name: "B.p.", positionX: -2.5, positionY: 7.5, flip: 0, fontSize: 10, fontWeight: 700 }], "#000", "left-automention vertical");
            addSign(12, 7, 1, SCHEME_ELEMENTS["contact_normal_deschis_automentinere"].imageSource, 0, [{ name: "C.a. Q1", positionX: 7.5, positionY: 3, flip: 0, fontSize: 10, fontWeight: 600 }], "#000", "right-automention vertical");

            addSign(11, 8, 1, SCHEME_ELEMENTS["conductor_lat"].imageSource, 0, [], "#000", "vertical"); 
            addSign(11, 9, 1, SCHEME_ELEMENTS["contact_normal_inchis_actionare_prin_apasare"].imageSource, 0, [{ name: "B.o.", positionX: -2.5, positionY: 7.5, flip: 0, fontSize: 10, fontWeight: 700 }], "#000", "vertical");
            addSign(11, 10, 1, SCHEME_ELEMENTS["conductor_lat"].imageSource, 0, [], "#000", "vertical"); 
            addSign(11, 11, 1, SCHEME_ELEMENTS["bobina_releu"].imageSource, 0, [], "#000", "vertical"); 
            addSign(11, 12, 1, SCHEME_ELEMENTS["colt_dreapta_jos"].imageSource, 0, [], "#000", "vertical"); 


            changeIsSelectedSign(2, 2);
            setProjectName("Pornirea într-un singur sens a motorului asincron trifazat");
        } else if (number === 3) {

            addSign(0, 0, 1,  SCHEME_ELEMENTS["contur"].imageSource, 0, [{ name: "L1", positionX: 3, positionY: 2.5, flip: 0, fontSize: 14, fontWeight: 700 }], "#000", "vertical");
            addSign(1, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(2, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(3, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(4, 0, 1,  SCHEME_ELEMENTS["derivatie_din_conductor"].imageSource, 0, [], "#000", "vertical");
            addSign(5, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(6, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(7, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(8, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(9, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(10, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(11, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(12, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(13, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(14, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(15, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(16, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(17, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(18, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(19, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(20, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(21, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(22, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(23, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(24, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(25, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(26, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(27, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(28, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            

            addSign(0, 1, 1,  SCHEME_ELEMENTS["contur"].imageSource, 0, [{ name: "L2", positionX: 3, positionY: 2.5, flip: 0, fontSize: 14, fontWeight: 700 }], "#000", "vertical");
            addSign(1, 1, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(2, 1, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(3, 1, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(4, 1, 1,  SCHEME_ELEMENTS["cruce"].imageSource, 0, [], "#000", "vertical");
            addSign(5, 1, 1,  SCHEME_ELEMENTS["derivatie_din_conductor"].imageSource, 0, [], "#000", "vertical");
            addSign(6, 1, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(7, 1, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(8, 1, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(9, 1, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(10, 1, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(11, 1, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(12, 1, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(13, 1, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(14, 1, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(15, 1, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(16, 1, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(17, 1, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(18, 1, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(19, 1, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(20, 1, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(21, 1, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(22, 1, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(23, 1, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(24, 1, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(25, 1, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(26, 1, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(27, 1, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(28, 1, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");

            addSign(0, 2, 1,  SCHEME_ELEMENTS["contur"].imageSource, 0, [{ name: "L3", positionX: 3, positionY: 2.5, flip: 0, fontSize: 14, fontWeight: 700 }], "#000", "vertical");
            addSign(1, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(2, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(3, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(4, 2, 1,  SCHEME_ELEMENTS["cruce"].imageSource, 0, [], "#000", "vertical");
            addSign(5, 2, 1,  SCHEME_ELEMENTS["cruce"].imageSource, 0, [], "#000", "vertical");
            addSign(6, 2, 1,  SCHEME_ELEMENTS["derivatie_din_conductor"].imageSource, 0, [], "#000", "vertical");
            addSign(7, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(8, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(9, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(10, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(11, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(12, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(13, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(14, 2, 1,  SCHEME_ELEMENTS["derivatie_din_conductor"].imageSource, 0, [], "#000", "vertical");
            addSign(15, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(16, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(17, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(18, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(19, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(20, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(21, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(22, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(23, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(24, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(25, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(26, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(27, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(28, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            
            addSign(0, 3, 1,  SCHEME_ELEMENTS["contur"].imageSource, 0, [{ name: "N", positionX: 3.5, positionY: 2.5, flip: 0, fontSize: 14, fontWeight: 700 }], "#000", "vertical");
            addSign(1, 3, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(2, 3, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(3, 3, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(4, 3, 1,  SCHEME_ELEMENTS["cruce"].imageSource, 0, [], "#000", "vertical");
            addSign(5, 3, 1,  SCHEME_ELEMENTS["cruce"].imageSource, 0, [], "#000", "vertical");
            addSign(6, 3, 1,  SCHEME_ELEMENTS["cruce"].imageSource, 0, [], "#000", "vertical");
            addSign(7, 3, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(8, 3, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(9, 3, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(10, 3, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(11, 3, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(12, 3, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(13, 3, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(14, 3, 1,  SCHEME_ELEMENTS["cruce"].imageSource, 0, [], "#000", "vertical");
            addSign(15, 3, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(16, 3, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(17, 3, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(18, 3, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(19, 3, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(21, 3, 1,  SCHEME_ELEMENTS["derivatie_din_conductor"].imageSource, 0, [], "#000", "vertical");
            addSign(20, 3, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(22, 3, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(23, 3, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(24, 3, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(25, 3, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(26, 3, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(27, 3, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(28, 3, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");


            // addSign(4,4, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource, 0, [], "#000", "vertical");
            // addSign(14, 4, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource, 0, [], "#000", "vertical");
            addSign(14, 5, 1,  SCHEME_ELEMENTS["siguranta"].imageSource, 0, [{ name: "F4", positionX: 0, positionY: 3.5, flip: 0, fontSize: 10, fontWeight: 700 }], "#000", "vertical");
            addSign(14, 6, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource, 0, [], "#000", "vertical");

            addSign(14, 7, 1,  SCHEME_ELEMENTS["contact_normal_inchis_prin_protectia_termica_la_supracurent"].imageSource, 0, [{ name: "F11.1", positionX: -5, positionY: 3.5, flip: 0, fontSize: 10, fontWeight: 700 }], "#000", "vertical");
            addSign(14, 8, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource, 0, [], "#000", "vertical");

            addSign(14, 9, 1,  SCHEME_ELEMENTS["contact_normal_inchis_actionare_prin_apasare"].imageSource, 0, [{ name: "B.o.", positionX: -5, positionY: 3, flip: 0, fontSize: 10, fontWeight: 700 }, { name: "S3", positionX: 10.5, positionY: 2.5, flip: 0, fontSize: 10, fontWeight: 700 }], "#000", "vertical");
            addSign(14, 10, 1,  SCHEME_ELEMENTS["semi_cruce"].imageSource, 0, [], "#000", "vertical");
            addSign(14, 11, 1,  SCHEME_ELEMENTS["contact_normal_deschis_actionare_prin_apasare_automentinere"].imageSource, 0, [{ name: "S1", positionX: -3, positionY: 4, flip: 0, fontSize: 10, fontWeight: 700 }, { name: "B.p.", positionX: 0, positionY: 0, flip: 0, fontSize: 0.01, fontWeight: 700 }], "#000", "left-automention vertical");
            addSign(14, 12, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource, 0, [], "#000", "vertical");
            addSign(14, 13, 1,  SCHEME_ELEMENTS["contact_normal_inchis"].imageSource, 0, [{ name: "Q11.2.", positionX: -1.5, positionY: 3.75, flip: 0, fontSize: 10, fontWeight: 700 }, { name: "C.i. Q11.2", positionX: 0, positionY: 0, flip: 0, fontSize: 0.01, fontWeight: 700 }], "#000", "vertical");

            // addSign(15, 10, 1,  SCHEME_ELEMENTS["colt_dreapta_jos"].imageSource, 0, [], "#000", "vertical");
            addSign(15, 11, 1,  SCHEME_ELEMENTS["contact_normal_deschis_automentinere"].imageSource, 0, [{ name: "Q11.1.", positionX: 7, positionY: 3.75, flip: 0, fontSize: 10, fontWeight: 700 }, { name: "C.a. Q1", positionX: 0, positionY: 0, flip: 0, fontSize: 0.01, fontWeight: 700 }], "#000", "right-automention vertical");

            addSign(15, 10, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(16, 10, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(17, 10, 1,  SCHEME_ELEMENTS["colt_dreapta_sus"].imageSource, 0, [], "#000", "vertical");
            addSign(17, 11, 1,  SCHEME_ELEMENTS["contact_normal_deschis_actionare_prin_apasare_automentinere"].imageSource, 0, [{ name: "S2", positionX: -3, positionY: 4, flip: 0, fontSize: 10, fontWeight: 700 }, { name: "B.p.", positionX: 0, positionY: 0, flip: 0, fontSize: 0.01, fontWeight: 700 }], "#000", "left-automention vertical");
            addSign(17, 12, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource, 0, [], "#000", "vertical");
            addSign(17, 13, 1,  SCHEME_ELEMENTS["contact_normal_inchis"].imageSource, 0, [{ name: "Q11.1.", positionX: -1.5, positionY: 3.75, flip: 0, fontSize: 10, fontWeight: 700 }, { name: "C.i. Q11.1", positionX: 0, positionY: 0, flip: 0, fontSize: 0.01, fontWeight: 700 }], "#000", "vertical");

            // addSign(18, 8, 1,  SCHEME_ELEMENTS["colt_dreapta_sus"].imageSource, 0, [], "#000", "vertical");
            addSign(18, 11, 1,  SCHEME_ELEMENTS["contact_normal_deschis_automentinere"].imageSource, 0, [{ name: "Q11.2.", positionX: 7, positionY: 3.75, flip: 0, fontSize: 10, fontWeight: 700 }, { name: "C.a. Q11.2", positionX: 0, positionY: 0, flip: 0, fontSize: 0.01, fontWeight: 700 }], "#000", "right-automention vertical");
            // addSign(18, 10, 1,  SCHEME_ELEMENTS["colt_dreapta_jos"].imageSource, 0, [], "#000", "vertical");
            // addSign(17, 11, 1,  SCHEME_ELEMENTS["contact_normal_inchis_copy"].imageSource, 0, [{ name: "Q11.1.", positionX: -1.5, positionY: 3.75, flip: 0, fontSize: 10, fontWeight: 700 }], "#000", "vertical");
            addSign(17, 12, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource, 0, [], "#000", "vertical");
            addSign(17, 15, 1,  SCHEME_ELEMENTS["bobina_releu"].imageSource, 0, [{ name: "Q11.2.", positionX: -3.5, positionY: 3.75, flip: 0, fontSize: 10, fontWeight: 700 }, { name: "A1", positionX: 6.25, positionY: 0, flip: -90, fontSize: 10, fontWeight: 700 }, { name: "A2", positionX: 6.25, positionY: 8, flip: -90, fontSize: 10, fontWeight: 700 }], "#000", "vertical");
            addSign(14, 14, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource, 0, [], "#000", "vertical");
            
            addSign(14, 15, 1,  SCHEME_ELEMENTS["bobina_releu"].imageSource, 0, [{ name: "Q11.1.", positionX: -3.5, positionY: 3.75, flip: 0, fontSize: 10, fontWeight: 700 }, { name: "A1", positionX: 6.25, positionY: 0, flip: -90, fontSize: 10, fontWeight: 700 }, { name: "A2", positionX: 6.25, positionY: 8, flip: -90, fontSize: 10, fontWeight: 700 }], "#000", "vertical");
            addSign(14, 16, 1,  SCHEME_ELEMENTS["colt_stanga_jos"].imageSource, 0, [], "#000", "vertical");
            addSign(15, 16, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");

            addSign(17, 14, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource, 0, [], "#000", "vertical");

            addSign(16, 16, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(17, 16, 1,  SCHEME_ELEMENTS["semi_cruce"].imageSource, 270, [], "#000", "vertical");
            addSign(18, 16, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 180, [], "#000", "vertical");
            // addSign(19, 16, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 180, [], "#000", "vertical");
            addSign(21, 16, 1,  SCHEME_ELEMENTS["colt_dreapta_jos"].imageSource, 0, [], "#000", "vertical");
            addSign(20, 16, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");

            addSign(21, 15, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource, 0, [], "#000", "vertical");
            addSign(21, 14, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource, 0, [], "#000", "vertical");
            addSign(21, 13, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource, 0, [], "#000", "vertical");
            addSign(21, 12, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource, 0, [], "#000", "vertical");
            addSign(21, 11, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource, 0, [], "#000", "vertical");
            addSign(21, 10, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource, 0, [], "#000", "vertical");
            addSign(21, 9, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource, 0, [], "#000", "vertical");
            addSign(21, 8, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource, 0, [], "#000", "vertical");
            addSign(21, 7, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource, 0, [], "#000", "vertical");
            addSign(21, 6, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource, 0, [], "#000", "vertical");
            addSign(21, 5, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource, 0, [], "#000", "vertical");
            // addSign(20, 4, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource, 0, [], "#000", "vertical");


            addSign(4, 5, 1,  SCHEME_ELEMENTS["siguranta"].imageSource,  0, [{ name: "F1", positionX: 0, positionY: 3.5, flip: 0, fontSize: 10, fontWeight: 700 }], "#000", "vertical");
            addSign(4, 6, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource, 0, [], "#000", "vertical");

            addSign(4, 7, 1,  SCHEME_ELEMENTS["semi_cruce"].imageSource, 0, [], "#000", "vertical");
            addSign(4, 8, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource, 0, [], "#000", "vertical");
            addSign(4, 9, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource, 0, [], "#000", "vertical");
            addSign(4, 10, 1,  SCHEME_ELEMENTS["contact_normal_deschis"].imageSource, 0, [{ name: "Q11.1", positionX: -3, positionY: 3.5, flip: 0, fontSize: 10, fontWeight: 700 }, { name: "Q1", positionX: -3, positionY: 3.5, flip: 0, fontSize: 0.01, fontWeight: 700 }, { name: "1", positionX: 3.2, positionY: -.5, flip: 0, fontSize: 10, fontWeight: 700 }, { name: "2", positionX: 3, positionY: 6, flip: 0, fontSize: 10, fontWeight: 700 }], "#000", "vertical");
            addSign(4, 11, 1,  SCHEME_ELEMENTS["semi_cruce"].imageSource, 0, [], "#000", "vertical");
            addSign(4, 12, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource, 0, [], "#000", "vertical");
            addSign(4, 13, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource, 0, [], "#000", "vertical");
            addSign(4, 14, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource, 0, [], "#000", "vertical");
            addSign(4, 15, 1,  SCHEME_ELEMENTS["releu_termic"].imageSource, 0, [{ name: "F11.1", positionX: -3, positionY: 3.5, flip: 0, fontSize: 10, fontWeight: 700 }, { name: "1", positionX: 2.5, positionY: 1, flip: 0, fontSize: 10, fontWeight: 700 }, { name: "2", positionX: 2.5, positionY: 7.5, flip: 0, fontSize: 10, fontWeight: 700 }], "#000", "vertical");
            addSign(4, 16, 1,  SCHEME_ELEMENTS["conductor_oblic_135"].imageSource, 0, [], "#000", "vertical");


            // addSign(5, 4, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource, 0, [], "#000", "vertical");
            addSign(5, 5, 1,  SCHEME_ELEMENTS["siguranta"].imageSource,  0, [{ name: "F2", positionX: 0, positionY: 3.5, flip: 0, fontSize: 10, fontWeight: 700 }], "#000", "vertical");
            addSign(5, 6, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource, 0, [], "#000", "vertical");

            addSign(5, 7, 1,  SCHEME_ELEMENTS["cruce"].imageSource, 0, [], "#000", "vertical");
            addSign(5, 8, 1,  SCHEME_ELEMENTS["semi_cruce"].imageSource, 0, [], "#000", "vertical");
            addSign(5, 9, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource, 0, [], "#000", "vertical");
            addSign(5, 10, 1,  SCHEME_ELEMENTS["contact_normal_deschis"].imageSource, 0, [{ name: "3", positionX: 3, positionY: -.5, flip: 0, fontSize: 10, fontWeight: 700 }, { name: "4", positionX: 3, positionY: 6, flip: 0, fontSize: 10, fontWeight: 700 }], "#000", "vertical");
            addSign(5, 11, 1,  SCHEME_ELEMENTS["cruce"].imageSource, 0, [], "#000", "vertical");
            addSign(5, 12, 1,  SCHEME_ELEMENTS["semi_cruce"].imageSource, 0, [], "#000", "vertical");
            addSign(5, 13, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource, 0, [], "#000", "vertical");
            addSign(5, 14, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource, 0, [], "#000", "vertical");

            addSign(5, 15, 1,  SCHEME_ELEMENTS["releu_termic"].imageSource, 0, [{ name: "3", positionX: 2.5, positionY: 1, flip: 0, fontSize: 10, fontWeight: 700 }, { name: "4", positionX: 2.5, positionY: 7.5, flip: 0, fontSize: 10, fontWeight: 700 }], "#000", "vertical");
            addSign(5, 16, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource, 0, [], "#000", "vertical");

            addSign(5, 17, 1,  SCHEME_ELEMENTS["motor_asincron_trifazat_cu_rotorul_in_scurtcircuit"].imageSource, 0, [], "#000", "engine vertical");
            // addSign(4, 15, 1,  SCHEME_ELEMENTS["colt_stanga_sus"].imageSource, 0, [], "#000", "vertical");
            // addSign(4, 16, 1,  SCHEME_ELEMENTS["priza_de_pamant_de_protectie"].imageSource, 0, [], "#000", "vertical");

            // addSign(6, 4, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource, 0, [], "#000", "vertical");
            addSign(6, 5, 1,  SCHEME_ELEMENTS["siguranta"].imageSource, 0, [{ name: "F3", positionX: 0, positionY: 3.5, flip: 0, fontSize: 10, fontWeight: 700 }], "#000", "vertical");
            addSign(6, 6, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource, 0, [], "#000", "vertical");

            addSign(6, 7, 1,  SCHEME_ELEMENTS["cruce"].imageSource, 0, [], "#000", "vertical");
            addSign(6, 8, 1,  SCHEME_ELEMENTS["cruce"].imageSource, 0, [], "#000", "vertical");
            addSign(6, 9, 1,  SCHEME_ELEMENTS["semi_cruce"].imageSource, 0, [], "#000", "vertical");


            addSign(6, 10, 1,  SCHEME_ELEMENTS["contact_normal_deschis"].imageSource, 0, [{ name: "5", positionX: 3, positionY: -.5, flip: 0, fontSize: 10, fontWeight: 700 }, { name: "6", positionX: 3, positionY: 6, flip: 0, fontSize: 10, fontWeight: 700 }, { name: "Q11.1S3", positionX: 0, positionY: 0, flip: 0, fontSize: 0.001, fontWeight: 700 }], "#000", "vertical");
            addSign(6, 11, 1,  SCHEME_ELEMENTS["cruce"].imageSource, 0, [], "#000", "vertical");
            addSign(6, 12, 1,  SCHEME_ELEMENTS["cruce"].imageSource, 0, [], "#000", "vertical");
            addSign(6, 13, 1,  SCHEME_ELEMENTS["semi_cruce"].imageSource, 0, [], "#000", "vertical");
            // addSign(6, 12, 1,  SCHEME_ELEMENTS["semi_cruce"].imageSource, 0, [], "#000", "vertical");
            addSign(6, 14, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource, 0, [], "#000", "vertical");

            addSign(6, 15, 1,  SCHEME_ELEMENTS["releu_termic"].imageSource,  0, [{ name: "5", positionX: 2.5, positionY: 1, flip: 0, fontSize: 10, fontWeight: 700 }, { name: "6", positionX: 2.5, positionY: 7.5, flip: 0, fontSize: 10, fontWeight: 700 }], "#000", "vertical");
            addSign(6, 16, 1,  SCHEME_ELEMENTS["conductor_oblic_45"].imageSource, 0, [], "#000", "vertical");

            // addSign(6, 14, 1,  SCHEME_ELEMENTS["releu_termic"].imageSource,  0, [{ name: "5", positionX: 2.5, positionY: 1, flip: 0, fontSize: 10, fontWeight: 700 }, { name: "6", positionX: 2.5, positionY: 7.5, flip: 0, fontSize: 10, fontWeight: 700 }], "#000", "vertical");
            

            addSign(7, 7, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(7, 8, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(7, 9, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");


            addSign(8, 7, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(8, 8, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(8, 9, 1,  SCHEME_ELEMENTS["colt_dreapta_sus"].imageSource, 0, [], "#000", "vertical");
            addSign(8, 10, 1,  SCHEME_ELEMENTS["contact_normal_deschis"].imageSource, 0, [{ name: "Q11.2", positionX: -3, positionY: 3.5, flip: 0, fontSize: 10, fontWeight: 700 }, { name: "7", positionX: 3, positionY: -.5, flip: 0, fontSize: 10, fontWeight: 700 }, { name: "8", positionX: 3, positionY: 6, flip: 0, fontSize: 10, fontWeight: 700 }], "#000", "vertical");
            addSign(8, 11, 1,  SCHEME_ELEMENTS["colt_dreapta_jos"].imageSource, 0, [], "#000", "vertical");
            addSign(7, 11, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");


            addSign(9, 7, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(9, 8, 1,  SCHEME_ELEMENTS["colt_dreapta_sus"].imageSource, 0, [], "#000", "vertical");
            addSign(9, 9, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource, 0, [], "#000", "vertical");
            addSign(9, 10, 1,  SCHEME_ELEMENTS["contact_normal_deschis"].imageSource, 0, [{ name: "9", positionX: 3, positionY: -.5, flip: 0, fontSize: 10, fontWeight: 700 }, { name: "10", positionX: 2.5, positionY: 6, flip: 0, fontSize: 10, fontWeight: 700 }], "#000", "vertical");
            addSign(9, 11, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource, 0, [], "#000", "vertical");
            addSign(9, 12, 1,  SCHEME_ELEMENTS["colt_dreapta_jos"].imageSource, 0, [], "#000", "vertical");
            addSign(8, 12, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(7, 12, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");

            addSign(10, 7, 1,  SCHEME_ELEMENTS["colt_dreapta_sus"].imageSource, 0, [], "#000", "vertical");
            addSign(10, 8, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource, 0, [], "#000", "vertical");
            addSign(10, 9, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource, 0, [], "#000", "vertical");
            addSign(10, 10, 1,  SCHEME_ELEMENTS["contact_normal_deschis"].imageSource, 0, [{ name: "11", positionX: 2.5, positionY: -.5, flip: 0, fontSize: 10, fontWeight: 700 }, { name: "12", positionX: 2.5, positionY: 6, flip: 0, fontSize: 10, fontWeight: 700 }, { name: "Q11.2S3", positionX: 0, positionY: 0, flip: 0, fontSize: 0.001, fontWeight: 700 }], "#000", "vertical");
            addSign(10, 11, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource, 0, [], "#000", "vertical");
            addSign(10, 12, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource, 0, [], "#000", "vertical");
            addSign(10, 13, 1,  SCHEME_ELEMENTS["colt_dreapta_jos"].imageSource, 0, [], "#000", "vertical");
            addSign(9, 13, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(8, 13, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");
            addSign(7, 13, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0, [], "#000", "vertical");

            addSign(1, 4, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 0, [{ name: "PE", positionX: -5, positionY: 3, flip: 0, fontSize: 14, fontWeight: 700 }], "#000", "vertical"); 
            addSign(2, 4, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 0, [], "#000", "vertical"); 
            addSign(3, 4, 1, SCHEME_ELEMENTS["derivatie_din_conductor"].imageSource, 0, [], "invert(59%) sepia(63%) saturate(439%) hue-rotate(66deg) brightness(94%) contrast(81%)", "vertical"); 
            addSign(4, 4, 1, SCHEME_ELEMENTS["cruce"].imageSource, 0, [], "invert(59%) sepia(63%) saturate(439%) hue-rotate(66deg) brightness(94%) contrast(81%)", "vertical"); 
            addSign(5, 4, 1, SCHEME_ELEMENTS["cruce"].imageSource, 0, [], "invert(59%) sepia(63%) saturate(439%) hue-rotate(66deg) brightness(94%) contrast(81%)", "vertical"); 
            addSign(6, 4, 1, SCHEME_ELEMENTS["cruce"].imageSource, 0, [], "invert(59%) sepia(63%) saturate(439%) hue-rotate(66deg) brightness(94%) contrast(81%)", "vertical"); 
            addSign(7, 4, 1,  SCHEME_ELEMENTS["semi_cruce"].imageSource, 90, [], "invert(59%) sepia(63%) saturate(439%) hue-rotate(66deg) brightness(94%) contrast(81%)");
            addSign(8, 4, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 0, [], "#000", "vertical"); 
            addSign(9, 4, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 0, [], "#000", "vertical"); 
            addSign(10, 4, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 0, [], "#000", "vertical"); 
            addSign(12, 4, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 0, [], "#000", "vertical"); 
            addSign(11, 4, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 0, [], "invert(59%) sepia(63%) saturate(439%) hue-rotate(66deg) brightness(94%) contrast(81%)", "vertical"); 
            addSign(13, 4, 1, SCHEME_ELEMENTS["semi_cruce"].imageSource, 90, [], "invert(59%) sepia(63%) saturate(439%) hue-rotate(66deg) brightness(94%) contrast(81%)", "vertical"); 
            addSign(15, 4, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 0, [], "#000", "vertical"); 
            addSign(14, 4, 1, SCHEME_ELEMENTS["cruce"].imageSource, 0, [], "invert(59%) sepia(63%) saturate(439%) hue-rotate(66deg) brightness(94%) contrast(81%)", "vertical"); 
            addSign(16, 4, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 0, [], "#000", "vertical"); 
            addSign(21, 4, 1, SCHEME_ELEMENTS["cruce"].imageSource, 0, [], "invert(59%) sepia(63%) saturate(439%) hue-rotate(66deg) brightness(94%) contrast(81%)", "vertical"); 
            addSign(18, 4, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 0, [], "#000", "vertical"); 
            addSign(19, 4, 1, SCHEME_ELEMENTS["semi_cruce"].imageSource, 90, [], "invert(59%) sepia(63%) saturate(439%) hue-rotate(66deg) brightness(94%) contrast(81%)", "vertical"); 
            addSign(17, 4, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 0, [], "#000", "vertical"); 
            addSign(20, 4, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 0, [], "#000", "vertical"); 
            addSign(22, 4, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 0, [], "#000", "vertical"); 
            addSign(23, 4, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 0, [], "#000", "vertical"); 
            addSign(24, 4, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 0, [], "#000", "vertical"); 
            addSign(25, 4, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 0, [], "#000", "vertical"); 
            addSign(26, 4, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 0, [], "#000", "vertical"); 
            addSign(27, 4, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 0, [], "#000", "vertical"); 

            addSign(3, 5, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 90);
            addSign(3, 6, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 90);
            addSign(3, 7, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 90);
            addSign(3, 8, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 90);
            addSign(3, 9, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 90);
            addSign(3, 10, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 90);
            addSign(3, 11, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 90);
            addSign(3, 12, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 90);
            addSign(3, 13, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 90);
            addSign(3, 14, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 90);
            addSign(3, 15, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 90);
            addSign(3, 16, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 90);

            addSign(3, 17, 1, SCHEME_ELEMENTS["semi_cruce"].imageSource, 0, [], "invert(59%) sepia(63%) saturate(439%) hue-rotate(66deg) brightness(94%) contrast(81%)");
            addSign(4, 17, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 0, [], "#000");
            addSign(3, 18, 1, SCHEME_ELEMENTS["colt_stanga_jos"].imageSource, 0, [], "invert(59%) sepia(63%) saturate(439%) hue-rotate(66deg) brightness(94%) contrast(81%)");
            addSign(4, 18, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 0);
            addSign(5, 18, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 0);
            addSign(6, 18, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 0);
            // addSign(7, 16, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 0);
            // addSign(8, 16, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 0);
            // addSign(9, 16, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 0);
            // addSign(10, 16, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 0);
            // addSign(11, 16, 1, SCHEME_ELEMENTS["colt_dreapta_jos"].imageSource, 0, [], "invert(59%) sepia(63%) saturate(439%) hue-rotate(66deg) brightness(94%) contrast(81%)");
            // addSign(11, 15, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 90, [], "#000");
            // addSign(11, 14, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 90, [], "#000");


            // addSign(3, 12, 1, SCHEME_ELEMENTS["colt_stanga_jos"].imageSource, 0, [], "invert(59%) sepia(63%) saturate(439%) hue-rotate(66deg) brightness(94%) contrast(81%)");
            // addSign(4, 12, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 0);
            // addSign(5, 12, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 0);
            // addSign(6, 12, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 0);
            // addSign(7, 12, 1, SCHEME_ELEMENTS["colt_dreapta_jos"].imageSource, 0, [], "invert(59%) sepia(63%) saturate(439%) hue-rotate(66deg) brightness(94%) contrast(81%)");
            // addSign(7, 11, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 90);
            // addSign(7, 10, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 90);
            // addSign(7, 9, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 90);
            // addSign(7, 8, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 90);
            // addSign(7, 7, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 90);
            // addSign(7, 6, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 90);
            // addSign(7, 5, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 90);
            // addSign(7, 4, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 90);

            // addSign(8, 5, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 90);
            // addSign(8, 6, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 90);
            // addSign(8, 7, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 90);
            // addSign(8, 8, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 90);
            // addSign(8, 9, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 90);
            // addSign(8, 10, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 90);
            // addSign(8, 11, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 90);
            // addSign(8, 12, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 90);
            // addSign(8, 13, 1, SCHEME_ELEMENTS["colt_stanga_jos"].imageSource, 0, [], "invert(59%) sepia(63%) saturate(439%) hue-rotate(66deg) brightness(94%) contrast(81%)");
            // addSign(9, 13, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 0);
            // addSign(10, 13, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 0);
            // addSign(11, 13, 1, SCHEME_ELEMENTS["colt_dreapta_jos"].imageSource, 0, [], "invert(59%) sepia(63%) saturate(439%) hue-rotate(66deg) brightness(94%) contrast(81%)");
            addSign(10, 6, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 0, [], "#000"); 
            addSign(9, 6, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 0, [], "#000"); 
            addSign(8, 6, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 0, [], "#000"); 
            addSign(7, 6, 1, SCHEME_ELEMENTS["colt_stanga_jos"].imageSource, 0, [], "invert(59%) sepia(63%) saturate(439%) hue-rotate(66deg) brightness(94%) contrast(81%)"); 
            addSign(7, 5, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 90, [], "#000"); 

            // addSign(6, 5, 1, SCHEME_ELEMENTS["semi_cruce"].imageSource, 90, [], "#000"); 

            addSign(11, 6, 1, SCHEME_ELEMENTS["colt_dreapta_sus"].imageSource, 0, [], "invert(59%) sepia(63%) saturate(439%) hue-rotate(66deg) brightness(94%) contrast(81%)"); 
            // addSign(11, 6, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 90, [], "#000", "vertical"); 
            addSign(11, 7, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 90, [], "#000", "vertical"); 
            addSign(11, 8, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 90, [], "#000", "vertical"); 
            addSign(11, 9, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 90);
            addSign(11, 10, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 90);
            addSign(11, 11, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 90);
            addSign(11, 12, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 90);
            addSign(11, 13, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 90);

            addSign(11, 14, 1, SCHEME_ELEMENTS["colt_dreapta_jos"].imageSource, 0, [], "invert(59%) sepia(63%) saturate(439%) hue-rotate(66deg) brightness(94%) contrast(81%)");
            addSign(10, 14, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 0, [], "#000");
            addSign(9, 14, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 0, [], "#000");
            addSign(8, 14, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 0, [], "#000");
            addSign(7, 14, 1, SCHEME_ELEMENTS["colt_stanga_sus"].imageSource, 0, [], "invert(59%) sepia(63%) saturate(439%) hue-rotate(66deg) brightness(94%) contrast(81%)");
            addSign(7, 15, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 90, [], "#000");
            addSign(7, 16, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 90, [], "#000");
            // addSign(7, 17, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 90, [], "#000");
            // addSign(7, 17, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 90, [], "#000");
            addSign(7, 17, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 90, [], "#000");

            addSign(7, 18, 1, SCHEME_ELEMENTS["colt_dreapta_jos"].imageSource, 0, [], "invert(59%) sepia(63%) saturate(439%) hue-rotate(66deg) brightness(94%) contrast(81%)");


            addSign(13, 5, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 90, [], "#000", "vertical"); 
            addSign(13, 6, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 90, [], "#000", "vertical"); 
            addSign(13, 7, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 90, [], "#000", "vertical"); 
            addSign(13, 8, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 90, [], "#000", "vertical"); 
            addSign(13, 9, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 90);
            addSign(13, 10, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 90);
            addSign(13, 11, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 90);
            addSign(13, 12, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 90);
            addSign(13, 13, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 90);
            addSign(13, 14, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 90);
            addSign(13, 15, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 90);
            addSign(13, 16, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 90);
            addSign(13, 17, 1, SCHEME_ELEMENTS["colt_stanga_jos"].imageSource, 0, [], "invert(59%) sepia(63%) saturate(439%) hue-rotate(66deg) brightness(94%) contrast(81%)", "vertical");
            addSign(14, 17, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 0);
            addSign(15, 17, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 0);
            addSign(16, 17, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 0);
            addSign(17, 17, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 0);
            addSign(18, 17, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 0);
            addSign(19, 17, 1, SCHEME_ELEMENTS["colt_dreapta_jos"].imageSource, 0, [], "invert(59%) sepia(63%) saturate(439%) hue-rotate(66deg) brightness(94%) contrast(81%)", "vertical");
            addSign(19, 16, 1, SCHEME_ELEMENTS["cruce"].imageSource, 0, [], "invert(59%) sepia(63%) saturate(439%) hue-rotate(66deg) brightness(94%) contrast(81%)", "vertical");
            addSign(19, 15, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 90);
            addSign(19, 14, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 90);
            addSign(19, 13, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 90);
            addSign(19, 12, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 90);
            addSign(19, 11, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 90);
            addSign(19, 10, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 90);
            addSign(19, 9, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 90);
            addSign(19, 8, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 90);
            addSign(19, 7, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 90);
            addSign(19, 6, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 90);
            addSign(19, 5, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 90);
            // addSign(19, 4, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 90);
            // addSign(12, 8, 1, SCHEME_ELEMENTS["colt_stanga_sus"].imageSource, 0, [], "invert(59%) sepia(63%) saturate(439%) hue-rotate(66deg) brightness(94%) contrast(81%)");
            // addSign(13, 8, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 0);
            // addSign(13, 8, 1, SCHEME_ELEMENTS["colt_dreapta_jos"].imageSource, 0, [], "invert(59%) sepia(63%) saturate(439%) hue-rotate(66deg) brightness(94%) contrast(81%)");
            // addSign(13, 7, 1, SCHEME_ELEMENTS["conductor_lung_protectie"].imageSource, 90, []);
            // addSign(13, 6, 1, SCHEME_ELEMENTS["colt_dreapta_sus"].imageSource, 0, [], "invert(59%) sepia(63%) saturate(439%) hue-rotate(66deg) brightness(94%) contrast(81%)");


            // addSign(12, 7, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 90);
            // addSign(12, 6, 1, SCHEME_ELEMENTS["colt_stanga_jos"].imageSource, 0, [], "invert(59%) sepia(63%) saturate(439%) hue-rotate(66deg) brightness(94%) contrast(81%)");
            // addSign(12, 5, 1, SCHEME_ELEMENTS["conductor_lung_protectie_invers"].imageSource, 90);


            changeIsSelectedSign(2, 2);
            setProjectName("Inversorul de sens");
        }

        console.log(signPage);
        
        // if (number?.toString() !== signPage?.toString()) {
        //     localStorage.setItem("signs", "[]");
        //     setSkipVerification(true);
        // }

        setFinished(true);

        
    }, [location.state]);

    useEffect(() => {

    }, []);

    const [changeSchemeNumber, setChangeSchemeNumber] = useState<number>(1);

    useEffect(() => {
        if (!projectName?.length) return;
        const currSelfMaintananceQ1 = findSignObjectByName("C.a. Q1");
        const currSelfMaintananceQ2 = findSignObjectByName("C.a. Q11.2");
        
        if (number === 1) {
            console.log("here");

            let partI = new Array(18).fill(0).map((_, idx) => {
                return {
                    dotNumber: idx + 1,
                    open: idx + 1 >= 11 && idx + 1 <= 18 ? true: false,
                    defect: null,
                    line: "Faza",
                    solved: false,
                    potential: 230,
                    screw: 1
                };
            });

            partI = partI.concat(new Array(12).fill(0).map((_, idx) => {
                return {
                    dotNumber: idx + 21,
                    open: false,
                    defect: null,
                    line: "Nul",
                    solved: false,
                    potential: 0,
                    screw: 1
                };
            }));

            console.log(partI);

            partI.push({
                dotNumber: 20,
                open: false,
                defect: null,
                line: "Nul",
                solved: false,
                potential: 0,
                screw: 1
            });

            partI.push({
                dotNumber: 19,
                open: false,
                defect: null,
                line: "Nul",
                solved: false,
                potential: 0,
                screw: 1
            });

            partI.push({
                dotNumber: 500,
                open: false,
                defect: null,
                line: "Nul protectie",
                solved: false,
                potential: 3,
                screw: 1
            });

            changeSchemeStructure(partI);
        } else if (number === 2) {
            const currSchemeStructure = [
                {
                    dotNumber: 1,
                    open: false,
                    defect: null,
                    line: "L1",
                    solved: false,
                    potential: 400,
                    screw: 1
                },

                {
                    dotNumber: 6,
                    open: false,
                    defect: null,
                    line: "L1",
                    solved: false,
                    potential: 400,
                    screw: 1,
                },


                {
                    dotNumber: 7,
                    open: false,
                    defect: null,
                    line: "L1",
                    solved: false,
                    potential: 400,
                    screw: 1,
                },

                {
                    dotNumber: 8,
                    open: false,
                    defect: null,
                    line: "L1",
                    solved: false,
                    potential: 400,
                    screw: 1,
                },

                {
                    dotNumber: 9,
                    open: false,
                    defect: null,
                    line: "L1",
                    solved: false,
                    potential: 400,
                    screw: 1,
                },

                {
                    dotNumber: 26,
                    open: false,
                    defect: null,
                    line: "L1",
                    solved: false,
                    potential: 400,
                    screw: 1,
                },
         
                {
                    dotNumber: 27,
                    open: false,
                    defect: null,
                    line: "L1",
                    solved: false,
                    potential: 400,
                    screw: 1,
                },

                {
                    dotNumber: 28,
                    open: false,
                    defect: null,
                    line: "L1",
                    solved: false,
                    potential: 400,
                    screw: 1,
                },

                {
                    dotNumber: 29,
                    open: false,
                    defect: null,
                    line: "L1",
                    solved: false,
                    potential: 400,
                    screw: 1,
                },
     

                {
                    dotNumber: 52,
                    open: false,
                    defect: null,
                    line: "L1",
                    solved: false,
                    potential: 400,
                    screw: 1,
                },

                {
                    dotNumber: 53,
                    open: false,
                    defect: null,
                    line: "L1",
                    solved: false,
                    potential: 400,
                    screw: 1,
                },
                
                {
                    dotNumber: 54,
                    open: false,
                    defect: null,
                    line: "L1",
                    solved: false,
                    potential: 400,
                    screw: 1,
                },

                {
                    dotNumber: 55,
                    open: false,
                    defect: null,
                    line: "L1",
                    solved: false,
                    potential: 400,
                    screw: 1,
                },

                {
                    dotNumber: 70,
                    open: false,
                    defect: null,
                    line: "L1",
                    solved: false,
                    potential: 400,
                    screw: 1,
                },

                {
                    dotNumber: 71,
                    open: false,
                    defect: null,
                    line: "L1",
                    solved: false,
                    potential: 400,
                    screw: 1,
                },

                {
                    dotNumber: 2,
                    open: false,
                    defect: null,
                    line: "L2",
                    solved: false,
                    potential: 401,
                    screw: 1
                },

                {
                    dotNumber: 10,
                    open: false,
                    defect: null,
                    line: "L2",
                    solved: false,
                    potential: 401,
                    screw: 1,
                },

                {
                    dotNumber: 11,
                    open: false,
                    defect: null,
                    line: "L2",
                    solved: false,
                    potential: 401,
                    screw: 1,
                },

                {
                    dotNumber: 12,
                    open: false,
                    defect: null,
                    line: "L2",
                    solved: false,
                    potential: 401,
                    screw: 1,
                },

                {
                    dotNumber: 13,
                    open: false,
                    defect: null,
                    line: "L2",
                    solved: false,
                    potential: 401,
                    screw: 1,
                },

                {
                    dotNumber: 30,
                    open: false,
                    defect: null,
                    line: "L2",
                    solved: false,
                    potential: 401,
                    screw: 1,
                },
       

                {
                    dotNumber: 31,
                    open: false,
                    defect: null,
                    line: "L2",
                    solved: false,
                    potential: 401,
                    screw: 1,
                },

                {
                    dotNumber: 32,
                    open: false,
                    defect: null,
                    line: "L2",
                    solved: false,
                    potential: 401,
                    screw: 1,
                },

                {
                    dotNumber: 33,
                    open: false,
                    defect: null,
                    line: "L2",
                    solved: false,
                    potential: 401,
                    screw: 1,
                },
          

                {
                    dotNumber: 56,
                    open: false,
                    defect: null,
                    line: "L2",
                    solved: false,
                    potential: 401,
                    screw: 1,
                },

                {
                    dotNumber: 57,
                    open: false,
                    defect: null,
                    line: "L2",
                    solved: false,
                    potential: 401,
                    screw: 1,
                },

                {
                    dotNumber: 58,
                    open: false,
                    defect: null,
                    line: "L2",
                    solved: false,
                    potential: 401,
                    screw: 1,
                },
                
                {
                    dotNumber: 59,
                    open: false,
                    defect: null,
                    line: "L2",
                    solved: false,
                    potential: 401,
                    screw: 1,
                },

                {
                    dotNumber: 68,
                    open: false,
                    defect: null,
                    line: "L2",
                    solved: false,
                    potential: 401,
                    screw: 1,
                },

                {
                    dotNumber: 69,
                    open: false,
                    defect: null,
                    line: "L2",
                    solved: false,
                    potential: 401,
                    screw: 1,
                },

                {
                    dotNumber: 3,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 14,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 15,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 16,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 17,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 34,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 35,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 36,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 37,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 60,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 61,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 62,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 63,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 72,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 73,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },


                {
                    dotNumber: 5,
                    open: false,
                    defect: null,
                    line: "N",
                    solved: false,
                    potential: 170,
                    screw: 1,
                },

                {
                    dotNumber: 18,
                    open: false,
                    defect: null,
                    line: "N",
                    solved: false,
                    potential: 170,
                    screw: 1,
                },

                {
                    dotNumber: 19,
                    open: false,
                    defect: null,
                    line: "N",
                    solved: false,
                    potential: 170,
                    screw: 1,
                },

                {
                    dotNumber: 20,
                    open: false,
                    defect: null,
                    line: "N",
                    solved: false,
                    potential: 170,
                    screw: 1,
                },

                {
                    dotNumber: 21,
                    open: false,
                    defect: null,
                    line: "N",
                    solved: false,
                    potential: 170,
                    screw: 1,
                },

                {
                    dotNumber: 38,
                    open: false,
                    defect: null,
                    line: "N",
                    solved: false,
                    potential: 170,
                    screw: 1,
                },

                {
                    dotNumber: 39,
                    open: false,
                    defect: null,
                    line: "N",
                    solved: false,
                    potential: 170,
                    screw: 1,
                },

                {
                    dotNumber: 40,
                    open: false,
                    defect: null,
                    line: "N",
                    solved: false,
                    potential: 170,
                    screw: 1,
                },

                {
                    dotNumber: 41,
                    open: false,
                    defect: null,
                    line: "N",
                    solved: false,
                    potential: 170,
                    screw: 1,
                },

                {
                    dotNumber: 77,
                    open: false,
                    defect: null,
                    line: "N",
                    solved: false,
                    potential: 170,
                    screw: 1,
                },

                {
                    dotNumber: 76,
                    open: false,
                    defect: null,
                    line: "N",
                    solved: false,
                    potential: 170,
                    screw: 1,
                },

     
                ////

                {
                    dotNumber: 4,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },


                {
                    dotNumber: 22,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 23,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 24,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 25,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 42,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 43,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 46,
                    open: false,
                    defect: null,
                    line: "L3(1)",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },


                {
                    dotNumber: 50,
                    open: false,
                    defect: null,
                    line: "L3(1)",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 48,
                    open: false,
                    defect: null,
                    line: "L3(1)",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 49,
                    open: false,
                    defect: null,
                    line: "L3(1)",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 51,
                    open: false,
                    defect: null,
                    line: "L3(1)",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 47,
                    open: false,
                    defect: null,
                    line: "L3(1)",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },


                {
                    dotNumber: 44,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 45,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 64,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 65,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },


                {
                    dotNumber: 66,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 67,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 74,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 75,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },


                {
                    dotNumber: 500,
                    open: false,
                    defect: null,
                    line: "Nul protectie",
                    solved: false,
                    potential: 173,
                    screw: 1,
                },
               
            ];
            
            changeSchemeStructure(currSchemeStructure);

        } else if (Number(schemeNumber) === 3) {
            
            const currSchemeStructure = changeSchemeNumber === 0 ? [
                {
                    dotNumber: 1,
                    open: false,
                    defect: null,
                    line: "L1",
                    solved: false,
                    potential: 400,
                    screw: 1
                },

                {
                    dotNumber: 6,
                    open: false,
                    defect: null,
                    line: "L1",
                    solved: false,
                    potential: 400,
                    screw: 1,
                },


                {
                    dotNumber: 7,
                    open: false,
                    defect: null,
                    line: "L1",
                    solved: false,
                    potential: 400,
                    screw: 1,
                },

                {
                    dotNumber: 8,
                    open: false,
                    defect: null,
                    line: "L1",
                    solved: false,
                    potential: 400,
                    screw: 1,
                },

                {
                    dotNumber: 9,
                    open: false,
                    defect: null,
                    line: "L1",
                    solved: false,
                    potential: 400,
                    screw: 1,
                },

                {
                    dotNumber: 50,
                    open: false,
                    defect: null,
                    line: "L1(1)",
                    solved: false,
                    potential: 400,
                    screw: 1
                },

                {
                    dotNumber: 51,
                    open: false,
                    defect: null,
                    line: "L1(1)",
                    solved: false,
                    potential: 400,
                    screw: 1
                },

                {
                    dotNumber: 30,
                    open: false,
                    defect: null,
                    line: "L1",
                    solved: false,
                    potential: 400,
                    screw: 1,
                },
         
                {
                    dotNumber: 31,
                    open: false,
                    defect: null,
                    line: "L1",
                    solved: false,
                    potential: 400,
                    screw: 1,
                },

                {
                    dotNumber: 32,
                    open: false,
                    defect: null,
                    line: "L1",
                    solved: false,
                    potential: 400,
                    screw: 1,
                },

                {
                    dotNumber: 33,
                    open: false,
                    defect: null,
                    line: "L1",
                    solved: false,
                    potential: 400,
                    screw: 1,
                },

                {
                    dotNumber: 45,
                    open: false,
                    defect: null,
                    line: "L1(1)",
                    solved: false,
                    potential: 400,
                    screw: 1
                },
                
                {
                    dotNumber: 44,
                    open: false,
                    defect: null,
                    line: "L1(1)",
                    solved: false,
                    potential: 400,
                    screw: 1
                },

                {
                    dotNumber: 82,
                    open: false,
                    defect: null,
                    line: "L1",
                    solved: false,
                    potential: 400,
                    screw: 1,
                },
         
                {
                    dotNumber: 83,
                    open: false,
                    defect: null,
                    line: "L1",
                    solved: false,
                    potential: 400,
                    screw: 1,
                },

                {
                    dotNumber: 84,
                    open: false,
                    defect: null,
                    line: "L1",
                    solved: false,
                    potential: 400,
                    screw: 1,
                },

                {
                    dotNumber: 85,
                    open: false,
                    defect: null,
                    line: "L1",
                    solved: false,
                    potential: 400,
                    screw: 1,
                },

                {
                    dotNumber: 104,
                    open: false,
                    defect: null,
                    line: "L1",
                    solved: false,
                    potential: 400,
                    screw: 1,
                },
         
                {
                    dotNumber: 105,
                    open: false,
                    defect: null,
                    line: "L1",
                    solved: false,
                    potential: 400,
                    screw: 1,
                },

                {
                    dotNumber: 2,
                    open: false,
                    defect: null,
                    line: "L2",
                    solved: false,
                    potential: 401,
                    screw: 1,
                },

                {
                    dotNumber: 10,
                    open: false,
                    defect: null,
                    line: "L2",
                    solved: false,
                    potential: 401,
                    screw: 1,
                },
         
                {
                    dotNumber: 11,
                    open: false,
                    defect: null,
                    line: "L2",
                    solved: false,
                    potential: 401,
                    screw: 1,
                },

                {
                    dotNumber: 12,
                    open: false,
                    defect: null,
                    line: "L2",
                    solved: false,
                    potential: 401,
                    screw: 1,
                },

                {
                    dotNumber: 13,
                    open: false,
                    defect: null,
                    line: "L2",
                    solved: false,
                    potential: 401,
                    screw: 1,
                },

                {
                    dotNumber: 46,
                    open: false,
                    defect: null,
                    line: "L2(1)",
                    solved: false,
                    potential: 401,
                    screw: 1
                },

                {
                    dotNumber: 47,
                    open: false,
                    defect: null,
                    line: "L2(1)",
                    solved: false,
                    potential: 401,
                    screw: 1
                },

                {
                    dotNumber: 34,
                    open: false,
                    defect: null,
                    line: "L2",
                    solved: false,
                    potential: 401,
                    screw: 1,
                },
         
                {
                    dotNumber: 35,
                    open: false,
                    defect: null,
                    line: "L2",
                    solved: false,
                    potential: 401,
                    screw: 1,
                },

                {
                    dotNumber: 36,
                    open: false,
                    defect: null,
                    line: "L2",
                    solved: false,
                    potential: 401,
                    screw: 1,
                },

                {
                    dotNumber: 37,
                    open: false,
                    defect: null,
                    line: "L2",
                    solved: false,
                    potential: 401,
                    screw: 1,
                },

                {
                    dotNumber: 48,
                    open: false,
                    defect: null,
                    line: "L2(1)",
                    solved: false,
                    potential: 401,
                    screw: 1
                },

                {
                    dotNumber: 49,
                    open: false,
                    defect: null,
                    line: "L2(1)",
                    solved: false,
                    potential: 401,
                    screw: 1
                },

                {
                    dotNumber: 86,
                    open: false,
                    defect: null,
                    line: "L2",
                    solved: false,
                    potential: 401,
                    screw: 1,
                },
         
                {
                    dotNumber: 87,
                    open: false,
                    defect: null,
                    line: "L2",
                    solved: false,
                    potential: 401,
                    screw: 1,
                },

                {
                    dotNumber: 88,
                    open: false,
                    defect: null,
                    line: "L2",
                    solved: false,
                    potential: 401,
                    screw: 1,
                },

                {
                    dotNumber: 89,
                    open: false,
                    defect: null,
                    line: "L2",
                    solved: false,
                    potential: 401,
                    screw: 1,
                },


                {
                    dotNumber: 102,
                    open: false,
                    defect: null,
                    line: "L2",
                    solved: false,
                    potential: 401,
                    screw: 1,
                },

                {
                    dotNumber: 103,
                    open: false,
                    defect: null,
                    line: "L2",
                    solved: false,
                    potential: 401,
                    screw: 1,
                },

                {
                    dotNumber: 3,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

               
                {
                    dotNumber: 14,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },


                {
                    dotNumber: 15,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },


                {
                    dotNumber: 16,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },


                {
                    dotNumber: 17,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 42,
                    open: false,
                    defect: null,
                    line: "L3(1)",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 43,
                    open: false,
                    defect: null,
                    line: "L3(1)",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 38,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },


                {
                    dotNumber: 39,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },


                {
                    dotNumber: 40,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },


                {
                    dotNumber: 41,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 53,
                    open: false,
                    defect: null,
                    line: "L3(1)",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 52,
                    open: false,
                    defect: null,
                    line: "L3(1)",
                    solved: false,
                    potential: 402,
                    screw: 1,     
                },

                {
                    dotNumber: 90,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },


                {
                    dotNumber: 91,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },


                {
                    dotNumber: 92,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },


                {
                    dotNumber: 93,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 106,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },


                {
                    dotNumber: 107,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 5,
                    open: false,
                    defect: null,
                    line: "N",
                    solved: false,
                    potential: 170,
                    screw: 1
                },

                {
                    dotNumber: 97,
                    open: false,
                    defect: null,
                    line: "N(1)",
                    solved: false,
                    potential: 170,
                    screw: 1
                },

                {
                    dotNumber: 96,
                    open: false,
                    defect: null,
                    line: "N(1)",
                    solved: false,
                    potential: 170,
                    screw: 1,
                },

                {
                    dotNumber: 101,
                    open: false,
                    defect: null,
                    line: "N",
                    solved: false,
                    potential: 170,
                    screw: 1
                },

                {
                    dotNumber: 100,
                    open: false,
                    defect: null,
                    line: "N",
                    solved: false,
                    potential: 170,
                    screw: 1
                },

                {
                    dotNumber: 4,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1
                },

                {
                    dotNumber: 18,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1
                },

                {
                    dotNumber: 19,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1
                },

                {
                    dotNumber: 20,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1
                },

                {
                    dotNumber: 21,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1
                },

                {
                    dotNumber: 22,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1
                },

                {
                    dotNumber: 23,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1
                },

                {
                    dotNumber: 24,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1
                },

                {
                    dotNumber: 25,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1
                },

                {
                    dotNumber: 26,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1
                },

                {
                    dotNumber: 27,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1
                },

                {
                    dotNumber: 28,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1
                },

                {
                    dotNumber: 29,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1
                },

                {
                    dotNumber: 54,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 55,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 58,
                    open: false,
                    defect: null,
                    line: "L3(1)",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 62,
                    open: false,
                    defect: null,
                    line: "L3(1)",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 60,
                    open: false,
                    defect: null,
                    line: "L3(1)",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },


                {
                    dotNumber: 61,
                    open: false,
                    defect: null,
                    line: "L3(1)",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 63,
                    open: false,
                    defect: null,
                    line: "L3(1)",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 59,
                    open: false,
                    defect: null,
                    line: "L3(1)",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 56,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },


                {
                    dotNumber: 57,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },


                {
                    dotNumber: 74,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },


                {
                    dotNumber: 75,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },


                {
                    dotNumber: 76,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },


                {
                    dotNumber: 77,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },


                {
                    dotNumber: 94,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },


                {
                    dotNumber: 95,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },


                {
                    dotNumber: 500,
                    open: false,
                    defect: null,
                    line: "Nul protectie",
                    solved: false,
                    potential: 173,
                    screw: 1,
                },


                {
                    dotNumber: 64,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 65,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 68,
                    open: false,
                    defect: null,
                    line: "L3(1)",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 72,
                    open: false,
                    defect: null,
                    line: "L3(1)",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },
              
                {
                    dotNumber: 70,
                    open: false,
                    defect: null,
                    line: "L3(1)",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 71,
                    open: false,
                    defect: null,
                    line: "L3(1)",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 73,
                    open: false,
                    defect: null,
                    line: "L3(1)",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 69,
                    open: false,
                    defect: null,
                    line: "L3(1)",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 66,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 67,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },


                {
                    dotNumber: 78,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 79,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },


                {
                    dotNumber: 80,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 81,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 98,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 99,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },
                
            ] : [
                {
                    dotNumber: 1,
                    open: false,
                    defect: null,
                    line: "L1",
                    solved: false,
                    potential: 400,
                    screw: 1
                },

                {
                    dotNumber: 6,
                    open: false,
                    defect: null,
                    line: "L1",
                    solved: false,
                    potential: 400,
                    screw: 1,
                },


                {
                    dotNumber: 7,
                    open: false,
                    defect: null,
                    line: "L1",
                    solved: false,
                    potential: 400,
                    screw: 1,
                },

                {
                    dotNumber: 8,
                    open: false,
                    defect: null,
                    line: "L1",
                    solved: false,
                    potential: 400,
                    screw: 1,
                },

                {
                    dotNumber: 9,
                    open: false,
                    defect: null,
                    line: "L1",
                    solved: false,
                    potential: 400,
                    screw: 1,
                },

                {
                    dotNumber: 50,
                    open: false,
                    defect: null,
                    line: "L1",
                    solved: false,
                    potential: 400,
                    screw: 1
                },

                {
                    dotNumber: 51,
                    open: false,
                    defect: null,
                    line: "L1",
                    solved: false,
                    potential: 400,
                    screw: 1
                },


                {
                    dotNumber: 52,
                    open: false,
                    defect: null,
                    line: "L1",
                    solved: false,
                    potential: 400,
                    screw: 1
                },

                {
                    dotNumber: 53,
                    open: false,
                    defect: null,
                    line: "L1",
                    solved: false,
                    potential: 400,
                    screw: 1
                },

                {
                    dotNumber: 41,
                    open: false,
                    defect: null,
                    line: "L1(1)",
                    solved: false,
                    potential: 400,
                    screw: 1
                },

                {
                    dotNumber: 40,
                    open: false,
                    defect: null,
                    line: "L1(1)",
                    solved: false,
                    potential: 400,
                    screw: 1
                },

                {
                    dotNumber: 90,
                    open: false,
                    defect: null,
                    line: "L1",
                    solved: false,
                    potential: 400,
                    screw: 1
                },

                {
                    dotNumber: 91,
                    open: false,
                    defect: null,
                    line: "L1",
                    solved: false,
                    potential: 400,
                    screw: 1
                },


                {
                    dotNumber: 92,
                    open: false,
                    defect: null,
                    line: "L1",
                    solved: false,
                    potential: 400,
                    screw: 1
                },

                {
                    dotNumber: 93,
                    open: false,
                    defect: null,
                    line: "L1",
                    solved: false,
                    potential: 400,
                    screw: 1
                },


                {
                    dotNumber: 106,
                    open: false,
                    defect: null,
                    line: "L1",
                    solved: false,
                    potential: 400,
                    screw: 1
                },


                {
                    dotNumber: 107,
                    open: false,
                    defect: null,
                    line: "L1",
                    solved: false,
                    potential: 400,
                    screw: 1
                },


                {
                    dotNumber: 30,
                    open: false,
                    defect: null,
                    line: "L1(1)",
                    solved: false,
                    potential: 400,
                    screw: 1,
                },
         
                {
                    dotNumber: 31,
                    open: false,
                    defect: null,
                    line: "L1(1)",
                    solved: false,
                    potential: 400,
                    screw: 1,
                },

                {
                    dotNumber: 2,
                    open: false,
                    defect: null,
                    line: "L2",
                    solved: false,
                    potential: 401,
                    screw: 1,
                },

                {
                    dotNumber: 10,
                    open: false,
                    defect: null,
                    line: "L2",
                    solved: false,
                    potential: 401,
                    screw: 1,
                },
         
                {
                    dotNumber: 11,
                    open: false,
                    defect: null,
                    line: "L2",
                    solved: false,
                    potential: 401,
                    screw: 1,
                },

                {
                    dotNumber: 12,
                    open: false,
                    defect: null,
                    line: "L2",
                    solved: false,
                    potential: 401,
                    screw: 1,
                },

                {
                    dotNumber: 13,
                    open: false,
                    defect: null,
                    line: "L2",
                    solved: false,
                    potential: 401,
                    screw: 1,
                },

                {
                    dotNumber: 34,
                    open: false,
                    defect: null,
                    line: "L2(1)",
                    solved: false,
                    potential: 401,
                    screw: 1,
                },

                {
                    dotNumber: 35,
                    open: false,
                    defect: null,
                    line: "L2(1)",
                    solved: false,
                    potential: 401,
                    screw: 1,
                },

                {
                    dotNumber: 46,
                    open: false,
                    defect: null,
                    line: "L2",
                    solved: false,
                    potential: 401,
                    screw: 1
                },

                {
                    dotNumber: 47,
                    open: false,
                    defect: null,
                    line: "L2",
                    solved: false,
                    potential: 401,
                    screw: 1
                },

                {
                    dotNumber: 48,
                    open: false,
                    defect: null,
                    line: "L2",
                    solved: false,
                    potential: 401,
                    screw: 1
                },

                {
                    dotNumber: 49,
                    open: false,
                    defect: null,
                    line: "L2",
                    solved: false,
                    potential: 401,
                    screw: 1
                },


                {
                    dotNumber: 36,
                    open: false,
                    defect: null,
                    line: "L2(1)",
                    solved: false,
                    potential: 401,
                    screw: 1
                },

                {
                    dotNumber: 37,
                    open: false,
                    defect: null,
                    line: "L2(1)",
                    solved: false,
                    potential: 401,
                    screw: 1
                },
         
                {
                    dotNumber: 86,
                    open: false,
                    defect: null,
                    line: "L2",
                    solved: false,
                    potential: 401,
                    screw: 1,
                },

                {
                    dotNumber: 87,
                    open: false,
                    defect: null,
                    line: "L2",
                    solved: false,
                    potential: 401,
                    screw: 1,
                },

                {
                    dotNumber: 88,
                    open: false,
                    defect: null,
                    line: "L2",
                    solved: false,
                    potential: 401,
                    screw: 1,
                },

                {
                    dotNumber: 89,
                    open: false,
                    defect: null,
                    line: "L2",
                    solved: false,
                    potential: 401,
                    screw: 1
                },

                {
                    dotNumber: 90,
                    open: false,
                    defect: null,
                    line: "L2",
                    solved: false,
                    potential: 401,
                    screw: 1
                },

                {
                    dotNumber: 102,
                    open: false,
                    defect: null,
                    line: "L2",
                    solved: false,
                    potential: 401,
                    screw: 1,
                },

                {
                    dotNumber: 103,
                    open: false,
                    defect: null,
                    line: "L2",
                    solved: false,
                    potential: 401,
                    screw: 1,
                },

                {
                    dotNumber: 3,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

               
                {
                    dotNumber: 14,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },


                {
                    dotNumber: 15,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },


                {
                    dotNumber: 16,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },


                {
                    dotNumber: 17,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 38,
                    open: false,
                    defect: null,
                    line: "L3(1)",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },


                {
                    dotNumber: 39,
                    open: false,
                    defect: null,
                    line: "L3(1)",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 42,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 43,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 44,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 45,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 33,
                    open: false,
                    defect: null,
                    line: "L3(1)",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },


                {
                    dotNumber: 32,
                    open: false,
                    defect: null,
                    line: "L3(1)",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 82,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 83,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 84,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 85,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 104,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 105,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },


            
                {
                    dotNumber: 5,
                    open: false,
                    defect: null,
                    line: "N",
                    solved: false,
                    potential: 170,
                    screw: 1
                },

                {
                    dotNumber: 97,
                    open: false,
                    defect: null,
                    line: "N(1)",
                    solved: false,
                    potential: 170,
                    screw: 1
                },

                {
                    dotNumber: 96,
                    open: false,
                    defect: null,
                    line: "N(1)",
                    solved: false,
                    potential: 170,
                    screw: 1,
                },

                {
                    dotNumber: 101,
                    open: false,
                    defect: null,
                    line: "N",
                    solved: false,
                    potential: 170,
                    screw: 1
                },

                {
                    dotNumber: 100,
                    open: false,
                    defect: null,
                    line: "N",
                    solved: false,
                    potential: 170,
                    screw: 1
                },

                {
                    dotNumber: 4,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1
                },

                {
                    dotNumber: 18,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1
                },

                {
                    dotNumber: 19,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1
                },

                {
                    dotNumber: 20,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1
                },

                {
                    dotNumber: 21,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1
                },

                {
                    dotNumber: 22,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1
                },

                {
                    dotNumber: 23,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1
                },

                {
                    dotNumber: 24,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1
                },

                {
                    dotNumber: 25,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1
                },

                {
                    dotNumber: 26,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1
                },

                {
                    dotNumber: 27,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1
                },

                {
                    dotNumber: 28,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1
                },

                {
                    dotNumber: 29,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1
                },

                {
                    dotNumber: 64,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 65,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 68,
                    open: false,
                    defect: null,
                    line: "L3(1)",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 72,
                    open: false,
                    defect: null,
                    line: "L3(1)",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },
              
                {
                    dotNumber: 70,
                    open: false,
                    defect: null,
                    line: "L3(1)",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 71,
                    open: false,
                    defect: null,
                    line: "L3(1)",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 73,
                    open: false,
                    defect: null,
                    line: "L3(1)",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 69,
                    open: false,
                    defect: null,
                    line: "L3(1)",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 66,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 67,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },


                {
                    dotNumber: 78,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 79,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },


                {
                    dotNumber: 80,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 81,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 98,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 99,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },


                {
                    dotNumber: 500,
                    open: false,
                    defect: null,
                    line: "Nul protectie",
                    solved: false,
                    potential: 173,
                    screw: 1,
                },


                {
                    dotNumber: 54,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 55,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 58,
                    open: false,
                    defect: null,
                    line: "L3(1)",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 62,
                    open: false,
                    defect: null,
                    line: "L3(1)",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 60,
                    open: false,
                    defect: null,
                    line: "L3(1)",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },


                {
                    dotNumber: 61,
                    open: false,
                    defect: null,
                    line: "L3(1)",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 63,
                    open: false,
                    defect: null,
                    line: "L3(1)",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 59,
                    open: false,
                    defect: null,
                    line: "L3(1)",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },

                {
                    dotNumber: 56,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },


                {
                    dotNumber: 57,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },


                {
                    dotNumber: 74,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },


                {
                    dotNumber: 75,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },


                {
                    dotNumber: 76,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },


                {
                    dotNumber: 77,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },


                {
                    dotNumber: 94,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },


                {
                    dotNumber: 95,
                    open: false,
                    defect: null,
                    line: "L3",
                    solved: false,
                    potential: 402,
                    screw: 1,
                },
            ];

            changeSchemeStructure(currSchemeStructure.map((element: any) => {
                const currentElement = schemeStructure?.find((currElement : any) => currElement.dotNumber.toString() === element.dotNumber.toString() && currElement.defect !== null);
                if (currentElement?.defect) return {...element, defect: currentElement?.defect, solved: currentElement?.solved, screw: currentElement?.screw };
                if (currentElement) return {...element, screw: currentElement?.screw};
                return element;
            }));
     
        }

        console.log(changeSchemeNumber);

        // setFinished(true);


    }, [projectName, changeSchemeNumber]);


    useEffect(() => {
        if (!finished) return;
        // setTimeout(() => document.querySelectorAll(".cell").forEach(cell => cell.classList.remove("selected")), 1500);
    }, [finished]);

    useEffect(() => {
        if (skipVerification) return;
            const currSigns = JSON.parse(localStorage.getItem("signs") || "[]");
            console.log(signs);
            // if (localStorage.getItem("sign-page")?.toString() !== schemeNumber?.toString()) return;
            if (!currSigns.length) return;
            console.log(currSigns);
            reset();
            
            changeGrid();
            imageLocking();

            currSigns.forEach((sign : { x: number, y: number, flip:number, names: Array<any>, texts: any, page: number, signColor: string, signPosition: string }) => {
                addSign(sign.x, sign.y, sign?.page || 1, sign.names, sign.flip, sign.texts, sign?.signColor || "#000", sign?.signPosition || "horizontal");
            });

            changeIsSelectedSign(2, 2);
            setProjectName(number === 1 ? "Circuit simplu" : number === 2 ? "Pornirea într-un singur sens a motorului asincron trifazat" : "Inversorul de sens a motorului asincron trifazat");
            const timer = setTimeout(() => {
                setFinished(true);
            }, 0);

       
          
        return () => {
            clearTimeout(timer);
        };
        
    }, [reset]);

    
    
    useEffect(() => {
        if (!projectName.length) return;
        console.log(schemeStructure);
        if (!schemeStructure.length) {
            setProjectName(projectName + "1");
            // setProjectName(projectName.slice(-1));

        }
    }, [schemeStructure]);

    const isBranchLine = (line1 : string, line2 : string) : boolean => {
        if ((line1.includes(`${line2}(`) && line1.at(-1) === ")") || (line2.includes(`${line1}(`) && line2.at(-1) === ")")) return true;
        return false;
    };
    const findSignObjectByName = (name : string) => {
        return signs.find((sign : any) => sign.texts.some((text: { name: string }) => text.name === name));
    };
    
    const selfMaintananceQ1 = useMemo(() => findSignObjectByName("C.i. Q11.1"), [signs]);
    const selfMaintananceQ2 = useMemo(() => findSignObjectByName("C.i. Q11.2"), [signs]);


    useEffect(() => {
        (async () => {
            if (!finished || !projectName?.length) return;
            console.log(projectName);
            // if (!signs.length) window.location.reload();
            let numberOfFusesOpen = 0; // Sigurante
            signs.forEach((sign : { names: Array<any>, texts: any }) => {
                const isFuse = sign.texts.some((text : { name: string }) => text.name === "F1" || text.name === "F2" || text.name === "F3");
                if (isFuse && sign.names?.some((name : { name: string }) => name.name.includes("deschisa"))) numberOfFusesOpen++;
            });


            const shortCircuitOnLine = number === 1 ? schemeStructure.some((element: { dotNumber: number, defect: string | null }) => element.dotNumber >= 3 && element.dotNumber <= 10 && element.defect === "Scurt circuit") : false;
    
            // const isOpenSign = signs.some((sign: { name: string, texts: any }) => {
            //     const isFuse = sign.texts.find((text : { name: string }) => text.name === "F1" || text.name === "F2" || text.name === "F3");
    
            //     if (number === 2) {
            //         if (sign.texts.some((text : { name: string }) => text.name === "B.o." || text.name === "B.p.")) return false;
            //         if (numberOfFusesOpen < 3 && isFuse) return false;
            //     }
                
            //     return isFuse === "F2" && shortCircuitOnLine ? false : sign.name.includes("deschis");
            // });

            const dotsList = number === 2 ? [20, 21, 38, 39, 40, 41, 77, 76] : number === 3 ? [] : [];
            let reverseSpin = false;
            const isOpenSign = signs.some((sign: { names: Array<any>, texts: any, x: number, y: number }) => {
            
                console.log(number);
                if (number !== 1) {
    
                    if (sign.names?.some((name : { name: string }) => name.name.includes("apasare"))) return false;
                    const isFuse = sign.texts.some((text : { name: string }) => text.name === "F1" || text.name === "F2" || text.name === "F3");
                    if (numberOfFusesOpen < 3 && isFuse) return false;
                    const isContact = sign.texts.some((text : { name: string }) => text.name === "Q11.1" || text.name === "Q11.2");
                    if (sign.texts.some((text: { name: string }) => text.name === "F4") && sign.names?.some((name : { name: string }) => name.name.includes("deschis")) && schemeStructure.some((element : { dotNumber: number, defect: string }) => dotsList.includes(element.dotNumber) && element.defect === "Scurt circuit")) return false;
                    
                    if (number === 3) {
                        if (sign.texts.some((text : { name: string }) => text.name === "3" || text.name === "4" || text.name === "5" || text.name === "6" || text.name === "9" || text.name === "10" || text.name === "11" || text.name === "12")) return false;
                        if (sign.texts.some((text : { name: string }) => text.name === "C.i. Q11.1" || text.name === "C.i. Q11.2")) return false;
                        const selfMaintananceQ1 = findSignObjectByName("C.a. Q1");
                        const selfMaintananceQ2 = findSignObjectByName("C.a. Q11.2");
                        if (sign.texts.some((text: { name: string }) => text.name === "C.a. Q1" || text.name === "C.a. Q11.2") && (selfMaintananceQ1.names?.some((name : { name: string }) => name.name.includes("inchis")) || selfMaintananceQ2.names?.some((name : { name: string }) => name.name.includes("inchis")))) return false; 
                        if (isContact) {
                            const normalContact = findSignObjectByName("Q11.1");
                            const reverseContact = findSignObjectByName("Q11.2");
                            console.log(normalContact, reverseContact);
                            if (normalContact.names?.some((name : { name: string }) => name.name.includes("inchis"))) {
                                return false;
                            }
    
                            else if (reverseContact.names?.some((name : { name: string }) => name.name.includes("inchis"))) {
                                console.warn(normalContact, reverseContact);
                                reverseSpin = true;
                                return false;
                            }
                        }
                    }
                }
                if (sign.names?.some((name : { name: string }) => name.name.includes("deschis"))) console.log(sign);
                return sign.names?.some((name : { name: string }) => name.name.includes("deschis"));
            });

            console.log(reverseSpin);
    
            let firstIdx : number = -1, secondIdx : number = -1, thirdIdx : number = -1, fourthIdx : number = -1, fifthIdx : number = -1;
            let firstPotential : number = 0, secondPotential : number = 0, thirdPotential : number = 0;
            
            schemeStructure.forEach((element: { dotNumber: number, potential: number, line: string }, idx: number) => {
                if (Number(schemeNumber) === 1) {
                    if (element.line === "Faza" && firstIdx === -1) {
                        firstIdx = element.dotNumber;
                        firstPotential = element.potential;
                    }
                    else if (element.line === "Nul" && secondIdx === -1) {
                        secondIdx = element.dotNumber;
                        secondPotential = element.potential;
                    }
                } else {
                    if (element.line === "L1" && firstIdx === -1) {
                        firstIdx = element.dotNumber;
                        firstPotential = element.potential;
                    }
                    else if (element.line === "L2" && secondIdx === -1) {
                        secondIdx = element.dotNumber;
                        secondPotential = element.potential;
                    } else if (element.line === "L3" && thirdIdx === -1) {
                        thirdIdx = element.dotNumber;
                        thirdPotential = element.potential;
                    } else if (element.line === "N" && fourthIdx === -1) {
                        fourthIdx = element.dotNumber;
                    } else if (element.line === "L3" && fourthIdx !== -1 && fifthIdx === -1) {
                        fifthIdx = element.dotNumber;
                    }
                }
            });

            console.log(fifthIdx);
            let problem : boolean = false;
            let currStart : boolean = false, line : string | null = null;
            for (let idx = 0; idx < schemeStructure.length; idx++) {
                if (schemeStructure[idx].dotNumber === firstIdx) {
                    currStart = true;
                    line = schemeStructure[idx].line;
                }
                if (line && isBranchLine(line, schemeStructure[idx].line)) continue;
                if (line !== null && line !== schemeStructure[idx].line && !isBranchLine(line, schemeStructure[idx].line)) currStart = false;
              
                if (currStart && (schemeStructure[idx].open || schemeStructure[idx].screw < 1 || (schemeStructure[idx].defect === "Lipsa tensiune" && !schemeStructure[idx].solved))) problem = true;
            }
    
            currStart = false;
            line = null;
    
            for (let idx = 0; idx < schemeStructure.length; idx++) {

                if (schemeStructure[idx].dotNumber === secondIdx) {
                    currStart = true;
                    line = schemeStructure[idx].line;
                }

                if (line && isBranchLine(line, schemeStructure[idx].line)) continue;

                if (line !== null && line !== schemeStructure[idx].line && !isBranchLine(line, schemeStructure[idx].line)) currStart = false;
                if (currStart && (schemeStructure[idx].open || schemeStructure[idx].screw < 1 || (schemeStructure[idx].defect === "Lipsa tensiune" && !schemeStructure[idx].solved))) problem = true;
            }
    
            currStart = false;
    
            for (let idx = 0; idx < schemeStructure.length; idx++) {
                if (schemeStructure[idx].dotNumber === thirdIdx) {
                    currStart = true;
                    line = schemeStructure[idx].line;
                }
                if (line && isBranchLine(line, schemeStructure[idx].line)) continue;

                if (line !== null && line !== schemeStructure[idx].line && !isBranchLine(line, schemeStructure[idx].line)) currStart = false;
                if (currStart && (schemeStructure[idx].open || schemeStructure[idx].screw < 1 || (schemeStructure[idx].defect === "Lipsa tensiune" && !schemeStructure[idx].solved))) problem = true;
            }

            currStart = false;

            for (let idx = 0; idx < schemeStructure.length; idx++) {
                if (schemeStructure[idx].dotNumber === fourthIdx) {
                    currStart = true;
                    line = schemeStructure[idx].line;
                }
                const dotsText : any = document.querySelectorAll(".dot p");

                // Excluding automention and self maintanance
                const imageSource = [...dotsText].find(p => p.innerText.toString() === schemeStructure[idx].dotNumber.toString())?.parentElement?.parentElement?.querySelector("img")?.src;
                if (imageSource?.includes("automentinere") || imageSource?.includes("actionare_prin_apasare")) continue;
                const h2s : any = [...dotsText].find(p => p.innerText.toString() === schemeStructure[idx].dotNumber.toString())?.parentElement?.parentElement?.querySelectorAll("h2");
                if (h2s && [...h2s].some(text => text.innerText.includes("C.i."))) continue;

                if (line && isBranchLine(line, schemeStructure[idx].line)) continue;

                if (line !== null && line !== schemeStructure[idx].line && !isBranchLine(line, schemeStructure[idx].line)) currStart = false;
                if (currStart && (schemeStructure[idx].open || schemeStructure[idx].screw < 1 || (schemeStructure[idx].defect === "Lipsa tensiune" && !schemeStructure[idx].solved))) {
                    console.warn("PROBLEM", schemeStructure[idx]);
                    problem = true;
                }
            }

            currStart = false;

            for (let idx = 0; idx < schemeStructure.length; idx++) {
                if (schemeStructure[idx].dotNumber === fifthIdx) {
                    currStart = true;
                    line = schemeStructure[idx].line;
                }

                const dotsText : any = document.querySelectorAll(".dot p");

                const imageSource = [...dotsText].find(p => p.innerText.toString() === schemeStructure[idx].dotNumber.toString())?.parentElement?.parentElement?.querySelector("img")?.src;
                const h2s : any = [...dotsText].find(p => p.innerText.toString() === schemeStructure[idx].dotNumber.toString())?.parentElement?.parentElement?.querySelectorAll("h2");
                if (imageSource?.includes("automentinere") || imageSource?.includes("actionare_prin_apasare")) continue;
                if (h2s && [...h2s].some(text => text.innerText.includes("C.i."))) continue;

                if (line && isBranchLine(line, schemeStructure[idx].line)) continue;

                if (line !== null && line !== schemeStructure[idx].line && !isBranchLine(line, schemeStructure[idx].line)) currStart = false;
                if (currStart && ((schemeStructure[idx].open && ![...h2s].some(text => text.innerText.includes("C.i.")) && !(imageSource.includes("automentinere") || imageSource.includes("actionare_prin_apasare"))) || schemeStructure[idx].screw < 1 || (schemeStructure[idx].defect === "Lipsa tensiune" && !schemeStructure[idx].solved))) {
                    console.warn("PROBLEM", schemeStructure[idx]);
                    problem = true;
                } 
            }
    
            const consumer = document.querySelectorAll(".cell")[activeElementY * COLUMNS + activeElementX];
            const image : any = consumer.firstChild;
            
            const contactFirstScheme = findSignObjectByName("I");
            const F1FirstScheme = findSignObjectByName("F1");
            const nullProblem = number === 1 ? schemeStructure.some((element : { dotNumber: number, defect: string }) => element.dotNumber >= 19 && element.dotNumber <= 32 && element.defect === "Scurt circuit") && !contactFirstScheme.names?.some((name : { name: string }) => name.name.includes("deschis")) && !F1FirstScheme.names?.some((name : { name: string }) => name.name.includes("deschis")) : isOpenSign;
            console.log(((Math.abs(firstPotential - secondPotential) >= 115 || (Math.abs(firstPotential - secondPotential) < 3 && (Math.abs(firstPotential - secondPotential) > 0 || (data.userData.type === "teacher" && isTeacher)))) || !schemeStructure.length || (nullProblem)));
            if (!image) return;
            console.log(isOpenSign, problem, firstPotential, secondPotential, isTeacher);
            
            if (!problem && ((Math.abs(firstPotential - secondPotential) >= 115 || (Math.abs(firstPotential - secondPotential) < 3 && (Math.abs(firstPotential - secondPotential) > 0 || (data.userData.type === "teacher" && isTeacher)))) || !schemeStructure.length || (nullProblem)) && (!isOpenSign || (number === 1 && nullProblem))) {
                image.classList.add("on");
                if (number === 1) image.classList.add("turned-on");
                if (image.src && image.src.includes("motor")) {
                    const S1 = findSignObjectByName("F1");
                    const S2 = findSignObjectByName("F2");
                    const S3 = findSignObjectByName("F3");
                    console.log("check", S1.names?.some((name : { name: string }) => name.name.includes("deschis")) || S2.names?.some((name : { name: string }) => name.name.includes("deschis")) || S3.names?.some((name : { name: string }) => name.name.includes("deschis")));

                    if (S1.names?.some((name : { name: string }) => name.name.includes("deschis")) || S2.names?.some((name : { name: string }) => name.name.includes("deschis")) || S3.names?.some((name : { name: string }) => name.name.includes("deschis"))) {
                        image.classList.remove("spin");
                        image.classList.remove("reverse-spin");

                        if (reverseSpin) image.classList.add("reverse-semi-spin");
                        else image.classList.add("semi-spin");
                        // image.classList.add("semi-spin");

                    }
                    else {
                        if (reverseSpin) image.classList.add("reverse-spin");
                        else image.classList.add("spin");
                        // image.classList.add("spin");
                        image.classList.remove("semi-spin");
                        image.classList.remove("reverse-semi-spin");
                    }
                    
                    setSpin(true);
                } // Spinning the engine
            }
            else {
                if (image.classList.contains("on")) {
                    if (selfMaintananceQ1?.names?.some((name : { name: string }) => name.name.includes("deschis"))) {
                        actuationAlgorithm(selfMaintananceQ1.x, selfMaintananceQ1.y, true);
                    } 

                    if (selfMaintananceQ2?.names?.some((name : { name: string }) => name.name.includes("deschis"))) {
                        actuationAlgorithm(selfMaintananceQ2.x, selfMaintananceQ2.y, true);
                    }
                }
                image.classList.remove("on");
                image.classList.remove("turned-on");
                image.classList.remove("spin");
                image.classList.remove("semi-spin");
                image.classList.remove("reverse-spin");
                image.classList.remove("reverse-semi-spin");
                setSpin(false);
            }
    
        })();
    }, [signs, projectName, schemeStructure]);

    useLayoutEffect(() => {
        if (number === 1 || !finished) return;
        const consumer = document.querySelectorAll(".cell")[activeElementY * COLUMNS + activeElementX];
        if (spin) replaceSign(Number(consumer.getAttribute("row")), Number(consumer.getAttribute("column") || 0), 1, SCHEME_ELEMENTS[`motor_asincron_trifazat_cu_rotorul_in_scurtcircuit_pornit`].imageSource);
        else {
            // const intreruptor1 = findSignObjectByName("Q1");
            // const intreruptor2 = findSignObjectByName("3");
            // const intreruptor3 = findSignObjectByName("5");

            // actuationAlgorithm(intreruptor1?.x, intreruptor1?.y, true);
            // actuationAlgorithm(intreruptor2?.x, intreruptor2?.y, true);
            // actuationAlgorithm(intreruptor3?.x, intreruptor3?.y, true);
            if (number === 3) {
                const CAQ2 = findSignObjectByName("C.a. Q11.2");
                if (CAQ2 && CAQ2.names?.some((name : { name: string }) => name.name.includes("inchis"))) actuationAlgorithm(CAQ2?.x, CAQ2?.y, false);
            }

            const CAQ1 = findSignObjectByName("C.a. Q1");
            if (CAQ1 && CAQ1.names?.some((name : { name: string }) => name.name.includes("inchis"))) actuationAlgorithm(CAQ1?.x, CAQ1?.y, false);
            const currSign = findSignObjectByName("C.a. Q1");
            actuationAlgorithm(currSign?.x, currSign?.y, false);
            const S1 = findSignObjectByName("F1");
            const S2 = findSignObjectByName("F2");
            const S3 = findSignObjectByName("F3");
            setTimeout(() => {
                // if (S1.name.includes("deschis") || S2.name.includes("deschis") || S3.name.includes("deschis") || CAQ1.name.includes("deschis")) replaceSign(Number(consumer.getAttribute("row")), Number(consumer.getAttribute("column") || 0), 1, SCHEME_ELEMENTS[`motor_asincron_trifazat_cu_rotorul_in_scurtcircuit`].imageSource);
                replaceSign(Number(consumer.getAttribute("row")), Number(consumer.getAttribute("column") || 0), 1, SCHEME_ELEMENTS[`motor_asincron_trifazat_cu_rotorul_in_scurtcircuit`].imageSource);
            }, 500);
        }
    }, [spin]);



    const findSignObject = (x: number, y: number, idx: number = 0) => {
        const currSign: { x: number, y: number, names: Array<any>, flip: number, texts: any } = signs.find((sign: { x: number, y: number, flip: number }) => sign.x === x && sign.y === y);
        return currSign;
    };
        
    const Q112 = useMemo(() => findSignObjectByName("Q11.2"), [signs]);

    useEffect(() => {
        if (Number(schemeNumber) !== 3 || !Q112?.names?.length) return;
        if (Q112.names?.some((name : { name: string }) => name.name.includes("deschis"))) setChangeSchemeNumber(0);
        else setChangeSchemeNumber(1);
    }, [Q112]);

    const replaceAll = (str : string, find : string, replace : string) => {
            return str.split(find).join(replace);
    };

    const actuationAlgorithm = (currIdx : number, currRowIdx : number, open : boolean | null = null) => {
        const currSignObj: any = findSignObject(currIdx, currRowIdx);
        
        if (!currSignObj) return;
        const source = currSignObj.names;
        if (!(source.some((name : { name: string }) => name.name.includes("/siguranta")) && !source?.some((name : { name: string }) => name.name.includes("siguranta_automata")) && !(source?.some((name : { name: string }) => name.name.includes("/contact_normal_deschis")) || source?.some((name : { name: string }) => name.name.includes("/contact_normal_inchis"))))) return;

        if (source.includes("/siguranta") && !source.includes("siguranta_automata")) {
            const isOpen = source.includes("/siguranta_deschisa");
            removeSign(currIdx, currRowIdx, 1);
            if (isOpen && (open !== null ? open : true)) addSign(currIdx, currRowIdx, 1, SCHEME_ELEMENTS["siguranta"].imageSource, currSignObj.flip, currSignObj.texts, currSignObj.signColor || "#000", currSignObj?.signPosition || "");
            else addSign(currIdx, currRowIdx, 1, SCHEME_ELEMENTS["siguranta_deschisa"].imageSource, currSignObj.flip, currSignObj.texts, currSignObj.signColor || "#000", currSignObj?.signPosition || "");
        } else if (source.includes("actionare_prin_apasare")) {
            const isOpen = source.includes("/contact_normal_deschis");
            const newSource = isOpen && (open !== null ? open : true) ? replaceAll(source?.[0]?.name, "deschis", "inchis").slice(14) : replaceAll(source?.[0]?.name, "inchis", "deschis").slice(14);
            const realNewSource = newSource.split(".")[0];

            removeSign(currIdx, currRowIdx, 1);
            addSign(currIdx, currRowIdx, 1, SCHEME_ELEMENTS[realNewSource].imageSource, currSignObj.flip, currSignObj.texts, currSignObj.signColor || "#000", currSignObj?.signPosition || "");
            const selfMaintananceQ1 = findSignObjectByName("C.a. Q1");

            // const selfMaintananceQ1 = findSignObject(selfMaintananceQ1Obj?.x || (currIdx + 1), selfMaintananceQ1Obj?.y || currRowIdx);

            setTimeout(() => {
                if (projectName.toLowerCase().includes("circuit")) return;
                if ((currSignObj.names?.some((name : { name: string }) => name.name.includes("automentinere")) && !selfMaintananceQ1.names?.some((name : { name: string }) => name.name.includes("inchis"))) || (currSignObj.texts.some((text: { name: string }) => text.name.includes("B.o.")) && selfMaintananceQ1.names?.some((name : { name: string }) => name.name.includes("inchis")))) {

                    actuationAlgorithm(selfMaintananceQ1?.x || (currIdx + 1), selfMaintananceQ1?.y || currRowIdx);
                    // For the three parallel contacts
                    actuationAlgorithm(4, 7);
                    actuationAlgorithm(5, 7);
                    actuationAlgorithm(6, 7);

                }

                if ((currSignObj.names?.some((name : { name: string }) => name.name.includes("automentinere")) && !selfMaintananceQ2.names?.some((name : { name: string }) => name.name.includes("inchis"))) || (currSignObj.texts.some((text: { name: string }) => text.name.includes("B.o.")) && selfMaintananceQ2.names?.some((name : { name: string }) => name.name.includes("inchis")))) {

                    actuationAlgorithm(selfMaintananceQ2?.x || (currIdx + 1), selfMaintananceQ2?.y || currRowIdx);
                    // For the three parallel contacts
                    actuationAlgorithm(8, 7);
                    actuationAlgorithm(9, 7);
                    actuationAlgorithm(10, 7);

                }
            }, 500);

            setTimeout(() => {
                removeSign(currIdx, currRowIdx, 1);
                addSign(currIdx, currRowIdx, 1, currSignObj.names, currSignObj.flip, currSignObj.texts, currSignObj.signColor || "#000", currSignObj?.signPosition || "");
            }, 650);

        }
        else if (source.includes("/contact_normal_deschis") || source.includes("/contact_normal_inchis")) {
            const isOpen = source.includes("/contact_normal_deschis");
            const newSource = isOpen && (open !== null ? open : true) ? replaceAll(source?.[0]?.name, "deschis", "inchis").slice(14) : replaceAll(source?.[0]?.name, "inchis", "deschis").slice(14);
            removeSign(currIdx, currRowIdx, 1);
            addSign(currIdx, currRowIdx, 1, SCHEME_ELEMENTS[newSource.split(".")[0]].imageSource, currSignObj.flip, currSignObj.texts, currSignObj.signColor || "#000", currSignObj?.signPosition || "");
        }

        setTimeout(() => document.querySelectorAll(".cell").forEach((cell: any) => cell.classList.remove("selected")), 100);
        
    };


    useEffect(() => {
        console.log(signs);
        localStorage.setItem("signs", JSON.stringify(signs)); 
        if (schemeNumber && localStorage.getItem("sign-page")?.toString() !== schemeNumber?.toString()) localStorage.removeItem("signs");
        if (schemeNumber) localStorage.setItem("sign-page", schemeNumber?.toString());
        
    }, [signs]);

    return finished ? (
        <>
            <Grid isHighTension={false} isSimulation={true} printSchemes={null} removePage={null} category="2" totalPages={1} page={1} debugSchemeNumber={number} useStore={useStore} data={data} projectId={null} projectName={projectName} format="A4" teacherVar={isTeacher} /> 
        </>
    ) : <Processing />;
    
};

export default PredefinedSchemeDebug;