import { faBookOpen, faCircleExclamation, faDoorClosed, faDoorOpen, faFlagCheckered, faListOl, faTimes } from "@fortawesome/free-solid-svg-icons";
import { faCircleCheck, faCircleXmark, faStar, faUser } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../Components/Header/Header";
import "./LeaderboardDebugSession.css";

const LeaderboardDebugSession = ({ data, socket }  : { data : any, socket: any }) => {

    const params = useParams();
    const sessionId = params?.sessionId;
    const [results, setResults] = useState<Array<any>>([]);
    const [cookies, setCookie] = useCookies();
    const navigate = useNavigate();

    const [title, setTitle] = useState<string | null>(null);
    const [sessionFinished, setSessionFinished] = useState<boolean>(false);
    const [schemeNumber, setSchemeNumber] = useState<number>(0);
    const [activeStep, setActiveStep] = useState<number | null>(null);
    const [activeGuide, setActiveGuide] = useState<boolean>(true);
    const scenarioPaperRef = useRef<HTMLDivElement>(null);
    
    const getLeaderboard = async () => {

        const options = {
            method: "GET",
            headers: {
                'Content-Type': 'application/json'
            }
        };

        try {
            const request = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/session/get-results-of-session/${data.userData.type === "teacher" ? data.userData.userId : data.userData.teacherId}/${sessionId}`, options);
            const response = await request.json();
            console.warn(response);
            const results = response.results;
            setResults(results);
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        (async () => {
            const options = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                },
             
            };

            try {
                const request = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/session/check-id/${data.userData.type === "teacher" ? data.userData.userId : data.userData.teacherId}/${sessionId}`, options);
                const response = await request.json();
                if (!response.success) return navigate(-1);
                console.warn(response.results);

                setTitle(response.results?.name);
                setSessionFinished(response.results.finished)
                setSchemeNumber(Number(response?.results?.number));
                await getLeaderboard();
            } catch (e) {
                console.log(e);
            }
        })(); 

        
    }, []);


    useEffect(() => {
        socket.on("update-results", async (currResults : any) => {
            const options = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                }
            };

            try {
                const request = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/session/get-results-of-session/${data.userData.userId}/${sessionId}`, options);
                const response = await request.json();

                console.warn(response.results);

                if (response.success) setResults(response.results);

            } catch (e) {
                console.log(e);
            }
        });
    }, []);


    const finishSessionTeacher = async () => {
        if (data.userData.type !== "teacher") return;
        
        const options = {
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                teacherId: data.userData.userId,
                sessionId
            })
        };

        try {
            const request = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/session/finish-session`, options);
            const response = await request.json();
            if (response.success) {
                socket.emit("session-finished", sessionId, data.userData.userId);
                window.location.reload();
            }
            
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <div className="content">
            <Header data={data} socket={socket} />

            {title ? <h2 className="title">{title}</h2> : null}

            <div className="session-containers">
                {data.userData.type === "teacher" ? <div className="join-session-container" onClick={() => window.location.replace(`/session/${sessionId}/${schemeNumber}/1`)}>
                    <FontAwesomeIcon icon={faDoorOpen} className="icon"/>
                    <h2>{cookies?.language === "en" ? "Join session" : "Intra in sesiune"}</h2>
                </div> : null}

                {data.userData.type === "teacher" && !sessionFinished ? <div className="finish-session-container" onClick={async () => await finishSessionTeacher()}>
                    <FontAwesomeIcon icon={faFlagCheckered} className="icon"/>
                    <h2>{cookies?.language === "en" ? "Finish session" : "Incheie sesiunea"}</h2>
                </div> : null}
            </div>

            {activeGuide ? (
                    <div className="steps-guide-container">

                        <div className="close-icon-container" onClick={() => setActiveGuide(false)}>
                            <FontAwesomeIcon className="close-icon" icon={faTimes} />
                        </div>
                        
                        <h2>{cookies?.language === "en" ? "Guide of using" : "Ghid de folosire"}</h2>

                        <div className={`step ${activeStep === 1 ? "active" : ""}`} onClick={() => setActiveStep(1)}>
                            <p>1. {cookies?.language === "en" ? "Creation of a defect" : "Creearea unui defect"}</p>
                        </div>

                        <div className={`step ${activeStep === 2 ? "active" : ""}`} onClick={() => setActiveStep(2)}>
                            <p>2. {cookies?.language === "en" ? "Removing defect" : "Stergerea unui defect"}</p>
                        </div>

                        <div className={`step ${activeStep === 3 ? "active" : ""}`} onClick={() => setActiveStep(3)}>
                            <p>3. {cookies?.language === "en" ? "Leaderboard" : "Clasament"}</p>
                        </div>

                        <div className={`step ${activeStep === 4 ? "active" : ""}`} onClick={() => setActiveStep(4)}>
                            <p>4. {cookies?.language === "en" ? "Debugging" : "Depanarea"}</p>
                        </div>

                    </div>
                ) : null}

              <div className="guide-button" onClick={() => setActiveGuide(curr => !curr)}>
                    <FontAwesomeIcon icon={faBookOpen} className="guide-icon" />
                </div>

                <div className={`scenario-paper ${activeStep !== null ? "active" : ""}`} ref={scenarioPaperRef}>
                    {/* <h2>{cookies?.language === "en" ? "" : ""}</h2> */}
                    {activeStep === 1 ? 
                        cookies?.language === "en" ? <p>You are in the debugging mode. <br /> At the moment, you have to create defects on a certain point(s) to test your students. <br /> To create a defect, select a point, and then press the "Create defect" button in the top right. <br /> You can create 2 types of faults: Lack of voltage and Grounding (Short circuit) <br /> After choosing the type of fault, press "Save". </p> : 
                        <p>Te afli in modulul de depanare. <br /> <br /> In momentul de fata, trebuie sa creezi defecte pe un anumit(e) punct(e) pentru a testa elevii. <br /> <br />
                            Doar profesorul poate sa creeze o sesiune de depanare in care sa adauge unul sau mai multe defecte. Elevii trebuie sa intre pe sesiunea de depanare creata de profesor, apoi sa depisteze defectele de pe schema electrica si in final sa le rezolve. <br /> <br />
                            Pentru a crea un defect: <br /> <br />
                            <div className="margin">
                            1) selectati un punct <br /> <br /> 
                            2) apasati in dreapta sus pe butonul <b>Creeaza defect</b> <br /> <br /> 
                            3) Poti crea 2 tipuri de defecte: <br /> <div className="margin">
                                a) Lipsa de tensiune <br /> 
                                b) Punere la masa (Scurt circuit) <br /> <br />
                                </div> 
                            4) Dupa ce ai ales tipul de defect apesi pe <b>Salveaza</b>. <br /> <br /> 
                            </div>
                            După ce ai creat cel puțin un defect, apeși pe butonul de jos <b>Începe sesiunea</b> 
                        </p>
                    : activeStep === 2 ? 
                        cookies?.language === "en" ? <p>In case you have added a defect by mistake or you've selected the wrong type of defect, you will select the dot for which you want to delete the defect, then you will press in the top-right corner on the "Remove defect" button.</p> : <p>In caz ca ai adaugat un defect din greseala sau ai selectat tipul gresit de defect, selectezi punctul pentru care vrei sa stergi defectul, apoi apesi in coltul din dreapta sus pe "Sterge defect".</p>
                    : activeStep === 3 ? 
                        cookies?.language === "en" ? <p>After you have added the defects on the respective points, to actually begin students' / electricians' testing you will press on the button with "Start the session".</p> : <p>Dupa ce ai adaugat defectele pe punctele respective, pentru a incepe efectiv testarea elevilor / electricienilor vei apasa pe butonul de jos cu "Incepe sesiunea". </p>
                    : activeStep === 4 ? 
                        cookies?.language === "en" ? <p>Now that you have created the session, you will be able to see a leaderboard with the students / electricians added by you and statistics about how are they doing in the session (correct, wrong, etc.). Also, you can join the session, as a teacher, as well, by pressing on the button above the leaderboard "Join the session". </p> : <p>Acum ca ai creat sesiunea, vei putea sa vezi un clasament cu elevii / electricieni adaugati de tine si cateva statistici despre cum se descurca in sesiune (corecte, gresite, etc.). De asemenea, poti sa intri si tu, ca profesor, in sesiunea respectiva, apasand pe butonul de deasupra clasamentului "Intra in sesiune".</p>
                    : null}

                    <div className="close-button" onClick={() => setActiveStep(null)}>
                        <FontAwesomeIcon className="close-icon" icon={faTimes}/>
                    </div>

                    {/* {scenario === "shock" ? <img src={electrocutareColeg} alt="electrocutare coleg imagine" /> : null} */}
                </div>
    

            <div className="leaderboard">

                <div className="row">

                    <div className="column">
                        <FontAwesomeIcon icon={faListOl} className="column-icon" />
                        <p>{cookies?.language === "en" ? "No." : "Nr."}</p>
                    </div>

                    <div>
                        <FontAwesomeIcon icon={faUser} className="column-icon" />
                        <p>{cookies?.language === "en" ? "Name" : "Nume"}</p>
                    </div>

                    <div>
                        <FontAwesomeIcon icon={faDoorOpen} className="column-icon" />
                        <p>{cookies?.language === "en" ? "Grade" : "Clasa"}</p>
                    </div>

                    <div className="column">
                        <FontAwesomeIcon icon={faCircleExclamation} className="column-icon" />
                        <p>{cookies?.language === "en" ? "Defects" : "Defecte"}</p>
                    </div>

                    <div className="column">
                        <FontAwesomeIcon icon={faCircleCheck} className="column-icon" />
                        <p>{cookies?.language === "en" ? "Correct" : "Corecte"}</p>
                    </div>

                    <div className="column">
                        <FontAwesomeIcon icon={faCircleXmark} className="column-icon" />
                        <p>{cookies?.language === "en" ? "Wrong" : "Gresite"}</p>
                    </div>

                    <div className="column">
                        <FontAwesomeIcon icon={faStar} className="column-icon" />
                        <p>{cookies?.language === "en" ? "Points" : "Puncte"}</p>
                    </div>

                </div>

                {results && results.length ? results.map((result, idx) => (
                    <div className="user" key={idx}>
                        <div className="column">
                            <p>{idx + 1}</p>
                        </div>

                        <div className="column">
                            <p>{result.name}</p>
                        </div>

                        <div className="column">
                            <p>{result?.grade || (cookies?.language === "en" ? "Undefined" : "Nedefinit")}</p>
                        </div>

                        <div className="column">
                            <p>{result.problemsSolved.length} / {result.problems.length} </p>
                        </div>

                        <div className="column">
                            <p>{result?.correct?.length || 0}</p>
                        </div>

                        <div className="column">
                            <p>{result?.wrong?.length || 0}</p>
                        </div>

                        <div className="column"  >
                            <p>{result.points || 0} / 100</p>
                        </div>
                    </div>
                )) : null}
            </div>

            {/* {data.userData.type === "teacher" && !sessionFinished ? (
                <div className="start-session-button" onClick={async () => await finishSessionTeacher()}>
                    <p>{cookies?.language === "en" ? "Finish session" : "Incheie sesiunea"}</p>
                </div>
            ) : null} */}

           
        </div>
    );
};

export default LeaderboardDebugSession;