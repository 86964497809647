const zoomFunc = (currZoom, isGrid = true, format = "A4") => {
    
    //document.body.style.zoom = currZoom;
    if (document.querySelector(".new-page")) {
        document.querySelector(".new-page").style.minWidth = `${(format === "A4" ? 297 : 420) * currZoom}mm`;
        document.querySelector(".new-page").style.width = `${(format === "A4" ? 297 : 420) * currZoom}mm`;
        document.querySelector(".new-page").style.maxWidth = `${(format === "A4" ? 297 : 420) * currZoom}mm`;
        document.querySelector(".new-page").style.minHeight = `${(format === "A4" ? 210 : 297) * currZoom}mm`;
        document.querySelector(".new-page").style.height = `${(format === "A4" ? 210 : 297)  * currZoom}mm`;
        document.querySelector(".new-page").style.maxHeight = `${(format === "A4" ? 210 : 297) * currZoom}mm`;
    }

    if (document.querySelector(".new-page .label")) {
        document.querySelector(".new-page .label").style.transform = `scale(${currZoom})`;
    }

    document.querySelectorAll(".page-container").forEach(page => {
        page.style.minWidth = `${(format === "A4" ? 297 : 420) * currZoom}mm`;
        page.style.width = `${(format === "A4" ? 297 : 420) * currZoom}mm`;
        page.style.maxWidth = `${(format === "A4" ? 297 : 420) * currZoom}mm`;
        page.style.minHeight = `${(format === "A4" ? 210 : 297) * currZoom}mm`;
        page.style.height = `${(format === "A4" ? 210 : 297)  * currZoom}mm`;
        page.style.maxHeight = `${(format === "A4" ? 210 : 297) * currZoom}mm`;
        page.style.border = `0mm ${5 * currZoom}mm 0mm ${currZoom}mm`;
       
        page.querySelector(".grid-total-container .grid-container").style.border = `${0.5 * currZoom}px solid grey`;
        page.querySelector(".grid-total-container .grid-container").style.borderRightWidth = `${.75 * currZoom}px`;
    });
 
    document.querySelectorAll(".page-container .numbers-container .empty").forEach(elem => elem.style.width = `${7 * currZoom}mm`);
    document.querySelectorAll(".page-container .grid-total-container").forEach(elem => elem.style.height = `${(format === "A4" ? 195 : 282) * currZoom}mm`);
    document.querySelectorAll(".page-container .grid-total-container .letters").forEach(elem => elem.style.width = `${7 * currZoom}mm`);
    document.querySelectorAll(".page-container .grid-total-container .letters").forEach(elem => elem.style.border = `${0.5 * currZoom}px solid grey`);
    document.querySelectorAll(".page-container .grid-total-container .letters p").forEach(elem => elem.style.body = `${0.5 * currZoom}px solid grey`);
    document.querySelectorAll(".page-container .grid-total-container .grid-container .row").forEach(elem => {
        elem.style.height = `${10 * currZoom}mm`;
    });

    let visitedParagraph = null;



    if (isGrid) {
        document.querySelectorAll(".page-container .grid-total-container .grid-container .row .cell:not(.selected)").forEach(elem => {
            elem.style.border = `${.5 * currZoom}px solid rgba(109,127,135, .3)`;
        });

        document.querySelectorAll(".page-container .grid-total-container .grid-container .row .cell.selected").forEach(elem => {
            elem.style.border = `${.55 * currZoom}px solid rgba(0, 0, 0, .85)`;
        });
    }
    else 
        document.querySelectorAll(".page-container .grid-total-container .grid-container .row .cell").forEach(elem => {
            elem.style.border = `none`;
            // elem.style.width = `${20 * currZoom}mm`;
            const dots = elem.querySelectorAll(".dot");
            dots && dots.length && dots.forEach((dot, idx) => {


                dot.style.width = `${currZoom * 5.2}px`;
                dot.style.height = `${currZoom * 5.2}px`;

                if (elem.classList.contains("horizontal")) {
                    if (dot.classList.contains("max-left")) {
                        dot.style.left = `${currZoom * -6.5}px`;
                    } 

                    if (dot.classList.contains("left")) {
                        dot.style.left = `${currZoom * 3.5}px`;
                    }

                    if (dot.classList.contains("max-right")) {
                        dot.style.right = `${currZoom * -7}px`;
                    } 

                    if (dot.classList.contains("right")) {
                        dot.style.right = `${currZoom * 4}px`;
                    }
                }


                if (elem.classList.contains("vertical")) {

                   

                    if (dot.classList.contains("max-left"))
                        dot.style.top = `${currZoom * -6}px`;
                    
                    if (dot.classList.contains("left"))
                        dot.style.top = `${1 * currZoom}px`;

                    if (elem.classList.contains("engine")) {
                        if (dot.classList.contains("max-left"))
                            dot.style.top = `${currZoom * -22.5}px`;
                    
                        if (dot.classList.contains("left"))
                            dot.style.top = `${-16 * currZoom}px`;
                    }
                    
                    
                    if (dot.classList.contains("right")) {
                        dot.style.bottom = `${3 * currZoom}px`;
                    }

                    if (dot.classList.contains("max-right")) {
                        dot.style.bottom = `${currZoom * - 4}px`;

                    }

                    if (dot.classList.contains("branch-left")) {
                        dot.style.top = `${-12.5 * currZoom}px`;
                        dot.style.left = `${-13.5 * currZoom}px`;
                    } 
                    
                    if (dot.classList.contains("branch-left-max")) {
                        dot.style.top = `${-7.5 * currZoom}px`;
                        dot.style.left = `${-9 * currZoom}px`;
                    } 

                    if (dot.classList.contains("branch-right")) {
                        dot.style.top = `${-7.5 * currZoom}px`;
                        dot.style.right = `${-9 * currZoom}px`;
                    } 
                    
                    if (dot.classList.contains("branch-right-max")) {
                        dot.style.top = `${-12.5 * currZoom}px`;
                        dot.style.right = `${-13.5 * currZoom}px`;
                    } 
                }

                if (elem.classList.contains("left-automention")) {
                    if (dot.classList.contains("max-left-automention")) {
                        dot.style.top = `${0 * currZoom}px`;
                        dot.style.right = `${7.5 * currZoom}px`;
                    } 

                    if (dot.classList.contains("max-right-automention")) {
                        dot.style.bottom = `${-.5 * currZoom}px`;
                        dot.style.right = `${7.5 * currZoom}px`;
                    }

                    if (dot.classList.contains("left"))
                        dot.style.top = `${-.5 * currZoom}px`;
                
                
                    if (dot.classList.contains("right")) {
                        dot.style.bottom = `${-.5 * currZoom}px`;
                    }

                    if (dot.classList.contains("max-left"))
                    dot.style.top = `${-8.5 * currZoom}px`;
            
            
                    if (dot.classList.contains("max-right")) {
                        dot.style.bottom = `${-8.5 * currZoom}px`;
                    }
                } 
                
                if (elem.classList.contains("right-automention")) {
                    if (dot.classList.contains("max-left-automention")) {
                        dot.style.top = `${-.25 * currZoom}px`;
                        dot.style.left = `${6 * currZoom}px`;
                    } 

                    if (dot.classList.contains("max-right-automention")) {
                        dot.style.bottom = `${-.5 * currZoom}px`;
                        dot.style.left = `${6 * currZoom}px`;
                    }

                    if (dot.classList.contains("left"))
                        dot.style.top = `${-.5 * currZoom}px`;
                
                
                    if (dot.classList.contains("right")) {
                        dot.style.bottom = `${-.5 * currZoom}px`;
                    }

                }
                
                  
                    

                    // if (Number(dot.getAttribute("point")) / 45 >= 1) {
                    //     dot.style.display = `none`;
                    // }
                
            });
            const dotsParagraph = elem.querySelectorAll(".dot p");
            const dotsImages = elem.querySelectorAll(".dot img");
            dotsParagraph && dotsParagraph.length && dotsParagraph.forEach(paragraph => {
                paragraph.style.fontSize = `${9 * (currZoom || 1) * 0.75}px`;
                if (paragraph.parentElement.classList.contains("top")) {
                    paragraph.style.top = `${-10 * (currZoom || 1)}px`;
                    if (elem.classList.contains("vertical")) paragraph.style.left = `${-3.5 * (currZoom || 1)}px`;

                } else if (paragraph.parentElement.classList.contains("branch-left")) {
                    paragraph.style.top = `${-1.5 * (currZoom || 1)}px`;
                    paragraph.style.left = `${-8 * (currZoom || 1)}px`;
                } else if (paragraph.parentElement.classList.contains("branch-left-max")) {
                    paragraph.style.top = `${.5 * (currZoom || 1)}px`;
                    paragraph.style.left = `${-9 * (currZoom || 1)}px`;
                } else if (paragraph.parentElement.classList.contains("max-left-automention")) {
                    paragraph.style.top = `${-11.5 * (currZoom || 1)}px`;
                    paragraph.style.left = `${.5 * (currZoom || 1)}px`;
                } else if (paragraph.parentElement.classList.contains("max-right-automention")) {
                    paragraph.style.top = `${7.5 * (currZoom || 1)}px`;
                    paragraph.style.right = `${-1 * (currZoom || 1)}px`;
                } else {
                    if (paragraph.parentElement.parentElement.querySelector(".max-left-automention") !== null && paragraph.parentElement.parentElement?.querySelector("img")?.src?.includes("actionare_prin_apasare_automentinere")) {
                        paragraph.style.bottom = `${-13.5 * (currZoom || 1)}px`;
                        if (elem.classList.contains("vertical")) {
                            paragraph.style.right = `${15 * (currZoom || 1)}px`;
                        }
       

                        // visitedParagraph = paragraph.parentElement.parentElement;
                    } else {
                        paragraph.style.bottom = `${-13.5 * (currZoom || 1)}px`;
                        if (elem.classList.contains("vertical")) {
                            paragraph.style.left = `${15 * (currZoom || 1)}px`;
                        }
                    }

                }

               
            });

            const dotsScrewText = document.querySelectorAll(".screw-driver-option .option h3");
            const dotsScrewIcon = document.querySelectorAll(".screw-driver-option .screw-driver-option-icon")

            dotsScrewText && dotsScrewText.length && dotsScrewText.forEach(screwText => {
                screwText.style.fontSize = `${3.5 * (currZoom || 1)}px`;
            });

            dotsScrewIcon && dotsScrewIcon.length && dotsScrewIcon.forEach(screwIcon => {
                screwIcon.style.fontSize = `${3.5 * (currZoom || 1)}px`;
            });

            dotsImages && dotsImages.length && dotsImages.forEach((image, idx) => {
                image.style.width = `${15 * (currZoom || 1)}px`;
                // image.style.transform = `rotateZ(135deg)`;
                // if (dots[idx].classList.contains("activated")) image.style.transform += 'scale(calc(1 / 1.7))';

                // image.style.height = `${20 * (currZoom || 1)}px`;
            });
        });

    document.querySelectorAll(".page-container .grid-total-container .grid-container .row .cell > img").forEach(elem => {
        elem.style.width = `100%`;
        elem.style.maxWidth = `${10 * currZoom}mm`;
    });


    document.querySelectorAll(".page-container .grid-total-container .grid-container .row .cell.copy").forEach(elem => {
        elem.style.border = `2px dashed #000`;
    });

    document.querySelectorAll(".page-container .grid-total-container .grid-container .row .cell h2").forEach(elem => {
        elem.style.fontSize = `${Number(elem.getAttribute("font-size") || 10) * (currZoom || 1) * 0.75}px`;
        elem.style.left = `${Number(elem.getAttribute("positionx") || 0) * (currZoom || 1)}mm`;
        elem.style.top = `${Number(elem.getAttribute("positiony") || 0) * (currZoom || 1)}mm`;
        
    });
};

export default zoomFunc;
