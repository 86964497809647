import { faBuilding, faUser, faCalendar, faIdCard } from "@fortawesome/free-regular-svg-icons";
import { faFileLines, faUserPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ChangeEvent, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useLocation, useNavigate } from "react-router-dom";
import "../CreateProject/CreateProject.css";

const EditHighTensionProject = ({ data } : { data: any }) => {

    const navigate = useNavigate();
    const [cookies, setCookie] = useCookies();
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const location = useLocation();
    const project = location.state?.project;
    if (!project) navigate(-1);

    const [inputs, setInputs] = useState<{ projectName: string, type: string, company: string }>({
        projectName: project?.projectName || "",
        type: project?.format || "A4",
        company: project?.compay || ""
    });

    const handleInputChange = (e : React.ChangeEvent<HTMLInputElement>) => {
        setInputs(currInputs => {
            return {...currInputs,
                [e.target.name]: e.target.value
            };
        });
    };

    const handleCreateProject = async (e : MouseEvent) => {
        if (inputs.projectName.length < 3 || inputs?.company)
          return setErrorMessage(cookies?.language === "en" ? "Fill every required field!" : "Completeaza toate campurile obligatorii!");

        e.preventDefault();
        const options = {
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({...inputs,
                format: inputs.type,
                userId: data.userData.userId,
                projectId: project.projectId
            })
        };

        try {
            const request = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/high-tension-project/update-project-settings`, options);
            const response = await request.json();
            console.log(response);
            if (response.success) {
                window.location.assign("/high-tension-edit");
            }
        } catch (e) {
            console.log(e);
        }
    };

   
    return (
        <div className="container">
            <div className="forms-container">
                <div className="signin-signup">
                    <form action="#" className="sign-in-form">
                      <h2 className="title">Editează proiectul </h2>
                         
                        <div className="input-field">
                            <FontAwesomeIcon icon={faUser} className="input-icon" />
                            <input type="text" name="projectName" value={inputs.projectName} onChange={handleInputChange} placeholder="Numele proiectului *" />
                        </div>

                        <div className="input-field">
                            <FontAwesomeIcon icon={faUser} className="input-icon" />
                            <input type="text" name="company" value={inputs.company} onChange={handleInputChange} placeholder="Companie / Școală *" />
                        </div>
                              
                    <p className="error">{errorMessage}</p>
                    <input type="submit" value={cookies?.language === "en" ? "Save changes" : "Salveaza modificarile"} className="btn solid" onClick={async (e : any) => await handleCreateProject(e)} />

                  </form>
                </div>
            </div>

        </div>
    );
};

export default EditHighTensionProject;