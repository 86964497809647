import { faAnchor, faAngleDoubleDown, faAngleDoubleUp, faArrowRightArrowLeft, faBolt, faBorderAll, faCompress, faExpand, faFileCirclePlus, faFileCircleXmark, faFilePen, faLock, faLockOpen, faMagnifyingGlass, faMagnifyingGlassMinus, faMagnifyingGlassPlus, faPenRuler, faPlay, faPlus, faPrint, faRemove, faRotate, faShareNodes, faTrashCan, faUpDownLeftRight, faUpRightAndDownLeftFromCenter, faXmark } from "@fortawesome/free-solid-svg-icons";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { memo, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useLocation, useNavigate } from "react-router-dom";
import "./OptionsMenu.css";


const OptionsMenu = ( { printSchemes, addSignPressed, activateEditName, replaceSignPressed, useStore, category, format, handleAddPage, page, projectId, signs, removePage, toggleSimulation } : { projectId: string, page: number, printSchemes: (() => void) | null, addSignPressed : () => void, activateEditName: () => void, replaceSignPressed: (x: number, y: number) => void, useStore: any, category: string | null, format: string, signs: any, removePage: ((page: number) => void) | null, handleAddPage: () => void, toggleSimulation: () => void } ) => {
    
    const [cookies, setCookie] = useCookies();
    const openMenu = useStore((state: any) => state.openMenu);
    const changeOpenMenu = useStore((state: any) => state.changeOpenMenu);
    const [openBarMenu, setOpenBarMenu] = useState<boolean>(false);
    const [fullScreen, setFullScreen] = useState<boolean>(Boolean(cookies?.fullScreen) || false);
    const [fullScreenAccess, setFullScreenAccess] = useState<boolean>(false);

    const openEditMenu = useStore((state: any) => state.activeEditMenu);
    const isSelected = useStore((state : any) => state.elements.find((element : any) => element.isSelectedSign));
    const isLocked = isSelected?.isLockedSign || false;
    const imageLocked = useStore((state: any) => state.lockImage);
    const activeEditOption = useStore((state: any) => state.activeEditOption);
    const language = useStore((state : any) => state.language);

    const changeLanguage = useStore((state: any) => state.changeLanguage);
    const changeEditMenu = useStore((state: any) => state.changeEditMenu);
    const changeActiveEditOption = useStore((state: any) => state.changeActiveEditOption);
    const imageLocking = useStore((state: any) => state.imageLocking);
    const printProject = useStore((state: any) => state.printProject);
    const removeSign = useStore((state: any) => state.removeSign);
    const changeIsLockedSign = useStore((state : any) => state.changeIsLockedSign);
    const removeSelectedSign = useStore((state: any) => state.removeSelectedSign);
    const changeAddSignMenu = useStore((state: any) => state.changeAddSignMenu);
    const isGrid = useStore((state : any) => state.isGrid);
    const changeGrid = useStore((state : any) => state.changeGrid);
    const flipSign = useStore((state: any) => state.flipSign);
    const changeCurrent = useStore((state: any) => state.changeCurrent);
    const isCurrentOnScheme = useStore((state: any) => state.current);
    const location = useLocation();

    // const isSelectedCell = new Array(document.querySelectorAll(".cell")).some((cell: any) => cell.classList.contains("selected"));
    
    const navigate = useNavigate();

    useEffect(() => {
        changeLanguage(cookies?.language);
    }, [cookies]);

    useEffect(() => {
        console.log(isSelected);
    }, [isSelected]);

    useEffect(() => {
        if (!cookies?.language) {
            setCookie('language', 'ro');
        }
        
    }, [cookies]);

    useEffect(() => {
        var el : any = document.documentElement, rfs = el.requestFullscreen || el.webkitRequestFullScreen || el.mozRequestFullScreen || el.msRequestFullscreen;
        
        if (typeof rfs != "undefined" && rfs) {
            setFullScreenAccess(true);
            // if (fullScreen) toggleFullScreen(true);
            console.log(window.history);
            
        

        } else setFullScreenAccess(false);

    }, []);

    useEffect(() => {
        if (!fullScreenAccess) return;
        if (window.history.state.fullScreen) {
            const el : any = document.documentElement;
            const isInFullScreen = (el.fullScreenElement && el.fullScreenElement !== null) ||  (el.mozFullScreen || el.webkitIsFullScreen);
            
            const event = () => {
                requestFullScreen();
                // el.requestFullScreen();

            };

            window.addEventListener("DOMContentLoaded", event);

            return () => {
                window.removeEventListener("DOMContentLoaded", event);
            }
            

        } else if (window.history.state.fullScreen === false) {
            cancelFullScreen();
        }
    }, [fullScreenAccess]);

    const requestFullScreen = () => {
            let el : any = document.documentElement;
            let rfs : any = el.requestFullscreen || el.webkitRequestFullScreen || el.mozRequestFullScreen || el.msRequestFullscreen;
            console.log(el);
            if (typeof rfs != "undefined" && rfs) rfs.call(el);
            // else if (typeof window?.ActiveXObject != "undefined" && typeof window?.ActiveXObject != "null") {
            //     // for Internet Explorer
            //     var wscript = new ActiveXObject("WScript.Shell");
            //     if (wscript!=null) wscript.SendKeys("{F11}");
            // }
    
    
            return false;
    
           
      
    };

    // Fullscreen functions 

    const cancelFullScreen = () => {
        let el : any = document;
        let requestMethod = el.cancelFullScreen|| el.webkitCancelFullScreen|| el.mozCancelFullScreen|| el.exitFullscreen|| el.webkitExitFullscreen;
        if (requestMethod) { // cancel full screen.
            requestMethod.call(el);
        }
        // else if (typeof window.ActiveXObject != "undefined") { // Older IE.
        //     var wscript = new ActiveXObject("WScript.Shell");
        //     if (wscript !== null) {
        //         wscript.SendKeys("{F11}");
        //     }
        // }
    };

    

 
    const toggleFullScreen = (fromEffect = false) => {
        let el : any = document;

        const isInFullScreen = (el.fullScreenElement && el.fullScreenElement !== null) ||  (el.mozFullScreen || el.webkitIsFullScreen);

        if (isInFullScreen) cancelFullScreen();
        else requestFullScreen();
        // console.log(isInFullScreen);
        // setFullScreen(curr => !curr);
        // setCookie("fullScreen", !isInFullScreen);
        window.history.replaceState({...window.history.state,
            fullScreen: !isInFullScreen || false
        }, "");

        // setTimeout(() => window.location.reload(), 100);
        return false;
    };

    // useEffect(() => {
    //     if (window.history.state.fullScreen) requestFullScreen();
    // }, []);

    const saveProject = () => {
        
    };

    const scrollVisible = () => {
        return document.body.scrollHeight > document.body.clientHeight || document.body.scrollWidth > document.body.clientWidth;
    };

    const getParentElement : any = (child : any, target : string) => {
        while (!child?.classList?.contains(target) && !child?.classList?.contains("pages-container")) {
            child = child?.parentElement;
        }

        return child;
    };

    return (
        <div className="menus">

            <div className={`side-menu ${openMenu ? "activated" : ""}`}>

                    <div 
                        className="option" 
                        onClick={() => {
                            changeOpenMenu();
                            changeEditMenu(false);
                            changeActiveEditOption(null);
                        }}
                        onContextMenu={(e : any) => {
                            e.preventDefault();
                            const element = getParentElement(e.target, "option");
                            element?.classList?.toggle("active");
                        }}
                        onBlur={(e : any) => {
                            const element = getParentElement(e.target, "option");

                            element?.classList?.remove("active");
                        }}
                        tabIndex={0}
                        onMouseLeave={(e: any) => {
                            const element = getParentElement(e.target, "option");
                            element?.classList?.remove("active");
                        }}
                    >
                        <FontAwesomeIcon 
                            icon={openMenu ? faAngleDoubleUp : faAngleDoubleDown} 
                            className="menu-icon" 
                            
                        />
                        <div className={`option-text ${openMenu ? "" : "relative-pos"}`}>
                            {openMenu ? <p>{language === "ro" ? "Inchidere Meniu" : "Close Menu"}</p> : <p>{language === "ro" ? "Deschidere Meniu" : "Open Menu"}</p>}
                        </div>
                    </div>

                    {openMenu ? (
                        <>
                            {category === "0" ? <div 
                                className="option" 
                                onClick={() => {
                                    addSignPressed();
                                }}
                                onContextMenu={(e : any) => {
                                    e.preventDefault();
                                    const element = getParentElement(e.target, "option");
                                    element?.classList?.toggle("active");
                                }}
                                onBlur={(e : any) => {
                                    const element = getParentElement(e.target, "option");

                                    element?.classList?.remove("active");
                                }}
                                tabIndex={0}
                                onMouseLeave={(e: any) => {
                                    const element = getParentElement(e.target, "option");
                                    element?.classList?.remove("active");
                                }}
                            >
                                <FontAwesomeIcon icon={faPlus} className="menu-icon" />
                                <div className="option-text">
                                    <p>{language === "ro" ? "Adaugare Semn" : "Add Sign"}</p>
                                </div>
                            </div> : null}

                            {category === "0" ? 
                            <div 
                                className="option" 
                                onClick={() => changeEditMenu()}
        
                                onContextMenu={(e : any) => {
                                    e.preventDefault();
                                    const element = getParentElement(e.target, "option");
                                    element?.classList?.toggle("active");
                                }}
                                onBlur={(e : any) => {
                                    const element = getParentElement(e.target, "option");

                                    element?.classList?.remove("active");
                                }}
                                tabIndex={0}
                                onMouseLeave={(e: any) => {
                                    const element = getParentElement(e.target, "option");
                                    element?.classList?.remove("active");
                                }}
                    
                            >
                                <FontAwesomeIcon 
                                    icon={faPenToSquare} 
                                    className="menu-icon" 
                                />
                                <div className="option-text" >
                                    <p>{language === "ro" ? "Editare" : "Edit"}</p>
                                </div>
                            </div> : null} 
                            
                            {category === "0" || category === "1" ? 
                            <div className="option" 
                                onClick={() => {
                                    if (isCurrentOnScheme) document.body.classList.remove("show-current");
                                    else document.body.classList.add("show-current");
                
                                    changeCurrent();
                                }}
                                onContextMenu={(e : any) => {
                                    e.preventDefault();
                                    const element = getParentElement(e.target, "option");
                                    element?.classList?.toggle("active");
                                }}
                                onBlur={(e : any) => {
                                    const element = getParentElement(e.target, "option");

                                    element?.classList?.remove("active");
                                }}
                                tabIndex={0}
                                onMouseLeave={(e: any) => {
                                    const element = getParentElement(e.target, "option");
                                    element?.classList?.remove("active");
                                }}
                            >
                                <FontAwesomeIcon 
                                    icon={faBolt} 
                                    className="menu-icon" 
                                />
                              

                                {/* <div 
                                  
                                    className="menu-icon-line" 
                                /> */}

                                <div className="option-text" >
                                    <p>{language === "ro" ? "Curent" : "Current"}</p>
                                </div>
                            </div> : null} 

                            {category === "0" ? 
                            <div className="option" 
                                onClick={() => {
                                    toggleSimulation();
                                }}
                                onContextMenu={(e : any) => {
                                    e.preventDefault();
                                    const element = getParentElement(e.target, "option");
                                    element?.classList?.toggle("active");
                                }}
                                onBlur={(e : any) => {
                                    const element = getParentElement(e.target, "option");

                                    element?.classList?.remove("active");
                                }}
                                tabIndex={0}
                                onMouseLeave={(e: any) => {
                                    const element = getParentElement(e.target, "option");
                                    element?.classList?.remove("active");
                                }}
                            >
                                <FontAwesomeIcon 
                                    icon={faPlay} 
                                    className="menu-icon" 
                                />
                                <div className="option-text" >
                                    <p>{language === "ro" ? "Simulează" : "Simulate"}</p>
                                </div>
                            </div> : null} 


                            {category === "0" ? 
                            <div 
                                className="option" 
                                onClick={() => {
                                    window.history.pushState({}, '', `/project/${projectId}/${page}`);
                                    window.history.replaceState({...window?.history?.state, addPage: true, removePage: false, reseted: false}, "");
                                    window.location.reload();
                                }}
                                onContextMenu={(e : any) => {
                                    e.preventDefault();
                                    const element = getParentElement(e.target, "option");
                                    element?.classList?.toggle("active");
                                }}
                                onBlur={(e : any) => {
                                    const element = getParentElement(e.target, "option");

                                    element?.classList?.remove("active");
                                }}
                                tabIndex={0}
                                onMouseLeave={(e: any) => {
                                    const element = getParentElement(e.target, "option");
                                    element?.classList?.remove("active");
                                }}
                            >
                                <FontAwesomeIcon icon={faFileCirclePlus} className="menu-icon" />
                                <div className="option-text">
                                    <p>{language === "ro" ? "Adauga pagina" : "Add page"}</p>
                                </div>
                            </div> : null}

                            {category === "0" ? 
                            <div 
                                className="option" 
                                onClick={() => {
                                    // signs.forEach((sign : any) => {
                                    //     if (sign.page === page) removeSign(sign.x, sign.y, sign.page);
                                    // });

                                    window.history.pushState({}, '', `/project/${projectId}/${page - 2 >= 0 ? page - 2 : page - 1}`);
                                    window.history.replaceState({...window?.history?.state, addPage: false, reseted: false, removePage: true}, "");
                                    window.location.reload();
                                
                                    // setTimeout(() => removePage ? removePage(page) : null, 0);
                                }}
                                onContextMenu={(e : any) => {
                                    e.preventDefault();
                                    const element = getParentElement(e.target, "option");
                                    element?.classList?.toggle("active");
                                }}
                                onBlur={(e : any) => {
                                    const element = getParentElement(e.target, "option");

                                    element?.classList?.remove("active");
                                }}
                                tabIndex={0}
                                onMouseLeave={(e: any) => {
                                    const element = getParentElement(e.target, "option");
                                    element?.classList?.remove("active");
                                }}
                            >
                                <FontAwesomeIcon icon={faFileCircleXmark} className="menu-icon" />
                                <div className="option-text">
                                    <p>{language === "ro" ? "Sterge pagina" : "Remove page"}</p>
                                </div>
                            </div> : null}

                            <div 
                                className="option" 
                                onClick={() => window.location.href = `/low-tension/${category === "0" ? "edit" : category === "1" ? "functional" : "debugging"}`}
                                onContextMenu={(e : any) => {
                                    e.preventDefault();
                                    const element = getParentElement(e.target, "option");
                                    element?.classList?.toggle("active");
                                }}
                                onBlur={(e : any) => {
                                    const element = getParentElement(e.target, "option");

                                    element?.classList?.remove("active");
                                }}
                                tabIndex={0}
                                onMouseLeave={(e: any) => {
                                    const element = getParentElement(e.target, "option");
                                    element?.classList?.remove("active");
                                }}
                            >
                                <FontAwesomeIcon icon={faXmark} className="menu-icon" />
                                <div className="option-text">
                                    <p>{language === "ro" ? "Inchide Proiect" : "Close Project"}</p>
                                </div>
                            </div>

                            <div 
                                className="option" 
                                onClick={async () => printSchemes && await printSchemes()}
                                onContextMenu={(e : any) => {
                                    e.preventDefault();
                                    const element = getParentElement(e.target, "option");
                                    element?.classList?.toggle("active");
                                }}
                                onBlur={(e : any) => {
                                    const element = getParentElement(e.target, "option");

                                    element?.classList?.remove("active");
                                }}
                                tabIndex={0}
                                onMouseLeave={(e: any) => {
                                    const element = getParentElement(e.target, "option");
                                    element?.classList?.remove("active");
                                }}
                            >
                                <FontAwesomeIcon icon={faPrint} className="menu-icon" />
                                <div className="option-text">
                                    <p>{language === "ro" ? "Printeaza" : "Print"}</p>
                                </div>
                            </div>

                            
                            {category === "0" ? 
                            <div 
                                className="option" 
                                onClick={() => {
                                    imageLocking();
                                    removeSelectedSign();
                                    document.querySelectorAll(".cell").forEach(cell => cell.classList.remove("selected"));
                                }}
                                onContextMenu={(e : any) => {
                                    e.preventDefault();
                                    const element = getParentElement(e.target, "option");
                                    element?.classList?.toggle("active");
                                }}
                                onBlur={(e : any) => {
                                    const element = getParentElement(e.target, "option");

                                    element?.classList?.remove("active");
                                }}
                                tabIndex={0}
                                onMouseLeave={(e: any) => {
                                    const element = getParentElement(e.target, "option");
                                    element?.classList?.remove("active");
                                }}
                            >
                                <FontAwesomeIcon icon={imageLocked ? faLock : faLockOpen} className="menu-icon" />
                                <div className="option-text">
                                    {imageLocked ? <p>{language === "ro" ? "Blocat" : "Locked"}</p> : <p>{language === "ro" ? "Deblocat" : "Unlocked"}</p>}
                                </div>
                            </div> : null}

                            {/* <div className="option" onClick={() => {
                                    // if (document.body.style.zoom) {
                                    //     document.body.style.zoom = `${Number(document.body.style.zoom) - 10}px`
                                    // }

                                    if (scrollVisible()) document.body.classList.remove("grid-scroll");
                                    else document.body.classList.add("grid-scroll");
                                    
                                    let newZoom;
                                    if (Number(document.body.getAttribute("zoom")) < 5) {
                                        if (document.body.getAttribute("zoom")) 
                                            newZoom = (Number(document.body.getAttribute("zoom")) + 0.1).toString();
                                        else newZoom = "1.1";

                                        document.body.setAttribute("zoom", newZoom);
                                        zoomFunc(newZoom, isGrid, format);
                                    }
                                  
                            }}>
                                <FontAwesomeIcon icon={faMagnifyingGlassPlus} className="menu-icon" />
                                <div className="option-text">
                                    <p>{language === "ro" ? "Marire" : "Maximize"}</p>
                                </div>
                            </div> */}

                            {/* <div className="option" onClick={() => {
                                
                                if (scrollVisible()) document.body.classList.remove("grid-scroll");
                                else document.body.classList.add("grid-scroll");
                                let newZoom;
                                if (Number(document.body.getAttribute("zoom")) > 0.9) {
                                        if (document.body.getAttribute("zoom")) 
                                            newZoom = (Number(document.body.getAttribute("zoom")) - 0.1).toString();
                                        if (newZoom) {
                                            document.body.setAttribute("zoom", newZoom);
                                            zoomFunc(newZoom, isGrid, format);
                                        }

                                }
                            }}>
                                <FontAwesomeIcon icon={faMagnifyingGlassMinus} className="menu-icon" />
                                <div className="option-text">
                                    <p>{language === "ro" ? "Micsorare" : "Minimize"}</p>
                                </div>
                            </div> */}

                            {/* <div className="option">
                                <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} className="menu-icon" />
                                <p>{language === "ro" ? "Micsorare" : "Minimize"}</p>
                            </div> */}

                            {/* <div 
                                className="option" 
                                onClick={() => setOpenBarMenu(currStatus => !currStatus)}
                                onContextMenu={(e : any) => {
                                    e.preventDefault();
                                    document.querySelectorAll(".option")?.[9]?.classList?.toggle("active");
                                }}
                                onBlur={(e : any) => {
                                    document.querySelectorAll(".option")?.[9]?.classList?.remove("active");
                                }}
                                tabIndex={0}
                                onMouseLeave={(e: any) => {
                                    document.querySelectorAll(".option")?.[9]?.classList?.remove("active");
                                }}
                            >

                                <FontAwesomeIcon 
                                    icon={faBars} 
                                    className="menu-icon" 
                                />
                                <div className="option-text">
                                    <p>{language === "ro" ? "Altele" : "Others"}</p>
                                </div>
                            </div> */}


                            {true ? (
                                <>

                                    {category === "0" ? 
                                    <div 
                                        className={`option ${isGrid ? "selected" : ""}`} 
                                        onClick={() => {
                                            changeGrid();
                                            // window.history.replaceState({...window.history.state,
                                            //      isGrid: window.history.state.isGrid != null ? !window.history.state.isGrid : !isGrid 
                                            // }, "");
                                            // window.location.reload();
                                            
                                        }}
                                        onContextMenu={(e : any) => {
                                            e.preventDefault();
                                            const element = getParentElement(e.target, "option");
                                            element?.classList?.toggle("active");
                                        }}
                                        onBlur={(e : any) => {
                                            const element = getParentElement(e.target, "option");

                                            element?.classList?.remove("active");
                                        }}
                                        tabIndex={0}
                                        onMouseLeave={(e: any) => {
                                            const element = getParentElement(e.target, "option");
                                            element?.classList?.remove("active");
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faBorderAll} className="menu-icon" />
                                        <div className="option-text">
                                            <p>{language === "ro" ? "Grila" : "Grid"}</p>
                                        </div>
                                    </div> : null}

                                    {fullScreenAccess ? 
                                        <div 
                                            className={`option`} 
                                            onClick={() => toggleFullScreen()}
                                            onContextMenu={(e : any) => {
                                                e.preventDefault();
                                                const element = getParentElement(e.target, "option");
                                                element?.classList?.toggle("active");
                                            }}
                                            onBlur={(e : any) => {
                                                const element = getParentElement(e.target, "option");
    
                                                element?.classList?.remove("active");
                                            }}
                                            tabIndex={0}
                                            onMouseLeave={(e: any) => {
                                                const element = getParentElement(e.target, "option");
                                                element?.classList?.remove("active");
                                            }}
                                        >
                                            <FontAwesomeIcon icon={fullScreen ? faCompress : faExpand} className="menu-icon" />
                                            <div className="option-text">
                                                <p>{language === "ro" ? "Ecran Complet" : "Fullscreen"}</p>
                                            </div>
                                        </div> 
                                    : null}


                                    {/* <div 
                                        className="option"
                                        onContextMenu={(e : any) => {
                                            e.preventDefault();
                                            const element = getParentElement(e.target, "option");
                                            element?.classList?.toggle("active");
                                        }}
                                        onBlur={(e : any) => {
                                            const element = getParentElement(e.target, "option");

                                            element?.classList?.remove("active");
                                        }}
                                        tabIndex={0}
                                        onMouseLeave={(e: any) => {
                                            const element = getParentElement(e.target, "option");
                                            element?.classList?.remove("active");
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faFileExport} className="menu-icon" />
                                        <div className="option-text">
                                            <p>{language === "ro" ? "Exportă proiectul" : "Export project"}</p>
                                        </div>
                                    </div> */}

                                    {/* <div className="option" onClick={() => mobileShare()}>
                                        <FontAwesomeIcon icon={faShareNodes} className="menu-icon" />
                                        <div className="option-text">
                                            <p>{language === "ro" ? "Distribuie" : "Share"}</p>
                                        </div>
                                    </div> */}

                                  

                                    {/* {category === "0" ? 
                                    <div 
                                        className="option" 
                                        onClick={() => {
                                            setCookie("language", language === "ro" ? "en" : "ro");
                                        }}
                                        onContextMenu={(e : any) => {
                                            e.preventDefault();
                                            const element = getParentElement(e.target, "option");
                                            element?.classList?.toggle("active");
                                        }}
                                        onBlur={(e : any) => {
                                            const element = getParentElement(e.target, "option");

                                            element?.classList?.remove("active");
                                        }}
                                        tabIndex={0}
                                        onMouseLeave={(e: any) => {
                                            const element = getParentElement(e.target, "option");
                                            element?.classList?.remove("active");
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faLanguage} className="menu-icon" />
                                        <div className="option-text">
                                            <p>{language === "ro" ? "Limba" : "Language"}</p>
                                        </div>
                                    </div> : null} */}
                                </>
                    
                            ) : null}
                        </>
                    ) : null}
            </div>

            {openEditMenu && openMenu ? 
                <div className="side-menu activated edit-menu">
                    
                    <div className={`option reverse-position ${activeEditOption === "edit-name" ? "selected" : ""}`} onClick={() => Array.from(document.querySelectorAll(".cell")).some((cell: any) => cell.classList.contains("selected")) ? activateEditName() : changeActiveEditOption(activeEditOption === "edit-name" ? null : "edit-name")}>
                        <FontAwesomeIcon icon={faFilePen} className="menu-icon" />
                        <div className="option-text">
                            <p>{language === "ro" ? "Editare Nume" : "Edit Name"}</p>
                        </div>
                    </div>

                    <div className={`option reverse-position ${activeEditOption === "remove" ? "selected" : ""}`} onClick={() => {
                        isSelected ? removeSign(isSelected.x, isSelected.y, isSelected.page) : changeActiveEditOption(activeEditOption === "remove" ? null : "remove")}
                    }>
                        <FontAwesomeIcon icon={faTrashCan} className="menu-icon"  />
                        <div className="option-text">
                            <p>{language === "ro" ? "Ștergere" : "Remove"}</p>
                        </div>
                    </div>

                    <div className={`option reverse-position ${activeEditOption === "replace" ? "selected" : ""}`} onClick={() => isSelected ? changeAddSignMenu() : changeActiveEditOption(activeEditOption === "replace" ? null : "replace") }>
                        <FontAwesomeIcon icon={faArrowRightArrowLeft} className="menu-icon" />
                        <div className="option-text">
                            <p>{language === "ro" ? "Înlocuire" : "Replace"}</p>
                        </div>
                    </div>

                    {isLocked ? 
                        <div className={`option reverse-position ${activeEditOption === "unlock" ? "selected" : ""}`} onClick={() => isSelected ? changeIsLockedSign(isSelected.x, isSelected.y, isSelected.page) : changeActiveEditOption(activeEditOption === "unlock" ? null : "unlock")}> 
                            <FontAwesomeIcon icon={faUpDownLeftRight} className="menu-icon" />
                            <div className="option-text">
                                <p>{language === "ro" ? "Deblochează" : "Unlock"}</p>
                            </div>
                        </div> : 

                        <div className={`option reverse-position ${activeEditOption === "lock" ? "selected" : ""}`} onClick={() => isSelected ? changeIsLockedSign(isSelected.x, isSelected.y, isSelected.page) : changeActiveEditOption(activeEditOption === "lock" ? null : "lock")}>
                            <FontAwesomeIcon icon={faAnchor} className="menu-icon" />
                            <div className="option-text">
                                <p>{language === "ro" ? "Blochează" : "Lock"}</p>
                            </div>
                        </div>
                    }

                    { isSelected ? 
                        <div className={`option reverse-position`} onClick={() => flipSign(isSelected.x, isSelected.y, (isSelected.flip + 90) % 360, isSelected.page)}>
                             <FontAwesomeIcon icon={faRotate} className="menu-icon" />
                             <div className="option-text">
                                <p>{language === "ro" ? "Rotire" : "Flip"}</p>
                             </div>
                        </div>
                    : null }

                    
                </div> 
            : null}
        </div>
    );
};

export default memo(OptionsMenu);