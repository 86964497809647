import { faAngleLeft, faEdit, faFileLines, faPencil, faPlus, faTrashCan, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useLayoutEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "../Projects/Projects.css";
import "../Project/Project.css";
import Header from "../../Components/Header/Header";
import DebugSessions from "../DebugSessions/DebugSessions";

const HighTensionSchemas = ({ data, socket } : { data: any, socket: any }) => {
    
    const navigate = useNavigate();
    const location = useLocation();
    const { module } = useParams();
    const [cookies, setCookie] = useCookies();
    const [category, setCategory] = useState("projects");

    const [debugViewOption, setDebugViewOption] = useState(location?.state?.debugViewOption || (data.userData.type === "teacher" ? 0 : 1));
    
    useEffect(() => {
        document.body.classList.remove("grid-body");
        document.querySelector("html")?.classList.remove("grid-body");

    }, [location]);



    const removeProject = async (projectId : string, e : any) => {
        
        const options = {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json"
            }
        };

        try {
            const request = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/high-tension-project/remove-project/${data.userData.userId}/${projectId}`, options);
            const response = await request.json();
            if (response.success) window.location.reload();
            else console.log(response);
        } catch (e) {
            console.log(e);
        }
    };
    

    return (
        <div className="content project-content">

            <Header data={data} socket={socket} />

            <div className="name-container">
                <div className="name">

                    <FontAwesomeIcon icon={faUser} className="user-icon" />
                    <p>{data.userData.username} {data.userData.type === "teacher" ? (cookies?.language === "en" ? "(Teacher)" : "(Profesor)") : (cookies?.language === "en" ? "(Student)" : "(Elev)")}</p>
                </div>
            </div>


            <div className="go-back" onClick={() => navigate(`/categories`)}>
                <FontAwesomeIcon icon={faAngleLeft} className="back-icon" />
            </div>

            <div className="module-title-container">
                <h2 className="title-module">{cookies?.language === "en" ? "High Tension Schemas" : "Scheme înalta tensiune"}</h2>
            </div>

        
                
            {data.userData.highTensionProjects?.length ?
                <div className="projects-container">
                    {data.userData.highTensionProjects.map((project : { projectName: string, format: string, projectId: string }, idx : number) => (
                        <div className="project-gradient" key={idx} onClick={(e : any) => {
                            if (e.target.tagName !== "svg" && e.target.tagName !== "path") {
                                localStorage.removeItem("signs");
                                window.location.replace(`/high-tension-project/${project.projectId}/0`);
                            } 
                        }}>
                            <div className={`project`}>
                                <h2>{project.projectName}  
                                    <div className="edit-container" onClick={() => navigate(`/edit-high-tension-project/${project.projectId}`, { state: { project: project } })}>
                                        <FontAwesomeIcon icon={faPencil} className="edit-icon" />
                                    </div> 
                                </h2>

                                <div className="format">
                                    <FontAwesomeIcon icon={faFileLines} className="format-icon" />
                                    <p>{project.format}</p>
                                </div>

                                <FontAwesomeIcon icon={faTrashCan} className="delete-icon" onClick={async (e : any) => await removeProject(project.projectId, e)} />
                                
                            </div>
                        </div>
                    ))}
                </div>
            : 
                <div className="empty-container">
                    <h2>Nu au fost create scheme.</h2>
                </div>
            }
                
            

            <div className={`add-button ${category === "projects" ? "show" : ""}`} onClick={() => category === "projects" ? navigate("/create-high-tension-project") : null}>
                <FontAwesomeIcon icon={faPlus} className="add-icon" />
                <p>{cookies?.language === "en" ? "Add project" : "Adauga Proiect"}</p>
            </div>

        </div>
    );
};

export default HighTensionSchemas;