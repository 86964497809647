import generator_deschis_inalta_tensiune from "../imgs/inalta_tensiune/generator_deschis_inalta_tensiune.svg";
import separator_deschis_inalta_tensiune from "../imgs/inalta_tensiune/separator_deschis_inalta_tensiune.svg";
import intreruptor_deschis_inalta_tensiune from "../imgs/inalta_tensiune/intreruptor_deschis_inalta_tensiune.svg";
import celula_de_masura_inalta_tensiune from "../imgs/inalta_tensiune/celula_de_masura_inalta_tensiune.svg";
import consumator_deschis_inalta_tenisune from "../imgs/inalta_tensiune/consumator_deschis_inalta_tensiune.svg";

import conductorLung from "../imgs/conductor_lung.svg";
import conductorLat from "../imgs/conductor_lat.svg";
import coltStangaJos from "../imgs/colt_stanga_jos.svg";
import derivatieDinConductor from "../imgs/derivatie_din_conductor.svg";
import linie_arc from "../imgs/linie_arc.svg";

import generator_inchis_inalta_tensiune from "../imgs/inalta_tensiune/generator_inchis_inalta_tensiune.svg";
import consumator_inalta_tensiune from "../imgs/inalta_tensiune/consumator_inalta_tensiune.svg";
import intreruptor_inchis_inalta_tensiune from "../imgs/inalta_tensiune/intreruptor_inchis_inalta_tensiune.svg";
import separator_inchis_inalta_tensiune from "../imgs/inalta_tensiune/separator_inchis_inalta_tensiune.svg";


const SCHEME_ELEMENTS_HIGH_TENSION : any = {
    generator_deschis_inalta_tensiune: {
        imageSource: generator_deschis_inalta_tensiune,
        realSource: [generator_deschis_inalta_tensiune],
        name: {
            ro: "Generator deschis",
            en: "Opened generator"
        }
    },

    generator_inchis_inalta_tensiune: {
        imageSource: generator_inchis_inalta_tensiune,
        realSource: [generator_inchis_inalta_tensiune],
        show: false
    },
    
    separator_deschis_inalta_tensiune: {
        imageSource: separator_deschis_inalta_tensiune,
        realSource: [separator_deschis_inalta_tensiune],
        name: {
            ro: "Separator deschis",
            en: "Opened separator"
        }
    },

    separator_inchis_inalta_tensiune: {
        imageSource: separator_inchis_inalta_tensiune,
        realSource: [separator_inchis_inalta_tensiune],
        show: false
    },

    intreruptor_deschis_inalta_tensiune: {
        imageSource: intreruptor_deschis_inalta_tensiune,
        realSource: [intreruptor_deschis_inalta_tensiune],
        name: {
            ro: "Intreruptor deschis",
            en: "Opened intreruptor"
        }
    },

    intreruptor_inchis_inalta_tensiune: {
        imageSource: intreruptor_inchis_inalta_tensiune,
        realSource: [intreruptor_inchis_inalta_tensiune],
        show: false
    },

    celula_de_masura_inalta_tensiune: {
        imageSource: celula_de_masura_inalta_tensiune,
        realSource: [celula_de_masura_inalta_tensiune],
        name: {
            ro: "Celula de masura",
            en: "Measurement cell"
        }
    },

    consumator_inalta_tensiune: {
        imageSource: consumator_inalta_tensiune,
        realSource: [consumator_inalta_tensiune],
        name: {
            ro: "Consumator",
            en: "Consumer"
        }
    },


    conductor_lung: {
        imageSource: conductorLung,
        realSource: [conductorLung],
        name: {
            ro: "Conductor (Lungime)",
            en: "Conductor (Length)"
        },
    },

    conductor_lat: {
        imageSource: conductorLat,
        realSource: [conductorLat],
        name: {
            ro: "Conductor (Latime)",
            en: "Conductor (Width)"
        },
    },

    cruce: {
        imageSource: linie_arc,
        realSource: [linie_arc],
        name: {
            ro: "Linie arc",
            en: "Arc line"
        },
    },

    derivatie_din_conductor: {
        imageSource: derivatieDinConductor,
        realSource: [derivatieDinConductor],
        name: {
            ro: "Derivatie din conductor",
            en: "Derivation from the conductor"
        },
    },

    colt_stanga_jos: {
        imageSource: coltStangaJos,
        realSource: [coltStangaJos],
        name: {
            ro: "Colt stanga jos",
            en: "Left down corner"
        },
    },

};

export default SCHEME_ELEMENTS_HIGH_TENSION;