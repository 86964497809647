import { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Grid from "../../Components/Grid/Grid";
import Processing from "../../Components/Processing/Processing";
import SCHEME_ELEMENTS from "../../Functions/getElements";

const PredefinedScheme = ({ useStore, data } : { useStore: any, data: any }) => {

    const navigate = useNavigate();
    const { schemeNumber } = useParams();
    const COLUMNS = Number(schemeNumber) !== 4 ? 28 : 40;
    const location = useLocation();
    const go_back = location?.state?.go_back;
    if (go_back) navigate(`/predefined-scheme-debug/${schemeNumber}`);
    if (schemeNumber === null) navigate(-1);

    const number = Number(schemeNumber);
    const addSign = useStore((state: any) => state.addSign);

    const changeHasCurrent = useStore((state: any) => state.changeHasCurrent);
    const reset = useStore((state: any) => state.reset);
    const changeGrid = useStore((state : any) => state.changeGrid);
    const imageLocking = useStore((state: any) => state.imageLocking);
    const signs = useStore((state: any) => state.elements);
    const replaceSign = useStore((state: any) => state.replaceSign);
    const removeSign = useStore((state: any) => state.removeSign);
    const changeIsSelectedSign = useStore((state: any) => state.changeIsSelectedSign);
    const activeElementX = number === 1 ? 11 : number === 2 ? 5 : number === 3 ? 5 :  5;
    const activeElementY = number === 1 ? 2 : number === 2 ? 11 : number === 3 ? 15 : 15;
    const [finished, setFinished] = useState<boolean>(false);
    const [projectName, setProjectName] = useState<string>("");
    const [spin, setSpin] = useState<boolean>(false);
    
    const replaceAll = (str : string, find : string, replace : string) => {
        console.log(str, find, replace);
        return str.split(find).join(replace);
    };

        
    useEffect(() => {
        console.log(number);
        reset();
        changeGrid();
        imageLocking();
        if (number === 1) {
            console.log("here", addSign);
            addSign(1, 2, 1, SCHEME_ELEMENTS["contur"].imageSource, 0, [{ name: "Faza", positionX: 2, positionY: 2.5, flip: 0, fontSize: 14, fontWeight: 700 }]);
            addSign(2, 2, 1, SCHEME_ELEMENTS["conductor_lung_sageata"].imageSource);
            addSign(3, 2, 1,  SCHEME_ELEMENTS["siguranta"].imageSource, 90);
            addSign(4, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(5, 2, 1,  SCHEME_ELEMENTS["regleta_1"].imageSource, 90, [{ name: "1", positionX: 4.75, positionY: 3.5, flip: 0, fontSize: 10, fontWeight: 700 }]);
            addSign(6, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(7, 2, 1,  SCHEME_ELEMENTS["contact_normal_deschis_actionare_prin_apasare"].imageSource, 90, [ { name: "I", positionX: 4.5, flip: 0, positionY: -3, fontSize: 10, fontWeight: 700 } ]);
            addSign(8, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(9, 2, 1,  SCHEME_ELEMENTS["regleta_1"].imageSource, 90, [{ name: "3", positionX: 4.5, positionY: 3.5, flip: 0, fontSize: 10, fontWeight: 700 }]);
            addSign(10, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(11, 2, 1,  SCHEME_ELEMENTS["indicator_luminos"].imageSource, 90);
            addSign(12, 2, 1,  SCHEME_ELEMENTS["colt_dreapta_sus"].imageSource, 0);
            addSign(12, 3, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource, 0);


            addSign(2, 4, 1,  SCHEME_ELEMENTS["conductor_lung_sageata"].imageSource, 0, [{ name: "Nul", positionX: -6.5, positionY: 2.5, flip: 0, fontSize: 14, fontWeight: 700 }]);
            addSign(3, 4, 1,  SCHEME_ELEMENTS["siguranta"].imageSource, 90);
            addSign(4, 4, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(5, 4, 1,  SCHEME_ELEMENTS["regleta_1"].imageSource, 90, [{ name: "2", positionX: 4.5, positionY: 3.5, flip: 0, fontSize: 10, fontWeight: 700 }]);
            addSign(6, 4, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(7, 4, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(8, 4, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(9, 4, 1,  SCHEME_ELEMENTS["regleta_1"].imageSource, 90, [{ name: "4", positionX: 4.5, positionY: 3.5, flip: 0, fontSize: 10, fontWeight: 700 }]);
            addSign(10, 4, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(11, 4, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(12, 4, 1,  SCHEME_ELEMENTS["colt_dreapta_jos"].imageSource, 0);
            changeIsSelectedSign(2, 2);
            setProjectName("Circuit simplu");
        } else if (number === 2) {

            addSign(1, 0, 1, SCHEME_ELEMENTS["conductor_lung_sageata"].imageSource, 0, [{ name: "L1", positionX: -5, positionY: 2.5, flip: 0, fontSize: 14, fontWeight: 700 }]);
            addSign(2, 0, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(3, 0, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(4, 0, 1, SCHEME_ELEMENTS["derivatie_din_conductor"].imageSource, 0);
            addSign(5, 0, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(6, 0, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(7, 0, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(8, 0, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(9, 0, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(10, 0, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(11, 0, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(12, 0, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(13, 0, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(14, 0, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(15, 0, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(16, 0, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(17, 0, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(18, 0, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(19, 0, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(20, 0, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(21, 0, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(22, 0, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(23, 0, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(24, 0, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(25, 0, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(26, 0, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(27, 0, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);


            addSign(1, 1, 1, SCHEME_ELEMENTS["conductor_lung_sageata"].imageSource, 0, [{ name: "L2", positionX: -5, positionY: 2.5, flip: 0, fontSize: 14, fontWeight: 700 }]);
            addSign(2, 1, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(3, 1, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(4, 1, 1, SCHEME_ELEMENTS["cruce"].realSource[0], 0);
            addSign(4, 1, 1, SCHEME_ELEMENTS["cruce"].realSource[1], 0);

            addSign(5, 1, 1, SCHEME_ELEMENTS["derivatie_din_conductor"].imageSource, 0);
            addSign(6, 1, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(7, 1, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(8, 1, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(9, 1, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(10, 1, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(11, 1, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(12, 1, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(13, 1, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(14, 1, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(15, 1, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(16, 1, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(17, 1, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(18, 1, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(19, 1, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(20, 1, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(21, 1, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(22, 1, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(23, 1, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(24, 1, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(25, 1, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(26, 1, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(27, 1, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);


            addSign(1, 2, 1, SCHEME_ELEMENTS["conductor_lung_sageata"].imageSource, 0, [{ name: "L3", positionX: -5, positionY: 2.5, flip: 0, fontSize: 14, fontWeight: 700 }]);
            addSign(2, 2, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(3, 2, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);

            addSign(4, 2, 1, SCHEME_ELEMENTS["cruce"].realSource[0], 0);
            addSign(4, 2, 1, SCHEME_ELEMENTS["cruce"].realSource[1], 0);

            addSign(5, 2, 1, SCHEME_ELEMENTS["cruce"].realSource[0], 0);
            addSign(5, 2, 1, SCHEME_ELEMENTS["cruce"].realSource[1], 0);

            addSign(6, 2, 1, SCHEME_ELEMENTS["derivatie_din_conductor"].imageSource, 0);
            addSign(7, 2, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(8, 3, 1, SCHEME_ELEMENTS["derivatie_din_conductor"].imageSource, 0);
            addSign(9, 2, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);

            addSign(10, 3, 1, SCHEME_ELEMENTS["cruce"].realSource[0], 0);
            addSign(10, 3, 1, SCHEME_ELEMENTS["cruce"].realSource[1], 0);

            addSign(11, 2, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(12, 2, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(13, 2, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(14, 2, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(15, 2, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(16, 2, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(17, 2, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(18, 2, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(19, 2, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(20, 2, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(21, 2, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(22, 2, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(23, 2, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(24, 2, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(25, 2, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(26, 2, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(27, 2, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);


            addSign(1, 3, 1, SCHEME_ELEMENTS["conductor_lung_sageata"].imageSource, 0, [{ name: "N", positionX: -5, positionY: 2.5, flip: 0, fontSize: 14, fontWeight: 700 }]);
            addSign(2, 3, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(3, 3, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);

            addSign(4, 3, 1, SCHEME_ELEMENTS["cruce"].realSource[0], 0);
            addSign(4, 3, 1, SCHEME_ELEMENTS["cruce"].realSource[1], 0);

            addSign(5, 3, 1, SCHEME_ELEMENTS["cruce"].realSource[0], 0);
            addSign(5, 3, 1, SCHEME_ELEMENTS["cruce"].realSource[1], 0);

            addSign(6, 3, 1, SCHEME_ELEMENTS["cruce"].realSource[0], 0);
            addSign(6, 3, 1, SCHEME_ELEMENTS["cruce"].realSource[1], 0);

            addSign(7, 3, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(8, 2, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(9, 3, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(10, 2, 1, SCHEME_ELEMENTS["derivatie_din_conductor"].imageSource, 0);
            addSign(11, 3, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(12, 3, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(13, 3, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(14, 3, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(15, 3, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(16, 3, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(17, 3, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(18, 3, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(19, 3, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(20, 3, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(21, 3, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(22, 3, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(23, 3, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(24, 3, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(25, 3, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(26, 3, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(27, 3, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);


            addSign(1, 4, 1, SCHEME_ELEMENTS["conductor_lung_sageata"].imageSource, 0, [{ name: "PE", positionX: -5, positionY: 2.5, flip: 0, fontSize: 14, fontWeight: 700 }]);
            addSign(2, 4, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(3, 4, 1, SCHEME_ELEMENTS["derivatie_din_conductor_punctat"].imageSource, 0);

            addSign(4, 4, 1, SCHEME_ELEMENTS["cruce"].realSource[0], 0);
            addSign(4, 4, 1, SCHEME_ELEMENTS["cruce"].realSource[1], 0);

            addSign(5, 4, 1, SCHEME_ELEMENTS["cruce"].realSource[0], 0);
            addSign(5, 4, 1, SCHEME_ELEMENTS["cruce"].realSource[1], 0);
            
            addSign(6, 4, 1, SCHEME_ELEMENTS["cruce"].realSource[0], 0);
            addSign(6, 4, 1, SCHEME_ELEMENTS["cruce"].realSource[1], 0);

            addSign(7, 4, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);

            addSign(8, 4, 1, SCHEME_ELEMENTS["cruce"].realSource[0], 0);
            addSign(8, 4, 1, SCHEME_ELEMENTS["cruce"].realSource[1], 0);

            addSign(9, 4, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);

            addSign(10, 4, 1, SCHEME_ELEMENTS["cruce"].realSource[0], 0);
            addSign(10, 4, 1, SCHEME_ELEMENTS["cruce"].realSource[1], 0);

            addSign(11, 4, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(12, 4, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(13, 4, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(14, 4, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(15, 4, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(16, 4, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(17, 4, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(18, 4, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(19, 4, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(20, 4, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(21, 4, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(22, 4, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(23, 4, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(24, 4, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(25, 4, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(26, 4, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(27, 4, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);

            addSign(3, 5, 1, SCHEME_ELEMENTS["conductor_lat_punctat"].imageSource, 0);
            addSign(3, 6, 1, SCHEME_ELEMENTS["conductor_lat_punctat"].imageSource, 0);
            addSign(3, 7, 1, SCHEME_ELEMENTS["conductor_lat_punctat"].imageSource, 0);
            addSign(3, 8, 1, SCHEME_ELEMENTS["conductor_lat_punctat"].imageSource, 0);
            addSign(3, 9, 1, SCHEME_ELEMENTS["conductor_lat_punctat"].imageSource, 0);
            addSign(3, 10, 1, SCHEME_ELEMENTS["conductor_lat_punctat"].imageSource, 0);

            addSign(3, 11, 1, SCHEME_ELEMENTS["colt_stanga_jos_punctat"].imageSource, 0);
            addSign(4, 11, 1, SCHEME_ELEMENTS["conductor_lung_punctat"].imageSource, 0);

            
            addSign(4, 5, 1, SCHEME_ELEMENTS["siguranta"].imageSource, 0, [{ name: "F1", positionX: -1.5, positionY: 3.5, flip: 0, fontSize: 14, fontWeight: 700 }]);
            addSign(4, 6, 1, SCHEME_ELEMENTS["conductor_lat"].imageSource, 0);
            addSign(4, 7, 1, SCHEME_ELEMENTS["contact_normal_deschis"].imageSource, 0, [{ name: "Q1", positionX: -2.5, positionY: 3.5, flip: 0, fontSize: 14, fontWeight: 700 }, { name: "1", positionX: 2, positionY: 0, flip: 0, fontSize: 14, fontWeight: 700 }, { name: "2", positionX: 1.5, positionY: 6, flip: 0, fontSize: 14, fontWeight: 700 } ]);
            addSign(4, 8, 1, SCHEME_ELEMENTS["conductor_lat"].imageSource, 0);
            addSign(4, 9, 1, SCHEME_ELEMENTS["releu_termic"].imageSource, 0, [{ name: "R.t.", positionX: -2.5, positionY: 3.5, flip: 0, fontSize: 14, fontWeight: 700 }, { name: "1", positionX: 2.25, positionY: 0, flip: 0, fontSize: 14, fontWeight: 700 }, { name: "2", positionX: 2, positionY: 7.5, flip: 0, fontSize: 14, fontWeight: 700 } ]);
            addSign(4, 10, 1, SCHEME_ELEMENTS["conductor_oblic_135"].imageSource, 0);


            addSign(5, 5, 1, SCHEME_ELEMENTS["siguranta"].imageSource, 0, [{ name: "F2", positionX: -1.5, positionY: 3.5, flip: 0, fontSize: 14, fontWeight: 700 }]);
            addSign(5, 6, 1, SCHEME_ELEMENTS["conductor_lat"].imageSource, 0);
            addSign(5, 7, 1, SCHEME_ELEMENTS["contact_normal_deschis"].imageSource, 0, [{ name: "3", positionX: 1.5, positionY: 0, flip: 0, fontSize: 14, fontWeight: 700 }, { name: "4", positionX: 1.5, positionY: 6, flip: 0, fontSize: 14, fontWeight: 700 } ]);
            addSign(5, 8, 1, SCHEME_ELEMENTS["conductor_lat"].imageSource, 0);
            addSign(5, 9, 1, SCHEME_ELEMENTS["releu_termic"].imageSource, 0, [{ name: "3", positionX: 2.25, positionY: 0, flip: 0, fontSize: 14, fontWeight: 700 }, { name: "4", positionX: 2, positionY: 7.5, flip: 0, fontSize: 14, fontWeight: 700 } ]);
            addSign(5, 10, 1, SCHEME_ELEMENTS["conductor_lat"].imageSource, 0);
            addSign(5, 11, 1, SCHEME_ELEMENTS["motor_asincron_trifazat_cu_rotorul_in_scurtcircuit"].imageSource, 0);


            addSign(6, 5, 1, SCHEME_ELEMENTS["siguranta"].imageSource, 0, [{ name: "F3", positionX: -1.5, positionY: 3.5, flip: 0, fontSize: 14, fontWeight: 700 }]);
            addSign(6, 6, 1, SCHEME_ELEMENTS["conductor_lat"].imageSource, 0);
            addSign(6, 7, 1, SCHEME_ELEMENTS["contact_normal_deschis"].imageSource, 0, [{ name: "5", positionX: 1.5, positionY: 0, flip: 0, fontSize: 14, fontWeight: 700 }, { name: "6", positionX: 1.5, positionY: 6, flip: 0, fontSize: 14, fontWeight: 700 }]);
            addSign(6, 8, 1, SCHEME_ELEMENTS["conductor_lat"].imageSource, 0);
            addSign(6, 9, 1, SCHEME_ELEMENTS["releu_termic"].imageSource, 0, [{ name: "5", positionX: 2, positionY: 0, flip: 0, fontSize: 14, fontWeight: 700 }, { name: "6", positionX: 2, positionY: 7.5, flip: 0, fontSize: 14, fontWeight: 700 } ]);
            addSign(6, 10, 1, SCHEME_ELEMENTS["conductor_oblic_45"].imageSource, 0);

            addSign(8, 5, 1, SCHEME_ELEMENTS["siguranta"].imageSource, 0, [{ name: "F4", positionX: -1.5, positionY: 3.5, flip: 0, fontSize: 14, fontWeight: 700 }]);
            addSign(8, 6, 1, SCHEME_ELEMENTS["conductor_lat"].imageSource, 0);
            addSign(8, 7, 1, SCHEME_ELEMENTS["contact_normal_inchis_prin_protectia_termica_la_supracurent"].imageSource, 0, [{ name: "R.T.", positionX: -6.5, positionY: 3.5, flip: 0, fontSize: 14, fontWeight: 700 }]);
            addSign(8, 8, 1, SCHEME_ELEMENTS["conductor_lat"].imageSource, 0);
            addSign(8, 9, 1, SCHEME_ELEMENTS["conductor_lat"].imageSource, 0);
            addSign(8, 10, 1, SCHEME_ELEMENTS["conductor_lat"].imageSource, 0);
            addSign(8, 11, 1, SCHEME_ELEMENTS["conductor_lat"].imageSource, 0);

            addSign(8, 12, 1, SCHEME_ELEMENTS["colt_stanga_jos"].imageSource, 0);
            addSign(9, 12, 1, SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);


            addSign(10, 5, 1, SCHEME_ELEMENTS["siguranta"].imageSource, 0, [{ name: "F5", positionX: -1.5, positionY: 3.5, flip: 0, fontSize: 14, fontWeight: 700 }]);
            addSign(10, 6, 1, SCHEME_ELEMENTS["conductor_lat"].imageSource, 0);
            addSign(10, 7, 1, SCHEME_ELEMENTS["contact_normal_deschis_actionare_prin_apasare_automentinere"].imageSource, 0, [{ name: "B.p.", positionX: -4, positionY: 7.5, flip: 0, fontSize: 14, fontWeight: 700 }]);
            addSign(11, 7, 1, SCHEME_ELEMENTS["contact_normal_deschis_automentinere"].imageSource, 0, [{ name: "Q1", positionX: 6, positionY: 3, flip: 0, fontSize: 14, fontWeight: 600 }]);
    
            addSign(10, 8, 1, SCHEME_ELEMENTS["conductor_lat"].imageSource, 0);
            addSign(10, 9, 1, SCHEME_ELEMENTS["contact_normal_inchis_actionare_prin_apasare"].imageSource, 0, [{ name: "B.o.", positionX: -4, positionY: 7.5, flip: 0, fontSize: 14, fontWeight: 700 }]);
            addSign(10, 10, 1, SCHEME_ELEMENTS["conductor_lat"].imageSource, 0);
            addSign(10, 11, 1, SCHEME_ELEMENTS["bobina_releu"].imageSource, 0, [{ name: "Q1", positionX: -3, positionY: 2.5, flip: 0, fontSize: 14, fontWeight: 700 }]);
            addSign(10, 12, 1, SCHEME_ELEMENTS["colt_dreapta_jos"].imageSource, 0);

            changeIsSelectedSign(2, 2);
            setProjectName("Pornirea într-un singur sens a motorului asincron trifazat");
       
        } else if (number === 3) {

            addSign(1, 0, 1,  SCHEME_ELEMENTS["conductor_lung_sageata"].imageSource, 0, [{ name: "L1", positionX: -5, positionY: 2.5, flip: 0, fontSize: 14, fontWeight: 700 }]);
            addSign(2, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(3, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(4, 0, 1,  SCHEME_ELEMENTS["derivatie_din_conductor"].imageSource);
            addSign(5, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(6, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(7, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(8, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(9, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(10, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(11, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(12, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(13, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(14, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(15, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(16, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(17, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(18, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(19, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(20, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(21, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(22, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(23, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(24, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(25, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(26, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(27, 0, 1,  SCHEME_ELEMENTS["conductor_lung_sageata_final"].imageSource);
            

            addSign(1, 1, 1,  SCHEME_ELEMENTS["conductor_lung_sageata"].imageSource, 0, [{ name: "L2", positionX: -5, positionY: 2.5, flip: 0, fontSize: 14, fontWeight: 700 }]);
            addSign(2, 1, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(3, 1, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);

            addSign(4, 1, 1, SCHEME_ELEMENTS["cruce"].realSource[0], 0);
            addSign(4, 1, 1, SCHEME_ELEMENTS["cruce"].realSource[1], 0);
                        
            addSign(5, 1, 1,  SCHEME_ELEMENTS["derivatie_din_conductor"].imageSource);
            addSign(6, 1, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(7, 1, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(8, 1, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(9, 1, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(10, 1, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(11, 1, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(12, 1, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(13, 1, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(14, 1, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(15, 1, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(16, 1, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(17, 1, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(18, 1, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(19, 1, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(20, 1, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(21, 1, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(22, 1, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(23, 1, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(24, 1, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(25, 1, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(26, 1, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(27, 1, 1,  SCHEME_ELEMENTS["conductor_lung_sageata_final"].imageSource);

            addSign(1, 2, 1,  SCHEME_ELEMENTS["conductor_lung_sageata"].imageSource, 0, [{ name: "L3", positionX: -5, positionY: 2.5, flip: 0, fontSize: 14, fontWeight: 700 }]);
            addSign(2, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(3, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);

            addSign(4, 2, 1, SCHEME_ELEMENTS["cruce"].realSource[0], 0);
            addSign(4, 2, 1, SCHEME_ELEMENTS["cruce"].realSource[1], 0);

            addSign(5, 2, 1, SCHEME_ELEMENTS["cruce"].realSource[0], 0);
            addSign(5, 2, 1, SCHEME_ELEMENTS["cruce"].realSource[1], 0);

            addSign(6, 2, 1,  SCHEME_ELEMENTS["derivatie_din_conductor"].imageSource);
            addSign(7, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(8, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(9, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(10, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(11, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(12, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(13, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(14, 2, 1,  SCHEME_ELEMENTS["derivatie_din_conductor"].imageSource);
            addSign(15, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(16, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(17, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(18, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(19, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(20, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(21, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(22, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(23, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(24, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(25, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(26, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(27, 2, 1,  SCHEME_ELEMENTS["conductor_lung_sageata_final"].imageSource);
            
            addSign(1, 3, 1,  SCHEME_ELEMENTS["conductor_lung_sageata"].imageSource, 0, [{ name: "N", positionX: -5, positionY: 2.5, flip: 0, fontSize: 14, fontWeight: 700 }]);
            addSign(2, 3, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(3, 3, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);

            addSign(4, 3, 1, SCHEME_ELEMENTS["cruce"].realSource[0], 0);
            addSign(4, 3, 1, SCHEME_ELEMENTS["cruce"].realSource[1], 0);

            addSign(5, 3, 1, SCHEME_ELEMENTS["cruce"].realSource[0], 0);
            addSign(5, 3, 1, SCHEME_ELEMENTS["cruce"].realSource[1], 0);

            addSign(6, 3, 1, SCHEME_ELEMENTS["cruce"].realSource[0], 0);
            addSign(6, 3, 1, SCHEME_ELEMENTS["cruce"].realSource[1], 0);

            addSign(7, 3, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(8, 3, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(9, 3, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(10, 3, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(11, 3, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(12, 3, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(13, 3, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);

            addSign(14, 3, 1, SCHEME_ELEMENTS["cruce"].realSource[0], 0);
            addSign(14, 3, 1, SCHEME_ELEMENTS["cruce"].realSource[1], 0);

            addSign(15, 3, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(16, 3, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(17, 3, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(18, 3, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(19, 3, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(20, 3, 1,  SCHEME_ELEMENTS["derivatie_din_conductor"].imageSource);
            addSign(21, 3, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(22, 3, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(23, 3, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(24, 3, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(25, 3, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(26, 3, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(27, 3, 1,  SCHEME_ELEMENTS["conductor_lung_sageata_final"].imageSource);


            addSign(4,4, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(14, 4, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(14, 5, 1,  SCHEME_ELEMENTS["siguranta"].imageSource, 0, [{ name: "F4", positionX: -1.5, positionY: 3.5, flip: 0, fontSize: 14, fontWeight: 700 }]);
            addSign(14, 6, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource, 0);

            addSign(14, 7, 1,  SCHEME_ELEMENTS["contact_normal_inchis_prin_protectia_termica_la_supracurent"].realSource[0], 0);
            addSign(14, 7, 1,  SCHEME_ELEMENTS["contact_normal_inchis_prin_protectia_termica_la_supracurent"].realSource[1], 0, [{ name: "F11.1", positionX: -6.5, positionY: 1.5, flip: 0, fontSize: 14, fontWeight: 700 }]);

            addSign(14, 8, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource, 0);

            addSign(14, 9, 1,  SCHEME_ELEMENTS["contact_normal_inchis_actionare_prin_apasare"].imageSource, 0, [{ name: "B.o.", positionX: -6.5, positionY: 3, flip: 0, fontSize: 14, fontWeight: 700 }, { name: "S3", positionX: 9, positionY: 2.5, flip: 0, fontSize: 14, fontWeight: 700 }]);
            addSign(14, 10, 1,  SCHEME_ELEMENTS["semi_cruce"].imageSource);
            addSign(14, 11, 1,  SCHEME_ELEMENTS["contact_normal_deschis_actionare_prin_apasare_automentinere"].imageSource, 0, [{ name: "S1", positionX: -4.5, positionY: 4, flip: 0, fontSize: 14, fontWeight: 700 }, { name: "B.p.", positionX: 0, positionY: 0, flip: 0, fontSize: 0.01, fontWeight: 700 }]);
            addSign(14, 12, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(14, 13, 1,  SCHEME_ELEMENTS["contact_normal_inchis_copy"].imageSource, 0, [{ name: "Q11.2", positionX: -3, positionY: 3.75, flip: 0, fontSize: 14, fontWeight: 700 }, { name: "C.i. Q11.2", positionX: 0, positionY: 0, flip: 0, fontSize: 0.01, fontWeight: 700 }]);

            // addSign(15, 10, 1,  SCHEME_ELEMENTS["colt_dreapta_jos"].imageSource);
            addSign(15, 11, 1,  SCHEME_ELEMENTS["contact_normal_deschis_automentinere"].imageSource, 0, [{ name: "Q11.1", positionX: 5.5, positionY: 3.75, flip: 0, fontSize: 14, fontWeight: 700 }, { name: "C.a. Q1", positionX: 0, positionY: 0, flip: 0, fontSize: 0.01, fontWeight: 700 }]);

            addSign(15, 10, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(16, 10, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(17, 10, 1,  SCHEME_ELEMENTS["colt_dreapta_sus"].imageSource);
            addSign(17, 11, 1,  SCHEME_ELEMENTS["contact_normal_deschis_actionare_prin_apasare_automentinere"].imageSource, 0, [{ name: "S2", positionX: -4.5, positionY: 4, flip: 0, fontSize: 14, fontWeight: 700 }, { name: "B.p.", positionX: 0, positionY: 0, flip: 0, fontSize: 0.01, fontWeight: 700 }]);
            addSign(17, 12, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(17, 13, 1,  SCHEME_ELEMENTS["contact_normal_inchis_copy"].imageSource, 0, [{ name: "Q11.1", positionX: -3, positionY: 3.75, flip: 0, fontSize: 14, fontWeight: 700 }, { name: "C.i. Q11.1", positionX: 0, positionY: 0, flip: 0, fontSize: 0.01, fontWeight: 700 }]);

            // addSign(18, 8, 1,  SCHEME_ELEMENTS["colt_dreapta_sus"].imageSource);
            addSign(18, 11, 1,  SCHEME_ELEMENTS["contact_normal_deschis_automentinere"].imageSource, 0, [{ name: "Q11.2", positionX: 5.5, positionY: 3.75, flip: 0, fontSize: 14, fontWeight: 700 }, { name: "C.a. Q11.2", positionX: 0, positionY: 0, flip: 0, fontSize: 0.01, fontWeight: 700 }]);
            // addSign(18, 10, 1,  SCHEME_ELEMENTS["colt_dreapta_jos"].imageSource);
            // addSign(17, 11, 1,  SCHEME_ELEMENTS["contact_normal_inchis_copy"].imageSource, 0, [{ name: "Q11.1", positionX: -1.5, positionY: 3.75, flip: 0, fontSize: 10, fontWeight: 700 }]);
            addSign(17, 12, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(17, 15, 1,  SCHEME_ELEMENTS["bobina_releu"].imageSource, 0, [{ name: "Q11.2", positionX: -5, positionY: 3.75, flip: 0, fontSize: 14, fontWeight: 700 }, { name: "A1", positionX: 4.25, positionY: 0, flip: -90, fontSize: 14, fontWeight: 700 }, { name: "A2", positionX: 4.25, positionY: 8, flip: -90, fontSize: 14, fontWeight: 700 }]);
            addSign(14, 14, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            
            addSign(14, 15, 1,  SCHEME_ELEMENTS["bobina_releu"].imageSource, 0, [{ name: "Q11.1", positionX: -5, positionY: 3.75, flip: 0, fontSize: 14, fontWeight: 700 }, { name: "A1", positionX: 4.25, positionY: 0, flip: -90, fontSize: 14, fontWeight: 700 }, { name: "A2", positionX: 4.25, positionY: 8, flip: -90, fontSize: 14, fontWeight: 700 }]);
            addSign(14, 16, 1,  SCHEME_ELEMENTS["colt_stanga_jos"].imageSource);
            addSign(15, 16, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);

            addSign(17, 14, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);

            addSign(16, 16, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(17, 16, 1,  SCHEME_ELEMENTS["derivatie_din_conductor"].imageSource, 180);
            addSign(18, 16, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 180);
            addSign(19, 16, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 180);
            addSign(20, 16, 1,  SCHEME_ELEMENTS["colt_dreapta_jos"].imageSource);
            addSign(20, 15, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(20, 14, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(20, 13, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(20, 12, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(20, 11, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(20, 10, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(20, 9, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(20, 8, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(20, 7, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(20, 6, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(20, 5, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(20, 4, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);


            addSign(4, 5, 1,  SCHEME_ELEMENTS["siguranta"].imageSource,  0, [{ name: "F1", positionX: -1.5, positionY: 3.5, flip: 0, fontSize: 14, fontWeight: 700 }]);
            addSign(4, 6, 1,  SCHEME_ELEMENTS["semi_cruce"].imageSource);
            addSign(4, 7, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(4, 8, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(4, 9, 1,  SCHEME_ELEMENTS["contact_normal_deschis_copy"].imageSource, 0, [{ name: "Q11.1", positionX: -4.5, positionY: 3.5, flip: 0, fontSize: 14, fontWeight: 700 }, { name: "Q1", positionX: -3, positionY: 3.5, flip: 0, fontSize: 0.01, fontWeight: 700 }, { name: "1", positionX: 1.7, positionY: -.5, flip: 0, fontSize: 14, fontWeight: 700 }, { name: "2", positionX: 1.5, positionY: 6, flip: 0, fontSize: 14, fontWeight: 700 }]);
            addSign(4, 10, 1,  SCHEME_ELEMENTS["semi_cruce"].imageSource);
            addSign(4, 11, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(4, 12, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(4, 13, 1,  SCHEME_ELEMENTS["releu_termic"].imageSource, 0, [{ name: "F11.1", positionX: -4.5, positionY: 3.5, flip: 0, fontSize: 14, fontWeight: 700 }, { name: "1", positionX: 2, positionY: 1, flip: 0, fontSize: 14, fontWeight: 700 }, { name: "2", positionX: 2, positionY: 7.5, flip: 0, fontSize: 14, fontWeight: 700 }]);
            addSign(4, 14, 1,  SCHEME_ELEMENTS["conductor_oblic_135"].imageSource);


            addSign(5, 4, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(5, 5, 1,  SCHEME_ELEMENTS["siguranta"].imageSource,  0, [{ name: "F2", positionX: -1.5, positionY: 3.5, flip: 0, fontSize: 14, fontWeight: 700 }]);
            
            addSign(5, 6, 1, SCHEME_ELEMENTS["cruce"].realSource[0], 0);
            addSign(5, 6, 1, SCHEME_ELEMENTS["cruce"].realSource[1], 0);

            addSign(5, 7, 1,  SCHEME_ELEMENTS["semi_cruce"].imageSource);
            addSign(5, 8, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(5, 9, 1,  SCHEME_ELEMENTS["contact_normal_deschis_copy"].imageSource, 0, [{ name: "3", positionX: 1.5, positionY: -.5, flip: 0, fontSize: 14, fontWeight: 700 }, { name: "4", positionX: 1.5, positionY: 6, flip: 0, fontSize: 14, fontWeight: 700 }]);
            
            addSign(5, 10, 1, SCHEME_ELEMENTS["cruce"].realSource[0], 0);
            addSign(5, 10, 1, SCHEME_ELEMENTS["cruce"].realSource[1], 0);

            addSign(5, 11, 1,  SCHEME_ELEMENTS["semi_cruce"].imageSource);
            addSign(5, 12, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);

            addSign(5, 13, 1,  SCHEME_ELEMENTS["releu_termic"].imageSource, 0, [{ name: "3", positionX: 2, positionY: 1, flip: 0, fontSize: 14, fontWeight: 700 }, { name: "4", positionX: 2, positionY: 7.5, flip: 0, fontSize: 14, fontWeight: 700 }]);
            addSign(5, 14, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);

            addSign(5, 15, 1,  SCHEME_ELEMENTS["motor_asincron_trifazat_cu_rotorul_in_scurtcircuit"].imageSource);
            addSign(4, 15, 1,  SCHEME_ELEMENTS["colt_stanga_sus"].imageSource);
            addSign(4, 16, 1,  SCHEME_ELEMENTS["priza_de_pamant_de_protectie"].imageSource);

            addSign(6, 4, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(6, 5, 1,  SCHEME_ELEMENTS["siguranta"].imageSource, 0, [{ name: "F3", positionX: -1.5, positionY: 3.5, flip: 0, fontSize: 14, fontWeight: 700 }]);
            
            addSign(6, 6, 1, SCHEME_ELEMENTS["cruce"].realSource[0], 0);
            addSign(6, 6, 1, SCHEME_ELEMENTS["cruce"].realSource[1], 0);
                        
            addSign(6, 7, 1, SCHEME_ELEMENTS["cruce"].realSource[0], 0);
            addSign(6, 7, 1, SCHEME_ELEMENTS["cruce"].realSource[1], 0);

            addSign(6, 8, 1,  SCHEME_ELEMENTS["semi_cruce"].imageSource);


            addSign(6, 9, 1,  SCHEME_ELEMENTS["contact_normal_deschis_copy"].imageSource, 0, [{ name: "5", positionX: 1.5, positionY: -.5, flip: 0, fontSize: 14, fontWeight: 700 }, { name: "6", positionX: 1.5, positionY: 6, flip: 0, fontSize: 14, fontWeight: 700 }]);
            
            addSign(6, 10, 1, SCHEME_ELEMENTS["cruce"].realSource[0], 0);
            addSign(6, 10, 1, SCHEME_ELEMENTS["cruce"].realSource[1], 0);

            addSign(6, 11, 1, SCHEME_ELEMENTS["cruce"].realSource[0], 0);
            addSign(6, 11, 1, SCHEME_ELEMENTS["cruce"].realSource[1], 0);

            addSign(6, 12, 1,  SCHEME_ELEMENTS["semi_cruce"].imageSource);

            addSign(6, 13, 1,  SCHEME_ELEMENTS["releu_termic"].imageSource,  0, [{ name: "5", positionX: 2, positionY: 1, flip: 0, fontSize: 14, fontWeight: 700 }, { name: "6", positionX: 2, positionY: 7.5, flip: 0, fontSize: 14, fontWeight: 700 }]);
            addSign(6, 14, 1,  SCHEME_ELEMENTS["conductor_oblic_45"].imageSource);


            addSign(7, 6, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(7, 7, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(7, 8, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);


            addSign(8, 6, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(8, 7, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(8, 8, 1,  SCHEME_ELEMENTS["colt_dreapta_sus"].imageSource);
            addSign(8, 9, 1,  SCHEME_ELEMENTS["contact_normal_deschis_copy"].imageSource, 0, [{ name: "Q11.2", positionX: -4.5, positionY: 3.5, flip: 0, fontSize: 14, fontWeight: 700 }, { name: "7", positionX: 3, positionY: -2, flip: 0, fontSize: 14, fontWeight: 700 }, { name: "8", positionX: 1.5, positionY: 6, flip: 0, fontSize: 14, fontWeight: 700 }]);
            addSign(8, 10, 1,  SCHEME_ELEMENTS["colt_dreapta_jos"].imageSource);
            addSign(7, 10, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);


            addSign(9, 6, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(9, 7, 1,  SCHEME_ELEMENTS["colt_dreapta_sus"].imageSource);
            addSign(9, 8, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(9, 9, 1,  SCHEME_ELEMENTS["contact_normal_deschis_copy"].imageSource, 0, [{ name: "9", positionX: 1.5, positionY: -.5, flip: 0, fontSize: 14, fontWeight: 700 }, { name: "10", positionX: 1, positionY: 6, flip: 0, fontSize: 14, fontWeight: 700 }]);
            addSign(9, 10, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(9, 11, 1,  SCHEME_ELEMENTS["colt_dreapta_jos"].imageSource);
            addSign(8, 11, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(7, 11, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);

            addSign(10, 6, 1,  SCHEME_ELEMENTS["colt_dreapta_sus"].imageSource);
            addSign(10, 7, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(10, 8, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(10, 9, 1,  SCHEME_ELEMENTS["contact_normal_deschis_copy"].imageSource, 0, [{ name: "11", positionX: 1, positionY: -.5, flip: 0, fontSize: 14, fontWeight: 700 }, { name: "12", positionX: 1, positionY: 6, flip: 0, fontSize: 14, fontWeight: 700 }]);
            addSign(10, 10, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(10, 11, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(10, 12, 1,  SCHEME_ELEMENTS["colt_dreapta_jos"].imageSource);
            addSign(9, 12, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(8, 12, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(7, 12, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);


            changeIsSelectedSign(2, 2);
            setProjectName("Inversorul de sens");

        } else if (number === 4) {

            addSign(1, 0, 1,  SCHEME_ELEMENTS["conductor_lung_sageata"].imageSource, 0, [{ name: "L1", positionX: -5, positionY: 2.5, flip: 0, fontSize: 14, fontWeight: 700 }]);
            addSign(2, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(3, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(4, 0, 1,  SCHEME_ELEMENTS["derivatie_din_conductor"].imageSource);
            addSign(5, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(6, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(7, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(8, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(9, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(10, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(11, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(12, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(13, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(14, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(15, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(16, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(17, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(18, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(19, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(20, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(21, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(22, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(23, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(24, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(25, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(26, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(27, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(28, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(29, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(30, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(31, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(32, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(33, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(34, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(35, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(36, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(37, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(38, 0, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(39, 0, 1,  SCHEME_ELEMENTS["conductor_lung_sageata_final"].imageSource);
            // addSign(40, 0, 1,  SCHEME_ELEMENTS["conductor_lung_sageata_final"].imageSource);

            addSign(1, 1, 1,  SCHEME_ELEMENTS["conductor_lung_sageata"].imageSource, 0, [{ name: "L2", positionX: -5, positionY: 2.5, flip: 0, fontSize: 14, fontWeight: 700 }]);
            addSign(2, 1, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(3, 1, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);

            addSign(4, 1, 1, SCHEME_ELEMENTS["cruce"].realSource[0], 0);
            addSign(4, 1, 1, SCHEME_ELEMENTS["cruce"].realSource[1], 0);

            addSign(5, 1, 1,  SCHEME_ELEMENTS["derivatie_din_conductor"].imageSource);
            addSign(6, 1, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(7, 1, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(8, 1, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(9, 1, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(10, 1, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(11, 1, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(12, 1, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(13, 1, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(14, 1, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(15, 1, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(16, 1, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(17, 1, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(18, 1, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(19, 1, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(20, 1, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(21, 1, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(22, 1, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(23, 1, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(24, 1, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(25, 1, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(26, 1, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(27, 1, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(28, 1, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(29, 1, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(30, 1, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(31, 1, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(32, 1, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(33, 1, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(34, 1, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(35, 1, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(36, 1, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(37, 1, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(38, 1, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(39, 1, 1,  SCHEME_ELEMENTS["conductor_lung_sageata_final"].imageSource);

            addSign(1, 2, 1,  SCHEME_ELEMENTS["conductor_lung_sageata"].imageSource, 0, [{ name: "L3", positionX: -5, positionY: 2.5, flip: 0, fontSize: 14, fontWeight: 700 }]);
            addSign(2, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(3, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);

            addSign(4, 2, 1, SCHEME_ELEMENTS["cruce"].realSource[0], 0);
            addSign(4, 2, 1, SCHEME_ELEMENTS["cruce"].realSource[1], 0);

            addSign(5, 2, 1, SCHEME_ELEMENTS["cruce"].realSource[0], 0);
            addSign(5, 2, 1, SCHEME_ELEMENTS["cruce"].realSource[1], 0);

            addSign(6, 2, 1,  SCHEME_ELEMENTS["derivatie_din_conductor"].imageSource);
            addSign(7, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(8, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(9, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(10, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(11, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(12, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(13, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(14, 2, 1,  SCHEME_ELEMENTS["derivatie_din_conductor"].imageSource);
            addSign(15, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(16, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(17, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(18, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(19, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(20, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(21, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(22, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(23, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(24, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(25, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(26, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(27, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(28, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(29, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(30, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(31, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(32, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(33, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(34, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(35, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(36, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(37, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(38, 2, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(39, 2, 1,  SCHEME_ELEMENTS["conductor_lung_sageata_final"].imageSource);
            
            addSign(1, 3, 1,  SCHEME_ELEMENTS["conductor_lung_sageata"].imageSource, 0, [{ name: "N", positionX: -5, positionY: 2.5, flip: 0, fontSize: 14, fontWeight: 700 }]);
            addSign(2, 3, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(3, 3, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);

            addSign(4, 3, 1, SCHEME_ELEMENTS["cruce"].realSource[0], 0);
            addSign(4, 3, 1, SCHEME_ELEMENTS["cruce"].realSource[1], 0);

            addSign(5, 3, 1, SCHEME_ELEMENTS["cruce"].realSource[0], 0);
            addSign(5, 3, 1, SCHEME_ELEMENTS["cruce"].realSource[1], 0);

            addSign(6, 3, 1, SCHEME_ELEMENTS["cruce"].realSource[0], 0);
            addSign(6, 3, 1, SCHEME_ELEMENTS["cruce"].realSource[1], 0);

            addSign(7, 3, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(8, 3, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(9, 3, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(10, 3, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(11, 3, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(12, 3, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(13, 3, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);

            addSign(14, 3, 1, SCHEME_ELEMENTS["cruce"].realSource[0], 0);
            addSign(14, 3, 1, SCHEME_ELEMENTS["cruce"].realSource[1], 0);

            addSign(15, 3, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(16, 3, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(17, 3, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(18, 3, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(19, 3, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(20, 3, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(21, 3, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(22, 3, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(23, 3, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(24, 3, 1,  SCHEME_ELEMENTS["derivatie_din_conductor"].imageSource);
            addSign(25, 3, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(26, 3, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(27, 3, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(28, 3, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(29, 3, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(30, 3, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(31, 3, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(32, 3, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(33, 3, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(34, 3, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(35, 3, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(36, 3, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(37, 3, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(38, 3, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(39, 3, 1,  SCHEME_ELEMENTS["conductor_lung_sageata_final"].imageSource);


            addSign(4, 4, 1, SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(14, 4, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(14, 5, 1,  SCHEME_ELEMENTS["siguranta"].imageSource, 0, [{ name: "F4", positionX: -1.5, positionY: 3.5, flip: 0, fontSize: 14, fontWeight: 700 }]);
            addSign(14, 6, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource, 0);

            addSign(14, 7, 1,  SCHEME_ELEMENTS["contact_normal_inchis_prin_protectia_termica_la_supracurent"].imageSource, 0, [{ name: "F11.1", positionX: -6.5, positionY: 3.5, flip: 0, fontSize: 14, fontWeight: 700 }]);
            addSign(14, 8, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource, 0);
            addSign(14, 9, 1,  SCHEME_ELEMENTS["contact_normal_deschis_actionare_prin_apasare_automentinere"].imageSource, 0, [{ name: "S1", positionX: -4.5, positionY: 4, flip: 0, fontSize: 14, fontWeight: 700 }, { name: "B.p.", positionX: 0, positionY: 0, flip: 0, fontSize: 0.01, fontWeight: 700 }]);
            addSign(15, 9, 1,  SCHEME_ELEMENTS["contact_normal_deschis_automentinere"].imageSource, 0, [{ name: "Q11.1", positionX: 5.5, positionY: 3.75, flip: 0, fontSize: 14, fontWeight: 700 }, { name: "C.a. Q1", positionX: 0, positionY: 0, flip: 0, fontSize: 0.01, fontWeight: 700 }]);

            addSign(14, 10, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(14, 11, 1,  SCHEME_ELEMENTS["contact_normal_inchis_actionare_prin_apasare"].imageSource, 0, [{ name: "B.o.", positionX: -6.5, positionY: 3, flip: 0, fontSize: 14, fontWeight: 700 }, { name: "S3", positionX: 10.5, positionY: 1, flip: 0, fontSize: 14, fontWeight: 700 }]);

            // addSign(14, 12, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(14, 12, 1,  SCHEME_ELEMENTS["semi_cruce"].imageSource, 0);
            addSign(14, 13, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource, 0);
            addSign(14, 14, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            // addSign(15, 13, 1,  SCHEME_ELEMENTS["contact_normal_deschis_automentinere"].imageSource, 0, [{ name: "Q11.1", positionX: 5.5, positionY: 3.75, flip: 0, fontSize: 14, fontWeight: 700 }]);
            
            addSign(15, 12, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(16, 12, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);

            // addSign(15, 10, 1,  SCHEME_ELEMENTS["colt_dreapta_jos"].imageSource);

          
            // addSign(18, 8, 1,  SCHEME_ELEMENTS["colt_dreapta_sus"].imageSource);
            // addSign(18, 11, 1,  SCHEME_ELEMENTS["contact_normal_deschis_automentinere"].imageSource, 0, [{ name: "Q11.2", positionX: 7, positionY: 3.75, flip: 0, fontSize: 10, fontWeight: 700 }, { name: "C.a. Q11.2", positionX: 0, positionY: 0, flip: 0, fontSize: 0.01, fontWeight: 700 }]);
            // addSign(18, 10, 1,  SCHEME_ELEMENTS["colt_dreapta_jos"].imageSource);
            // addSign(17, 11, 1,  SCHEME_ELEMENTS["contact_normal_inchis_copy"].imageSource, 0, [{ name: "Q11.1.", positionX: -1.5, positionY: 3.75, flip: 0, fontSize: 10, fontWeight: 700 }]);
            
            addSign(17, 10, 1,  SCHEME_ELEMENTS["colt_stanga_sus"].imageSource, 0);
            addSign(17, 11, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource, 0);
            addSign(17, 12, 1,  SCHEME_ELEMENTS["semi_cruce"].imageSource, 180);
            addSign(17, 13, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(17, 14, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(17, 15, 1,  SCHEME_ELEMENTS["actionare_electromecanica_cu_temporizare_la_anclansare"].imageSource, 0, [{ name: "T1", positionX: -4.5, positionY: 3.75, flip: 0, fontSize: 14, fontWeight: 700 }, { name: "A1", positionX: 4.25, positionY: 0, flip: -90, fontSize: 14, fontWeight: 700 }, { name: "A2", positionX: 4.25, positionY: 8, flip: -90, fontSize: 14, fontWeight: 700 }]);
            addSign(18, 10, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);

            addSign(19, 10, 1,  SCHEME_ELEMENTS["semi_cruce"].imageSource, 90);
            addSign(19, 11, 1,  SCHEME_ELEMENTS["contact_normal_inchis"].imageSource, 0, [{ name: "Q11.3", positionX: -3.5, positionY: 3.75, flip: 0, fontSize: 14, fontWeight: 700 }]);
            addSign(19, 12, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource, 0);
            addSign(19, 13, 1,  SCHEME_ELEMENTS["contact_normal_inchis"].imageSource, 0, [{ name: "T1", positionX: 1, positionY: 3.75, flip: 0, fontSize: 14, fontWeight: 700 }, { name: "T1.Q2", positionX: 0, positionY: 0, flip: 0, fontSize: 0.01, fontWeight: 700 }]);
            addSign(19, 14, 1,  SCHEME_ELEMENTS["semi_cruce"].imageSource, 0);
            addSign(20, 14, 1,  SCHEME_ELEMENTS["colt_dreapta_sus"].imageSource, 0);
            addSign(20, 15, 1,  SCHEME_ELEMENTS["indicator_luminos"].imageSource, 0);
            addSign(20, 17, 1,  SCHEME_ELEMENTS["steaua"].imageSource, 0);
            addSign(19, 17, 1,  SCHEME_ELEMENTS["steaua"].imageSource, 0);




            addSign(19, 15, 1,  SCHEME_ELEMENTS["bobina_releu"].imageSource, 0,  [{ name: "Q11.2", positionX: -6, positionY: 3.75, flip: 0, fontSize: 14, fontWeight: 700 }, { name: "A1", positionX: 5.25, positionY: -1, flip: -90, fontSize: 14, fontWeight: 700 }, { name: "A2", positionX: 5.25, positionY: 8, flip: -90, fontSize: 14, fontWeight: 700 }]);

            addSign(20, 10, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);

            addSign(21, 10, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(22, 10, 1,  SCHEME_ELEMENTS["colt_dreapta_sus"].imageSource, 0);
            // addSign(22, 10, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(22, 11, 1,  SCHEME_ELEMENTS["contact_normal_inchis"].imageSource, 0, [{ name: "Q11.2", positionX: -3.5, positionY: 3.75, flip: 0, fontSize: 14, fontWeight: 700 },  { name: "T1 C.i. Q11.2", positionX: 0, positionY: 0, flip: 0, fontSize: 0.001, fontWeight: 700 }, { name: "T1. Q11.2", positionX: 0, positionY: 0, flip: 0, fontSize: 0.001, fontWeight: 700 }]);
            addSign(22, 12, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource, 0);
            addSign(22, 13, 1,  SCHEME_ELEMENTS["contact_normal_deschis"].imageSource, 0, [{ name: "T1", positionX: 1, positionY: 3.75, flip: 0, fontSize: 14, fontWeight: 700 }]);
            addSign(22, 14, 1,  SCHEME_ELEMENTS["semi_cruce"].imageSource, 0);
            addSign(23, 14, 1,  SCHEME_ELEMENTS["colt_dreapta_sus"].imageSource, 0);
            addSign(23, 15, 1,  SCHEME_ELEMENTS["indicator_luminos"].imageSource, 0);

            addSign(23, 17, 1,  SCHEME_ELEMENTS["triunghi"].imageSource, 0);
            addSign(22, 17, 1,  SCHEME_ELEMENTS["triunghi"].imageSource, 0);

            addSign(22, 15, 1,  SCHEME_ELEMENTS["bobina_releu"].imageSource, 0,  [{ name: "Q11.3", positionX: -6, positionY: 3.75, flip: 0, fontSize: 14, fontWeight: 700 }, { name: "A1", positionX: 5.25, positionY: -1, flip: -90, fontSize: 14, fontWeight: 700 }, { name: "A2", positionX: 5.25, positionY: 8, flip: -90, fontSize: 14, fontWeight: 700 }]);

            addSign(14, 15, 1,  SCHEME_ELEMENTS["bobina_releu"].imageSource, 0, [{ name: "Q11.1", positionX: -6, positionY: 3.75, flip: 0, fontSize: 14, fontWeight: 700 }, { name: "A1", positionX: 5.25, positionY: -1, flip: -90, fontSize: 14, fontWeight: 700 }, { name: "A2", positionX: 5.25, positionY: 8, flip: -90, fontSize: 14, fontWeight: 700 }]);
            addSign(14, 16, 1,  SCHEME_ELEMENTS["colt_stanga_jos"].imageSource);
            addSign(15, 16, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);

            addSign(17, 14, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);

            addSign(16, 16, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(17, 16, 1,  SCHEME_ELEMENTS["semi_cruce"].imageSource, 270);
            addSign(18, 16, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(19, 16, 1,  SCHEME_ELEMENTS["semi_cruce"].imageSource, 270);
            addSign(20, 16, 1,  SCHEME_ELEMENTS["semi_cruce"].imageSource, 270);
            addSign(21, 16, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource, 0);
            addSign(22, 16, 1,  SCHEME_ELEMENTS["semi_cruce"].imageSource, 270);
            addSign(23, 16, 1,  SCHEME_ELEMENTS["semi_cruce"].imageSource, 270);

            addSign(24, 16, 1,  SCHEME_ELEMENTS["colt_dreapta_jos"].imageSource);
            addSign(24, 15, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(24, 14, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(24, 13, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(24, 12, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(24, 11, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(24, 10, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(24, 9, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(24, 8, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(24, 7, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(24, 6, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(24, 5, 1,  SCHEME_ELEMENTS["siguranta"].imageSource, 0, [{ name: "F5", positionX: -1.5, positionY: 3.5, flip: 0, fontSize: 14, fontWeight: 700 }]);
            addSign(24, 4, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);


            addSign(4, 5, 1,  SCHEME_ELEMENTS["siguranta"].imageSource,  0, [{ name: "F1", positionX: -1.5, positionY: 3.5, flip: 0, fontSize: 14, fontWeight: 700 }]);
            addSign(4, 6, 1,  SCHEME_ELEMENTS["semi_cruce"].imageSource);
            addSign(4, 7, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(4, 8, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(4, 9, 1,  SCHEME_ELEMENTS["contact_normal_deschis_copy"].imageSource, 0, [{ name: "Q11.1", positionX: -4.5, positionY: 3.5, flip: 0, fontSize: 14, fontWeight: 700 }, { name: "Q1", positionX: -3, positionY: 3.5, flip: 0, fontSize: 0.01, fontWeight: 700 }, { name: "1", positionX: 1.7, positionY: -.5, flip: 0, fontSize: 14, fontWeight: 700 }, { name: "2", positionX: 1.5, positionY: 6, flip: 0, fontSize: 14, fontWeight: 700 }]);
            addSign(4, 10, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(4, 11, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(4, 12, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(4, 13, 1,  SCHEME_ELEMENTS["releu_termic"].imageSource, 0, [{ name: "F11.1", positionX: -4.5, positionY: 3.5, flip: 0, fontSize: 14, fontWeight: 700 }, { name: "1", positionX: 2, positionY: 1, flip: 0, fontSize: 14, fontWeight: 700 }, { name: "2", positionX: 2, positionY: 7.5, flip: 0, fontSize: 14, fontWeight: 700 }]);
            addSign(4, 14, 1,  SCHEME_ELEMENTS["conductor_oblic_135"].imageSource);


            addSign(5, 4, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(5, 5, 1,  SCHEME_ELEMENTS["siguranta"].imageSource,  0, [{ name: "F2", positionX: -1.5, positionY: 3.5, flip: 0, fontSize: 14, fontWeight: 700 }]);
            
            addSign(5, 6, 1, SCHEME_ELEMENTS["cruce"].realSource[0], 0);
            addSign(5, 6, 1, SCHEME_ELEMENTS["cruce"].realSource[1], 0);

            addSign(5, 7, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(5, 8, 1,  SCHEME_ELEMENTS["semi_cruce"].imageSource);
            addSign(5, 9, 1,  SCHEME_ELEMENTS["contact_normal_deschis_copy"].imageSource, 0, [{ name: "3", positionX: 1.5, positionY: -.5, flip: 0, fontSize: 14, fontWeight: 700 }, { name: "4", positionX: 1.5, positionY: 6, flip: 0, fontSize: 14, fontWeight: 700 }]);
            addSign(5, 10, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(5, 11, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(5, 12, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);

            addSign(5, 13, 1,  SCHEME_ELEMENTS["releu_termic"].imageSource, 0, [{ name: "3", positionX: 2, positionY: 1, flip: 0, fontSize: 14, fontWeight: 700 }, { name: "4", positionX: 2, positionY: 7.5, flip: 0, fontSize: 14, fontWeight: 700 }]);
            addSign(5, 14, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);

            addSign(5, 15, 1,  SCHEME_ELEMENTS["motor_asincron_trifazat_cu_rotorul_in_scurtcircuit"].imageSource, 0, [{ name: "", positionX: 0, positionY: 0, flip: 0, fontSize: 0, fontWeight: 700 }]);
            addSign(4, 15, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(3, 15, 1,  SCHEME_ELEMENTS["colt_stanga_sus"].imageSource);
            addSign(3, 16, 1,  SCHEME_ELEMENTS["priza_de_pamant_de_protectie"].imageSource);

            addSign(6, 4, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(6, 5, 1,  SCHEME_ELEMENTS["siguranta"].imageSource, 0, [{ name: "F3", positionX: -1.5, positionY: 3.5, flip: 0, fontSize: 14, fontWeight: 700 }]);
            
            addSign(6, 6, 1, SCHEME_ELEMENTS["cruce"].realSource[0], 0);
            addSign(6, 6, 1, SCHEME_ELEMENTS["cruce"].realSource[1], 0);

            addSign(6, 7, 1,  SCHEME_ELEMENTS["semi_cruce"].imageSource);

            addSign(6, 8, 1, SCHEME_ELEMENTS["cruce"].realSource[0], 0);
            addSign(6, 8, 1, SCHEME_ELEMENTS["cruce"].realSource[1], 0);

            addSign(6, 9, 1,  SCHEME_ELEMENTS["contact_normal_deschis_copy"].imageSource, 0, [{ name: "5", positionX: 1.5, positionY: -.5, flip: 0, fontSize: 14, fontWeight: 700 }, { name: "6", positionX: 1.5, positionY: 6, flip: 0, fontSize: 14, fontWeight: 700 }]);
            addSign(6, 10, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(6, 11, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(6, 12, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);

            addSign(6, 13, 1,  SCHEME_ELEMENTS["releu_termic"].imageSource,  0, [{ name: "5", positionX: 2, positionY: 1, flip: 0, fontSize: 14, fontWeight: 700 }, { name: "6", positionX: 2, positionY: 7.5, flip: 0, fontSize: 14, fontWeight: 700 }]);
            addSign(6, 14, 1,  SCHEME_ELEMENTS["conductor_oblic_45"].imageSource);


            addSign(7, 6, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(7, 7, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(7, 8, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);


            addSign(8, 6, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(8, 7, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(8, 8, 1,  SCHEME_ELEMENTS["colt_dreapta_sus"].imageSource);
            addSign(8, 9, 1,  SCHEME_ELEMENTS["contact_normal_deschis_copy"].imageSource, 0, [{ name: "Q11.3", positionX: -4.5, positionY: 3.5, flip: 0, fontSize: 14, fontWeight: 700 }, { name: "7", positionX: 1.5, positionY: -.5, flip: 0, fontSize: 14, fontWeight: 700 }, { name: "8", positionX: 1.5, positionY: 6, flip: 0, fontSize: 14, fontWeight: 700 }]);
            addSign(8, 10, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(8, 11, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(8, 12, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(8, 13, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(8, 14, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(8, 15, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(8, 16, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(8, 17, 1,  SCHEME_ELEMENTS["colt_dreapta_jos"].imageSource);
            addSign(7, 17, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(6, 16, 1,  SCHEME_ELEMENTS["conductor_oblic_135"].imageSource, 180);


            addSign(9, 6, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(9, 7, 1,  SCHEME_ELEMENTS["colt_dreapta_sus"].imageSource);
            addSign(9, 8, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(9, 9, 1,  SCHEME_ELEMENTS["contact_normal_deschis_copy"].imageSource, 0, [{ name: "9", positionX: 1.5, positionY: -.5, flip: 0, fontSize: 14, fontWeight: 700 }, { name: "10", positionX: 1, positionY: 6, flip: 0, fontSize: 14, fontWeight: 700 }]);
            addSign(9, 10, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(9, 10, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(9, 11, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(9, 12, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(9, 13, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(9, 14, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(9, 15, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(9, 16, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(9, 17, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(9, 18, 1,  SCHEME_ELEMENTS["colt_dreapta_jos"].imageSource);
            addSign(8, 17, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(7, 17, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            
            addSign(6, 17, 1, SCHEME_ELEMENTS["cruce"].realSource[0], 0);
            addSign(6, 17, 1, SCHEME_ELEMENTS["cruce"].realSource[1], 0);

            addSign(5, 17, 1, SCHEME_ELEMENTS["cruce"].realSource[0], 0);
            addSign(5, 17, 1, SCHEME_ELEMENTS["cruce"].realSource[1], 0);

            addSign(5, 18, 1,  SCHEME_ELEMENTS["semi_cruce"].imageSource);
            addSign(7, 18, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(8, 18, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);

            addSign(5, 16, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);


            addSign(10, 6, 1,  SCHEME_ELEMENTS["colt_dreapta_sus"].imageSource);
            addSign(10, 7, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(10, 8, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(10, 9, 1,  SCHEME_ELEMENTS["contact_normal_deschis_copy"].imageSource, 0, [{ name: "11", positionX: 1, positionY: -.5, flip: 0, fontSize: 14, fontWeight: 700 }, { name: "12", positionX: 1, positionY: 6, flip: 0, fontSize: 14, fontWeight: 700 }]);
            addSign(10, 10, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(10, 11, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(10, 12, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(10, 13, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(10, 14, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(10, 15, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(10, 16, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(10, 17, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(10, 18, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(10, 19, 1,  SCHEME_ELEMENTS["colt_dreapta_jos"].imageSource);
            addSign(9, 19, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(8, 19, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);
            addSign(7, 19, 1,  SCHEME_ELEMENTS["conductor_lung"].imageSource);

            addSign(6, 18, 1, SCHEME_ELEMENTS["cruce"].realSource[0], 0);
            addSign(6, 18, 1, SCHEME_ELEMENTS["cruce"].realSource[1], 0);

            addSign(6, 19, 1,  SCHEME_ELEMENTS["semi_cruce"].imageSource);

            addSign(5, 19, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(4, 19, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(4, 18, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(4, 17, 1,  SCHEME_ELEMENTS["semi_cruce"].imageSource);
            // addSign(4, 16, 1,  SCHEME_ELEMENTS["conductor_lat"].imageSource);
            addSign(4, 16, 1,  SCHEME_ELEMENTS["conductor_oblic_45"].imageSource, 180);

            addSign(4, 20, 1,  SCHEME_ELEMENTS["contact_normal_deschis_copy"].imageSource, 0, [{ name: "Q11.2", positionX: -5, positionY: 3.5, flip: 0, fontSize: 14, fontWeight: 700 }, { name: "13", positionX: 1, positionY: -.5, flip: 0, fontSize: 14, fontWeight: 700 }, { name: "14", positionX: 1, positionY: 6, flip: 0, fontSize: 14, fontWeight: 700 }]);
            addSign(5, 20, 1,  SCHEME_ELEMENTS["contact_normal_deschis_copy"].imageSource, 0, [{ name: "15", positionX: 1, positionY: -.5, flip: 0, fontSize: 14, fontWeight: 700 }, { name: "16", positionX: 1, positionY: 6, flip: 0, fontSize: 14, fontWeight: 700 }]);
            addSign(6, 20, 1,  SCHEME_ELEMENTS["contact_normal_deschis_copy"].imageSource, 0, [{ name: "17", positionX: 1, positionY: -.5, flip: 0, fontSize: 14, fontWeight: 700 }, { name: "18", positionX: 1, positionY: 6, flip: 0, fontSize: 14, fontWeight: 700 }]);

            addSign(4, 21, 1,  SCHEME_ELEMENTS["colt_stanga_jos"].imageSource, 0);
            addSign(5, 21, 1,  SCHEME_ELEMENTS["semi_cruce"].imageSource, 270);
            addSign(6, 21, 1,  SCHEME_ELEMENTS["colt_dreapta_jos"].imageSource, 0);


            changeIsSelectedSign(2, 2);
            setProjectName("Pornirea stea-triunghi");
        }

        setFinished(true);
        
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            const currSigns = JSON.parse(localStorage.getItem("signs") || "[]");
            console.log(currSigns);
            if (!currSigns.length) return;
            reset();
            changeGrid();
            imageLocking();
            currSigns.forEach((sign : { x: number, y: number, page:number, flip:number, names: Array<any>, texts: any }) => {
                addSign(sign.x, sign.y, sign.page || 1, sign.names, sign.flip, sign.texts);
            });
            changeIsSelectedSign(2, 2);
            setProjectName(number === 1 ? "Circuit simplu" : number === 2 ? "Pornirea într-un singur sens a motorului asincron trifazat" : number === 3 ? "Inversorul de sens a motorului asincron trifazat" : "Pornirea stea-triunghi");
            setFinished(true);

        }, 150);
        

        return () => {
            clearTimeout(timer);
        };

        // setResetCount(currReset => currReset + 1);
    }, [reset]);

    useEffect(() => {
        if (!finished) return;
        setTimeout(() => document.querySelectorAll(".cell").forEach(cell => cell.classList.remove("selected")), 1000);
    }, [finished]);


    const findSignObjectByName = (name : string) => {
        return signs.find((sign : any) => sign.texts.some((text: { name: string }) => text.name === name));
    };


    const findSignObject = (x: number, y: number, idx: number = 0) => {
        const currSign: { x: number, y: number, names: Array<any>, flip: number, texts: any } = signs.find((sign: { x: number, y: number, flip: number }) => sign.x === x && sign.y === y);
        return currSign;
    };

    // const actuationAlgorithm = (currIdx : number, currRowIdx : number, open : boolean | null = null) => {
    //     const currSignObj: any = findSignObject(currIdx, currRowIdx);
    //     console.log(currSignObj);
    //     if (!currSignObj) return;
    //     const source = currSignObj?.names;
    //     if (!(source.some((name : { name: string }) => name.name.includes("/siguranta")) && !source.some((name : { name: string }) => name.name.includes("siguranta_automata"))) && !(source.some((name : { name: string }) => name.name.includes("/contact_normal_deschis")) || source.some((name : { name: string }) => name.name.includes("/contact_normal_inchis")))) return;
        
    //     if (source.some((name : { name: string }) => name.name.includes("/siguranta")) && !source.some((name : { name: string }) => name.name.includes("siguranta_automata"))) {
    //         const isOpen = source.some((name : { name: string }) => name.name.includes("/siguranta_deschisa"));
    //         removeSign(currIdx, currRowIdx, 1);
    //         if (isOpen && (open !== null ? open : true)) addSign(currIdx, currRowIdx, 1, SCHEME_ELEMENTS["siguranta"].imageSource, currSignObj.flip, currSignObj.texts, currSignObj.signColor || "#000", currSignObj?.signPosition || "");
    //         else addSign(currIdx, currRowIdx, 1, SCHEME_ELEMENTS["siguranta_deschisa"].imageSource, currSignObj.flip, currSignObj.texts, currSignObj.signColor || "#000", currSignObj?.signPosition || "");
    //     } else if (source.some((name : { name: string }) => name.name.includes("actionare_prin_apasare"))) {
    //         const isOpen = source.some((name : { name: string }) => name.name.includes("/contact_normal_deschis"));
    //         const newSource = isOpen && (open !== null ? open : true) ? replaceAll(source[0].name, "deschis", "inchis").slice(14) : replaceAll(source[0].name, "inchis", "deschis").slice(14);
    //         const realNewSource = newSource.split(".")[0];

    //         removeSign(currIdx, currRowIdx, 1);
    //         addSign(currIdx, currRowIdx, 1, SCHEME_ELEMENTS[realNewSource].imageSource, currSignObj.flip, currSignObj.texts, currSignObj.signColor || "#000", currSignObj?.signPosition || "");
    //         const selfMaintananceQ1 = findSignObjectByName("C.a. Q1");
    //         // const selfMaintananceQ2 = findSignObjectByName("C.a. Q11.2");


    //         const contactQ1 = findSignObjectByName("Q11.1");
    //         const contactQ2 = findSignObjectByName("Q11.2");


    //         // const selfMaintananceQ1 = findSignObject(selfMaintananceQ1Obj?.x || (currIdx + 1), selfMaintananceQ1Obj?.y || currRowIdx);

    //         setTimeout(() => {
    //             if (projectName.toLowerCase().includes("circuit")) return;
    //             if ((currSignObj.names?.some((name : { name: string }) => name.name.includes("automentinere")) && !selfMaintananceQ1.names?.some((name : { name: string }) => name.name.includes("inchis"))) || (currSignObj.texts.some((text: { name: string }) => text.name.includes("B.o.")) && selfMaintananceQ1.names?.some((name : { name: string }) => name.name.includes("inchis")))) {
    //                 if (Number(schemeNumber) !== 4) {
    //                     actuationAlgorithm(selfMaintananceQ1?.x || (currIdx + 1), selfMaintananceQ1?.y || currRowIdx);
    //                     // For the three parallel contacts
    //                     actuationAlgorithm(contactQ1?.x, contactQ1?.y);
    //                     actuationAlgorithm(contactQ1?.x + 1, contactQ1?.y);
    //                     actuationAlgorithm(contactQ1?.x + 2, contactQ1?.y);
    //                 } else {
    //                     setTimeout(() => {
    //                         actuationAlgorithm(selfMaintananceQ1?.x || (currIdx + 1), selfMaintananceQ1?.y || currRowIdx);
    //                         // For the three parallel contacts
    //                         actuationAlgorithm(contactQ1?.x, contactQ1?.y);
    //                         actuationAlgorithm(contactQ1?.x + 1, contactQ1?.y);
    //                         actuationAlgorithm(contactQ1?.x + 2, contactQ1?.y);
    //                     }, currSignObj.texts.some((text: { name: string }) => text.name.includes("B.o.")) ? 0 : 1000);
                        
    //                     // actuationAlgorithm(selfMaintananceQ2?.x || (currIdx + 1), selfMaintananceQ1?.y || currRowIdx);
    //                     // For the three parallel contacts
    //                     // actuationAlgorithm(contactQ2?.x, contactQ2?.y);
    //                     // actuationAlgorithm(contactQ2?.x + 1, contactQ2?.y);
    //                     // actuationAlgorithm(contactQ2?.x + 2, contactQ2?.y);

    //                 }
        

    //             }
    //         }, 500);

    //         setTimeout(() => {
    //             removeSign(currIdx, currRowIdx, 1);
    //             addSign(currIdx, currRowIdx, 1, currSignObj.names, currSignObj.flip, currSignObj.texts, currSignObj.signColor || "#000", currSignObj?.signPosition || "");
    //         }, 650);

    //     }
    //     else if (source.some((name : { name: string }) => name.name.includes("/contact_normal_deschis")) || source.some((name : { name: string }) => name.name.includes("/contact_normal_inchis"))) {
    //         const isOpen = source.some((name : { name: string }) => name.name.includes("/contact_normal_deschis"));
    //         const newSource = isOpen && (open !== null ? open : true) ? replaceAll(source?.[0]?.name, "deschis", "inchis").slice(14) : replaceAll(source?.[0]?.name, "inchis", "deschis").slice(14);
    //         removeSign(currIdx, currRowIdx, 1);
    //         addSign(currIdx, currRowIdx, 1, SCHEME_ELEMENTS[newSource.split(".")[0]].imageSource, currSignObj.flip, currSignObj.texts, currSignObj.signColor || "#000", currSignObj?.signPosition || "");
    //     }

    //     setTimeout(() => document.querySelectorAll(".cell").forEach((cell: any) => cell.classList.remove("selected")), 200);

    // };

    // useLayoutEffect(() => {
    //     if (number === 1 || !finished) return;
    //     const consumer = document.querySelectorAll(".cell")[activeElementY * COLUMNS + activeElementX];
    //     if (spin) replaceSign(Number(consumer.getAttribute("row")), Number(consumer.getAttribute("column") || 0), 1, SCHEME_ELEMENTS[`motor_asincron_trifazat_cu_rotorul_in_scurtcircuit_pornit`].imageSource);
    //     else {
    //         // const intreruptor1 = findSignObjectByName("Q1");
    //         // const intreruptor2 = findSignObjectByName("3");
    //         // const intreruptor3 = findSignObjectByName("5");

    //         // actuationAlgorithm(intreruptor1?.x, intreruptor1?.y, true);
    //         // actuationAlgorithm(intreruptor2?.x, intreruptor2?.y, true);
    //         // actuationAlgorithm(intreruptor3?.x, intreruptor3?.y, true);
    //         if (number === 3 || number === 4) {
    //             const CAQ2 = findSignObjectByName("C.a. Q11.2");
    //             if (CAQ2 && CAQ2.names?.some((name : { name: string }) => name.name.includes("inchis"))) actuationAlgorithm(CAQ2?.x, CAQ2?.y, false);
    //         }

    //         const CAQ1 = findSignObjectByName("C.a. Q1");
    //         if (CAQ1 && CAQ1.names?.some((name : { name: string }) => name.name.includes("inchis"))) actuationAlgorithm(CAQ1?.x, CAQ1?.y, false);
    //         const S1 = findSignObjectByName("F1");
    //         const S2 = findSignObjectByName("F2");
    //         const S3 = findSignObjectByName("F3");

    //         setTimeout(() => {
    //             // if (S1.name.includes("deschis") || S2.name.includes("deschis") || S3.name.includes("deschis") || CAQ1.name.includes("deschis")) replaceSign(Number(consumer.getAttribute("row")), Number(consumer.getAttribute("column") || 0), 1, SCHEME_ELEMENTS[`motor_asincron_trifazat_cu_rotorul_in_scurtcircuit`].imageSource);
    //             replaceSign(Number(consumer.getAttribute("row")), Number(consumer.getAttribute("column") || 0), 1, SCHEME_ELEMENTS[`motor_asincron_trifazat_cu_rotorul_in_scurtcircuit`].imageSource);
    //         }, 500);
    //     }
    // }, [spin]);

    useEffect(() => {
        console.log(signs);
        if (signs.length) {
            console.log(JSON.stringify([...signs]), 1);
            localStorage.setItem("signs", JSON.stringify(signs));
        }
    }, [signs]);

    return finished ? <Grid isHighTension={false} printSchemes={null} removePage={() => {}} totalPages={1} category="0" isSimulation={true} debugSchemeNumber={-1} page={1} useStore={useStore} data={data} projectId={null} format={Number(schemeNumber) !== 4 ? "A4" : "A3"} projectName={projectName} teacherVar={true} /> : <Processing />;
    
};

export default PredefinedScheme;